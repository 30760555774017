export const ROUTE_PATHS = {
  GAMES_PLATFORMS_LIST: "/games/platforms",
  GAMES_PRERELEASES_LIST: "/games/pre-releases",
  GAMES_LIST: "/games",
  GAMES_CUSTOM_LAUNCHER_LIST: "/games/launcher-custom",
  GAMES_CREATE: "/games/create",
  GAMES_MERGE: "/games/merge",
  GAMES_FORM: "/games/:id",
  CAMPAIGNS_LIST: "/campaigns",
  CAMPAIGNS_CREATE: "/campaigns/create",
  CAMPAIGNS_FORM: "/campaigns/:id",
  USERS_LIST: "/users",
  USERS_FORM: "/users/:id",
  USERS_PUSH: "/users/push",
  USERS_STATS: "/users/stats",
  USERS_PUSH_NOTIFICATIONS: "/users/push-notifications",
  USERS_PUSH_NOTIFICATIONS_DETAILS: "/users/push-notifications/:id",
  CATEGORIES_LIST: "/categories",
  CATEGORIES_FORM: "/categories/:id",
  PLAYLISTS_LIST: "/playlists",
  PLAYLISTS_DELETED_LIST: "/playlists/deleted",
  PLAYLISTS_CREATE: "/playlists/create",
  PLAYLISTS_FORM: "/playlists/:id",
  CTAS_LIST: "/call-to-actions",
  CTAS_FORM: "/call-to-actions/:id",
  POLLS_LIST: "/polls",
  POLLS_CREATE: "/polls/create",
  POLLS_FORM: "/polls/:id",
  TAGS_LIST: "/tags",
  TAGS_FORM: "/tags/:id",
  REVIEWS_LIST: "/reviews",
  REVIEWS_FORM: "/reviews/:id",
  SUGGESTIONS_LIST: "/suggestions",
  SUBSCRIPTIONS_LIST: "/subscriptions",
  FOLLOWINGS_LIST: "/followings",
  FOLLOWINGS_FORM: "/followings/:id",
  DEVELOPERS_LIST: "/developers",
  DEVELOPERS_CREATE: "/developers/create",
  DEVELOPERS_MERGE: "/developers/merge",
  DEVELOPERS_FORM: "/developers/:id",
};

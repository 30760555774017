import { ColumnDefinition, OptionType } from "@tager/admin-ui";

import { ReviewInterface } from "@/modules/reviews/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<ReviewInterface>> = [
  {
    id: 1,
    name: "Datetime",
    field: "datetime",
    type: "datetime",
    width: "168px",
  },
  {
    id: 4,
    name: "Rating",
    field: "rating",
    width: "50px",
    style: {
      textAlign: "center",
    },
    headStyle: {
      textAlign: "center",
    },
  },
  {
    id: 2,
    name: "Game",
    field: "game",
    format: ({ row }) => {
      return row.game?.name || "-";
    },
  },
  {
    id: 3,
    name: "User",
    field: "user",
    format: ({ row }) => {
      return row.user?.name || "-";
    },
  },
  {
    id: 5,
    name: "Body",
    field: "body",
  },
  {
    id: 6,
    name: "Home",
    field: "displayWebHome",
    type: "boolean",
    view: "TICK",
  },
  {
    id: 8,
    name: "Hidden",
    field: "hidden",
    width: "70px",
  },
  {
    id: 9,
    name: "",
    field: "actions",
    width: "50px",
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "home",
    label: "Home",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "length",
    label: "Length",
  },
];

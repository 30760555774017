import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldAjaxSelect = _resolveComponent("FormFieldAjaxSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Merge Developers" }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": true,
        "can-create-another": false,
        "submit-label": "Merge",
        "is-submit-button-disabled": !_ctx.submitButtonEnabled,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-submit-button-disabled", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_FormFieldAjaxSelect, {
        value: _ctx.values.mergeFrom,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.mergeFrom) = $event)),
        label: "Merge FROM",
        name: "mergeFrom",
        "value-field": "value",
        "label-field": "label",
        "request-url": "/admin/developers/ajax-search"
      }, null, 8, ["value"]),
      _createVNode(_component_FormFieldAjaxSelect, {
        value: _ctx.values.mergeTo,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.mergeTo) = $event)),
        name: "mergeTo",
        disabled: _ctx.developerToDisabled,
        label: "Merge TO",
        "value-field": "value",
        "label-field": "label",
        "request-url": `/admin/developers/ajax-search?ignore=${_ctx.values.mergeFrom?.value}`
      }, null, 8, ["value", "disabled", "request-url"])
    ]),
    _: 1
  }))
}
import { ColumnDefinition } from "@tager/admin-ui";

import { PlaylistDeletedInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<PlaylistDeletedInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "50px",
  },
  {
    id: 2,
    name: "Deleted",
    field: "deletedAt",
    type: "datetime",
    width: "165px",
  },
  {
    id: 3,
    name: "Name",
    field: "name",
  },
  {
    id: 4,
    name: "User",
    field: "user",
  },
  {
    id: 5,
    name: "games",
    field: "games",
  },
];

import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import { getExportListUrl } from "@tager/admin-export";
import { getSettingItemListUrl } from "@tager/admin-settings";
import { getEmailLogListUrl, getEmailTemplateListUrl } from "@tager/admin-mail";
import {
  getCommandsListUrl,
  getCronLogsUrl,
  getCommandsLogsUrl,
} from "@tager/admin-cron";

import { getCallToActionsListUrl, getPollsListUrl } from "@/utils/paths";

type LinkKey =
  | "HOME"
  | "GAMES"
  | "GAMES_PLATFORMS"
  | "GAMES_PRE_RELEASES"
  | "GAMES_PRE_RELEASES"
  | "PLAYLISTS"
  | "USERS"
  | "USERS_PUSH_NOTIFICATIONS"
  | "CATEGORIES"
  | "POLLS"
  | "CALLS_TO_ACTION"
  | "TAGS"
  | "SUGGESTIONS"
  | "REVIEWS"
  | "FOLLOWINGS"
  | "SUBSCRIPTIONS"
  | "DEVELOPERS"
  | "EXPORT"
  | "SETTINGS"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS"
  | "MAIL_TEMPLATES_LIST"
  | "MAIL_LOGS_LIST"
  | "CRON_COMMANDS"
  | "CRON_LOGS"
  | "CRON_COMMANDS_LOGS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Home",
  },
  GAMES: {
    url: "/games?filter[stores]=with_stores",
    text: "Games",
  },
  GAMES_PLATFORMS: {
    url: "/games/platforms",
    text: "Games Platforms",
  },
  GAMES_PRE_RELEASES: {
    url: "/games/pre-releases",
    text: "Games Pre-Releases",
  },
  USERS: {
    url: "/users",
    text: "Users",
  },
  USERS_PUSH_NOTIFICATIONS: {
    url: "/users/push-notifications",
    text: "Push Notifications",
  },
  PLAYLISTS: {
    url: "/playlists",
    text: "Playlists",
  },
  TAGS: {
    url: "/tags",
    text: "Categories",
  },
  REVIEWS: {
    url: "/reviews",
    text: "Reviews",
  },
  SUGGESTIONS: {
    url: "/suggestions",
    text: "Suggestions",
  },
  FOLLOWINGS: {
    url: "/followings",
    text: "Followings",
  },
  DEVELOPERS: {
    url: "/developers",
    text: "Developers",
  },
  CATEGORIES: {
    url: "/categories",
    text: "Genres",
  },
  SUBSCRIPTIONS: {
    url: "/subscriptions",
    text: "Subscriptions",
  },
  CALLS_TO_ACTION: {
    url: getCallToActionsListUrl(),
    text: "CTAs",
  },
  POLLS: {
    url: getPollsListUrl(),
    text: "Polls",
  },
  SETTINGS: {
    url: getSettingItemListUrl(),
    text: "Settings",
  },
  EXPORT: {
    url: getExportListUrl(),
    text: "Export",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Roles",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Administrators",
  },
  CRON_COMMANDS: {
    url: getCommandsListUrl(),
    text: "Commands",
  },
  CRON_COMMANDS_LOGS: {
    url: getCommandsLogsUrl(),
    text: "Commands Logs",
  },
  CRON_LOGS: {
    url: getCronLogsUrl(),
    text: "Cron Logs",
  },
  MAIL_TEMPLATES_LIST: {
    url: getEmailTemplateListUrl(),
    text: "E-Mail Templates",
  },
  MAIL_LOGS_LIST: {
    url: getEmailLogListUrl(),
    text: "E-Mail Logs",
  },
});

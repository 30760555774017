
import { defineComponent, onMounted } from "vue";

import { ColumnDefinition, DataTable, useDataTable } from "@tager/admin-ui";

import { getUserActivityCalendar } from "@/modules/users/services";
import { UserActivityCalendarInterface } from "@/modules/users/typings";

export const COLUMN_DEFS: Array<
  ColumnDefinition<UserActivityCalendarInterface>
> = [
  {
    id: 1,
    name: "Date",
    field: "date",
    type: "date",
    width: "100px",
  },
  {
    id: 2,
    name: "Signup",
    field: "signup",
    type: "boolean",
    view: "TICK",
  },
  {
    id: 3,
    name: "Active",
    field: "active",
    type: "boolean",
    view: "TICK",
  },
  {
    id: 4,
    name: "Actions",
    field: "actions",
    type: "boolean",
    view: "TICK",
  },
];

export default defineComponent({
  name: "UsersViewActivityCalendarList",
  components: {
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserActivityCalendarInterface>({
      fetchEntityList: () => getUserActivityCalendar(props.id),
      initialValue: [],
      resourceName: "Calendar Activity List",
    });

    onMounted(() => {
      fetchData();
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});

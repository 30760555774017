import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import { EXPORT_FORM_ROUTE, EXPORT_LIST_ROUTE } from "@tager/admin-export";
import {
  SETTINGS_ITEM_FORM_ROUTE,
  SETTINGS_ITEM_LIST_ROUTE,
} from "@tager/admin-settings";
import {
  EMAIL_LOG_LIST_ROUTE,
  EMAIL_TEMPLATE_FORM_ROUTE,
  EMAIL_TEMPLATE_LIST_ROUTE,
} from "@tager/admin-mail";
import {
  PAGE_COMMANDS_DETAILS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_ROUTE,
  PAGE_CRON_LOG_DETAILS_ROUTE,
  PAGE_CRON_LOGS_ROUTE,
} from "@tager/admin-cron";

import { getLinks } from "@/constants/links";
import Home from "@/views/Home.vue";
import { ROUTE_PATHS } from "@/constants/paths";
import {
  GamesCreateView,
  GamesLauncherCustomListView,
  GamesListView,
  GamesMergeView,
  GamesPlatformsListView,
  GamesPreReleasesListView,
  GamesUpdateView,
} from "@/modules/games";
import { CategoriesFormView, CategoriesListView } from "@/modules/categories";
import { CallToActionsFormView, CallToActionsListView } from "@/modules/ctas";
import { TagsFormView, TagsListView } from "@/modules/tags";
import {
  USERS_LIST_ROUTE,
  USERS_FORM_ROUTE,
  USERS_PUSH_NOTIFICATIONS_ROUTE,
  USERS_SEND_PUSH_ROUTE,
  USERS_STATS_ROUTE,
  USERS_PUSH_NOTIFICATIONS_DETAILS_ROUTE,
} from "@/modules/users";
import { FollowingsListView } from "@/modules/followings";
import { SuggestionsListView } from "@/modules/suggestions";
import { SubscriptionsListView } from "@/modules/subscriptions";
import { REVIEWS_FORM_ROUTE, REVIEWS_LIST_ROUTE } from "@/modules/reviews";
import {
  CAMPAIGNS_LIST_ROUTE,
  CAMPAIGNS_UPDATE_ROUTE,
  CAMPAIGNS_CREATE_ROUTE,
} from "@/modules/campaigns";
import {
  DevelopersFormView,
  DevelopersListView,
  DevelopersMergeView,
} from "@/modules/developers";
import {
  POLLS_CREATE_ROUTE,
  POLLS_FORM_ROUTE,
  POLLS_LIST_ROUTE,
} from "@/modules/polls";
import {
  PLAYLISTS_LIST_ROUTE,
  PLAYLISTS_DELETED_LIST_ROUTE,
  PLAYLISTS_CREATE_ROUTE,
} from "@/modules/playlists";
import { PLAYLISTS_UPDATE_ROUTE } from "@/modules/playlists/routes";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: () => [{ url: "/", text: "Home" }],
  },
};

export const GAMES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GAMES_LIST,
  component: GamesListView,
  name: "Games List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, { url: "/", text: "Games" }],
  },
};

export const GAMES_CUSTOM_LAUNCHER_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GAMES_CUSTOM_LAUNCHER_LIST,
  component: GamesLauncherCustomListView,
  name: "Games Custom Launcher List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, { url: "/", text: "Games" }],
  },
};

export const GAMES_PLATFORMS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GAMES_PLATFORMS_LIST,
  component: GamesPlatformsListView,
  name: "Games Platforms List",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: "/games", text: "Games" },
      { url: "/games/platforms", text: "Games Platforms" },
    ],
  },
};

export const GAMES_PRERELEASES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GAMES_PRERELEASES_LIST,
  component: GamesPreReleasesListView,
  name: "Games Pre-Releases List",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: "/games", text: "Games" },
      { url: "/games/pre-releases", text: "Games Pre-Releases" },
    ],
  },
};

export const GAMES_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GAMES_CREATE,
  component: GamesCreateView,
  name: "Games Create",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().GAMES,
      {
        url: route.path,
        text: "New Game",
      },
    ],
  },
};

export const GAMES_UPDATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GAMES_FORM,
  component: GamesUpdateView,
  name: "Games Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().GAMES,
      {
        url: route.path,
        text: "Update Game",
      },
    ],
  },
};

export const GAMES_MERGE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GAMES_MERGE,
  component: GamesMergeView,
  name: "Games Merge",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().GAMES,
      {
        url: route.path,
        text: "Merge Games",
      },
    ],
  },
};

export const CATEGORIES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CATEGORIES_LIST,
  component: CategoriesListView,
  name: "Categories List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, { url: "/", text: "Games" }],
  },
};

export const CATEGORIES_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CATEGORIES_FORM,
  component: CategoriesFormView,
  name: "Categories Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().CATEGORIES,
      {
        url: route.path,
        text: "Category",
      },
    ],
  },
};

export const TAGS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.TAGS_LIST,
  component: TagsListView,
  name: "Tags List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().TAGS],
  },
};

export const TAGS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.TAGS_FORM,
  component: TagsFormView,
  name: "Tags Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().TAGS,
      {
        url: route.path,
        text: "Categories",
      },
    ],
  },
};

export const CTAS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CTAS_LIST,
  component: CallToActionsListView,
  name: "CTAs List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().CALLS_TO_ACTION],
  },
};

export const CTAS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CTAS_FORM,
  component: CallToActionsFormView,
  name: "CTAs Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().CALLS_TO_ACTION,
      {
        url: route.path,
        text: "CTA Form",
      },
    ],
  },
};

export const SUGGESTIONS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SUGGESTIONS_LIST,
  component: SuggestionsListView,
  name: "Suggestions List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, { url: "/", text: "Suggestions" }],
  },
};
export const SUBSCRIPTIONS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SUBSCRIPTIONS_LIST,
  component: SubscriptionsListView,
  name: "Subscriptions List",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: "/", text: "Subscriptions" },
    ],
  },
};

export const FOLLOWINGS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.FOLLOWINGS_LIST,
  component: FollowingsListView,
  name: "Followings List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, { url: "/", text: "Followings" }],
  },
};

export const DEVELOPERS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.DEVELOPERS_LIST,
  component: DevelopersListView,
  name: "Developers List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, { url: "/", text: "Developers" }],
  },
};

export const DEVELOPERS_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.DEVELOPERS_CREATE,
  component: DevelopersFormView,
  name: "Developers Create",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().DEVELOPERS,
      {
        url: route.path,
        text: "New Developer",
      },
    ],
  },
};

export const DEVELOPERS_UPDATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.DEVELOPERS_FORM,
  component: DevelopersFormView,
  name: "Developers Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().DEVELOPERS,
      {
        url: route.path,
        text: "Update Developer",
      },
    ],
  },
};

export const DEVELOPERS_MERGE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.DEVELOPERS_MERGE,
  component: DevelopersMergeView,
  name: "Developers Merge",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().DEVELOPERS,
      {
        url: route.path,
        text: "Merge Developers",
      },
    ],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,

    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,
    EXPORT_LIST_ROUTE,
    EXPORT_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ADMIN_FORM_ROUTE,
    SETTINGS_ITEM_LIST_ROUTE,
    SETTINGS_ITEM_FORM_ROUTE,

    GAMES_LIST_ROUTE,
    GAMES_PLATFORMS_LIST_ROUTE,
    GAMES_PRERELEASES_LIST_ROUTE,
    GAMES_CREATE_ROUTE,
    GAMES_UPDATE_ROUTE,
    GAMES_MERGE_ROUTE,
    GAMES_CUSTOM_LAUNCHER_LIST_ROUTE,

    USERS_LIST_ROUTE,
    USERS_STATS_ROUTE,
    USERS_PUSH_NOTIFICATIONS_ROUTE,
    USERS_PUSH_NOTIFICATIONS_DETAILS_ROUTE,
    USERS_SEND_PUSH_ROUTE,
    USERS_FORM_ROUTE,

    CATEGORIES_LIST_ROUTE,
    CATEGORIES_FORM_ROUTE,
    TAGS_LIST_ROUTE,
    TAGS_FORM_ROUTE,
    FOLLOWINGS_LIST_ROUTE,
    SUGGESTIONS_LIST_ROUTE,
    SUBSCRIPTIONS_LIST_ROUTE,

    EMAIL_TEMPLATE_LIST_ROUTE,
    EMAIL_TEMPLATE_FORM_ROUTE,
    EMAIL_LOG_LIST_ROUTE,

    PAGE_COMMANDS_ROUTE,
    PAGE_CRON_LOGS_ROUTE,
    PAGE_COMMANDS_LOGS_ROUTE,
    PAGE_COMMANDS_LOG_DETAILS_ROUTE,
    PAGE_CRON_LOG_DETAILS_ROUTE,
    PAGE_COMMANDS_DETAILS_ROUTE,

    CTAS_LIST_ROUTE,
    CTAS_FORM_ROUTE,

    PLAYLISTS_LIST_ROUTE,
    PLAYLISTS_DELETED_LIST_ROUTE,
    PLAYLISTS_UPDATE_ROUTE,
    PLAYLISTS_CREATE_ROUTE,

    POLLS_LIST_ROUTE,
    POLLS_CREATE_ROUTE,
    POLLS_FORM_ROUTE,

    DEVELOPERS_LIST_ROUTE,
    DEVELOPERS_CREATE_ROUTE,
    DEVELOPERS_UPDATE_ROUTE,
    DEVELOPERS_MERGE_ROUTE,

    CAMPAIGNS_LIST_ROUTE,
    CAMPAIGNS_UPDATE_ROUTE,
    CAMPAIGNS_CREATE_ROUTE,

    REVIEWS_FORM_ROUTE,
    REVIEWS_LIST_ROUTE,
  ],
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed79525c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "New Game",
    "is-content-loading": _ctx.isLoading,
    "header-buttons": _ctx.headerButtonList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": true,
        "can-create-another": true,
        "submit-and-exit-label": null,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isLoading && _ctx.values)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("form", {
              novalidate: "",
              onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createVNode(_component_FormField, {
                value: _ctx.values.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
                label: "Name",
                name: "name",
                error: _ctx.errors.name
              }, null, 8, ["value", "error"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.values.categories,
                  "onUpdate:selectedOptions": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.categories) = $event)),
                  name: "categories",
                  label: "Genres",
                  options: _ctx.categoryOptions
                }, null, 8, ["selected-options", "options"]),
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.values.tags,
                  "onUpdate:selectedOptions": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.tags) = $event)),
                  name: "tags",
                  label: "Categories",
                  options: _ctx.tagOptions
                }, null, 8, ["selected-options", "options"])
              ])
            ], 32)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-content-loading", "header-buttons"]))
}
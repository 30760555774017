import { ColumnDefinition } from "@tager/admin-ui";

import { PollInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<PollInterface>> = [
  {
    id: 1,
    name: "Name",
    field: "name",
  },
  {
    id: 2,
    name: "Active",
    field: "active",
    type: "boolean",
    view: "TICK",
    width: "150px",
  },
  {
    id: 3,
    name: "Questions",
    field: "questionsCount",
    width: "150px",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 4,
    name: "Results",
    field: "sessionsCount",
    width: "150px",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 6,
    name: "",
    field: "actions",
    headStyle: { width: "108px", textAlign: "center", whiteSpace: "nowrap" },
  },
];

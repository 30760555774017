
import { defineComponent, onMounted } from "vue";

import { ColumnDefinition, DataTable, useDataTable } from "@tager/admin-ui";

import { getUserDevices, getUserGameActions } from "@/modules/users/services";
import {
  UserDeviceInterface,
  UserGameActionInterface,
} from "@/modules/users/typings";
import { getGamesUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<UserGameActionInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "40px",
  },
  {
    id: 2,
    name: "Date/Time",
    field: "datetime",
    type: "datetime",
    width: "180px",
  },
  {
    id: 3,
    name: "Action",
    field: "action",
    width: "100px",
  },
  {
    id: 4,
    name: "Game",
    field: "game",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.game.id + " - " + row.game.name,
        url: getGamesUpdateUrl(row.game.id),
      },
    }),
  },
];

export default defineComponent({
  name: "UsersViewGameActionsList",
  components: {
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserGameActionInterface>({
      fetchEntityList: () => getUserGameActions(props.id),
      initialValue: [],
      resourceName: "Games Action List",
    });

    onMounted(() => {
      fetchData();
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});


import { defineComponent, computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import { FormFooter, FormField, TagerFormSubmitEvent } from "@tager/admin-ui";

import { getPollsListUrl, getPollsUpdateUrl } from "@/utils/paths";

import { createPoll } from "../services";

import {
  FormValues,
  defaultFormValues,
  convertFormValuesToCreationPayload,
} from "./PollsCreateView.helpers";

export default defineComponent({
  name: "PollsCreateView",
  components: {
    FormField,
    Page,
    FormFooter,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();

    onMounted(() => {
      values.value = {
        name: "",
      };
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(defaultFormValues);

    const isSubmitting = ref<boolean>(false);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createPayload = convertFormValuesToCreationPayload(
        values.value as FormValues
      );

      createPoll(createPayload)
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getPollsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getPollsListUrl());
          }
          toast.show({
            variant: "success",
            title: "Polls",
            body: "Poll successfully created",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Polls",
            body: "Error create poll",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    return {
      values,
      errors,
      isSubmitting,
      submitForm,
      backButtonUrl: getPollsListUrl(),
    };
  },
});

import {ColumnDefinition} from "@tager/admin-ui";

import {SuggestionInterface} from "@/modules/suggestions/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<SuggestionInterface>> = [
    {
        id: 1,
        name: "ID",
        field: "id",
        headStyle: {width: "50px"},
    },
    {
        id: 2,
        name: "Date / Time",
        field: "createdAt",
        type: "datetime",
        headStyle: {width: "180px"},
    },
    {
        id: 3,
        name: "Platform",
        field: "platform",
        headStyle: {width: "120px"},
    },
    {
        id: 4,
        name: "Type",
        field: "type",
        headStyle: {width: "120px"},
    },
    {
        id: 5,
        name: "User",
        field: "user",
        format: ({row}) => {
            return row.user ? "ID " + row.user.id + " - " + row.user.username : "-";
        },
        headStyle: {width: "300px"},
    },
    {
        id: 6,
        name: "Message",
        field: "body",
    },
];

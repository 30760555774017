import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "column-defs": _ctx.columnDefs,
    "row-data": _ctx.rowData,
    loading: _ctx.isRowDataLoading,
    "error-message": _ctx.errorMessage,
    "use-search": false
  }, null, 8, ["column-defs", "row-data", "loading", "error-message"]))
}

import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  OptionType,
  FormFieldMultiSelect,
  FormFieldFileInput,
} from "@tager/admin-ui";

import { getTagsListUrl, getTagsUpdateUrl } from "@/utils/paths";
import { getAppMagicCategories } from "@/modules/categories/services";
import TagsFormViewTopRight from "@/modules/tags/TagsFormView/containers/TagsFormViewTopRight.vue";

import { updateTag, createTag, getTag } from "../services";

import {
  FormValues,
  getTagFormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
} from "./TagsFormView.helpers";

export default defineComponent({
  name: "TagsFormView",
  components: {
    TagsFormViewTopRight,
    FormFieldFileInput,
    FormFieldMultiSelect,
    Page,
    FormField,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getTag(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Tag",
    });

    onMounted(() => {
      fetchModel();
    });

    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getTagFormValues(model.value, []));

    const isSubmitting = ref<boolean>(false);

    const [
      fetchAppMagicCategories,
      { data: appMagicCategories, loading: isAppMagicCategoriesLoading },
    ] = useResource({
      fetchResource: () => getAppMagicCategories(),
      initialValue: [],
      resourceName: "AppMagic Categories List",
    });

    const appMagicCategoriesOptions = computed<Array<OptionType<number>>>(
      () =>
        appMagicCategories.value?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }) || []
    );

    function updateFormValues() {
      values.value = getTagFormValues(
        model.value,
        appMagicCategoriesOptions.value
      );
    }

    onMounted(() => {
      updateFormValues();
      fetchAppMagicCategories();
    });

    watch([model, appMagicCategoriesOptions], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise = isCreation.value
        ? createTag(createOrUpdatePayload)
        : updateTag(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getTagsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getTagsListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getTagFormValues(
              null,
              appMagicCategoriesOptions.value
            );
          }

          toast.show({
            variant: "success",
            title: "Categories",
            body: isCreation.value
              ? "Tag successfully created"
              : "Tag successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Categories",
            body: isCreation.value
              ? "Error creation tag"
              : "Error updating tag",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isAppMagicCategoriesLoading.value
    );

    return {
      isLoading,
      values,
      errors,
      model,
      isCreation,
      isSubmitting,
      submitForm,
      appMagicCategoriesOptions,
      backButtonUrl: getTagsListUrl(),
    };
  },
});

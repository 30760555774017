
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  isNotNullish,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FieldValue,
  FormField,
  FormFieldSelect,
  FormFieldCheckbox,
  FormFieldMultiSelect,
  FormFooter,
  OptionType,
  TabType,
  TagerFormSubmitEvent,
  ToggleSection,
} from "@tager/admin-ui";

import {
  getGamesListUrl,
  getGamesUpdateUrl,
  getUsersListUrl,
} from "@/utils/paths";
import { getCategoriesList } from "@/modules/categories/services";
import { getTagsList } from "@/modules/tags/services";
import { UserFullInterface } from "@/modules/users/typings";
import UsersFormGamesList from "@/modules/users/UsersFormView/components/UsersFormGamesList.vue";
import UsersViewActivityCalendarList from "@/modules/users/UsersFormView/components/UsersViewActivityCalendarList.vue";
import UsersViewDevicesList from "@/modules/users/UsersFormView/components/UsersViewDevicesList.vue";
import UsersViewGameActionsList from "@/modules/users/UsersFormView/components/UsersViewGameActionsList.vue";
import UsersViewActivity from "@/modules/users/UsersFormView/components/UsersViewActivity.vue";
import UsersViewStats from "@/modules/users/UsersFormView/components/UsersViewStats.vue";
import UsersViewLauncherList from "@/modules/users/UsersFormView/components/UsersViewLauncherList.vue";
import { getCampaigns } from "@/modules/campaigns";
import UsersViewLauncherHistoryList from "@/modules/users/UsersFormView/components/UsersViewLauncherHistoryList.vue";
import UsersViewPushNotificationsList from "@/modules/users/UsersFormView/components/UsersViewPushNotificationsList.vue";

import { getUser, getUserFeatureFlags, updateUser } from "../services";

import {
  convertFormValuesToCreationOrUpdatePayload,
  FormValues,
  getUserFormValues,
} from "./UsersFormView.helpers";

export default defineComponent({
  name: "UsersFormView",
  components: {
    UsersViewPushNotificationsList,
    UsersViewLauncherHistoryList,
    UsersViewLauncherList,
    UsersViewStats,
    UsersFormGamesList,
    UsersViewActivityCalendarList,
    UsersViewDevicesList,
    UsersViewGameActionsList,
    UsersViewActivity,

    FormFieldCheckbox,
    ToggleSection,
    FieldValue,
    FormField,
    FormFieldSelect,
    FormFieldMultiSelect,
    Page,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value) {
          return getUser(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "User",
    });

    const [
      fetchFeatureFlagsList,
      { data: featureFlagsList, loading: isFeatureFlagsList },
    ] = useResource({
      fetchResource: () => getUserFeatureFlags(),
      initialValue: [],
      resourceName: "Feature Flags list",
    });

    const [fetchCampaigns, { data: campaigns, loading: isCampaignsLoading }] =
      useResource({
        fetchResource: () => getCampaigns(),
        initialValue: [],
        resourceName: "Campaigns list",
      });

    const [
      fetchCategoryList,
      { data: categoryList, loading: isCategoryListLoading },
    ] = useResource({
      fetchResource: () => getCategoriesList(),
      initialValue: [],
      resourceName: "Categories list",
    });

    const [fetchTagList, { data: tagList, loading: isTagListLoading }] =
      useResource({
        fetchResource: () => getTagsList(),
        initialValue: [],
        resourceName: "Tag list",
      });

    onMounted(() => {
      fetchModel();
      fetchCategoryList();
      fetchTagList();
      fetchFeatureFlagsList();
      fetchCampaigns();
    });

    watch(id, fetchModel);

    const campaignOptions = computed<Array<OptionType<number | null>>>(() => {
      return [
        { value: null, label: "No campaign" },
        ...(campaigns.value?.map((item) => ({
          value: item.id,
          label: item.name + `${item.active ? "" : "(No Active)"}`,
        })) || []),
      ];
    });

    const featureFlagOptions = computed<Array<OptionType<string>>>(() => {
      return (
        featureFlagsList.value?.map((featureFlag) => ({
          value: featureFlag.id,
          label: featureFlag.label,
        })) || []
      );
    });

    const tagOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...tagList.value.map((tag) => ({
          value: tag.id,
          label: tag.name,
        })),
      ];
    });

    const categoryOptions = computed<Array<OptionType<number>>>(() => {
      return categoryList.value.map((category) => ({
        value: category.id,
        label: category.name,
      }));
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<Nullable<FormValues>>(
      getUserFormValues(
        model.value as UserFullInterface,
        categoryOptions.value,
        tagOptions.value,
        featureFlagOptions.value,
        campaignOptions.value
      )
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = model.value
        ? getUserFormValues(
            model.value as UserFullInterface,
            categoryOptions.value,
            tagOptions.value,
            featureFlagOptions.value,
            campaignOptions.value
          )
        : null;
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model, categoryOptions, tagOptions], () => {
      updateFormValues();
    });

    const isLoading = computed<boolean>(
      () =>
        isModelLoading.value ||
        isTagListLoading.value ||
        isCategoryListLoading.value ||
        isFeatureFlagsList.value
    );

    const tabList = computed<Array<TabType>>(() => {
      return [
        { id: "general", label: "General" },
        { id: "game-actions", label: "Game Actions" },
        { id: "activity", label: "Activity" },
        { id: "activity-calendar", label: "Activity Calendar" },
        { id: "android_queue", label: "Queue - Android" },
        { id: "ios_queue", label: "Queue - iOS" },
        { id: "stats", label: "Stats" },
        { id: "launcher", label: "Launcher Apps" },
        { id: "launcher-history", label: "Launcher History" },
        { id: "devices", label: "Devices" },
        { id: "push-notifications", label: "Push Notifications" },
      ].filter(isNotNullish);
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const updatePayload = convertFormValuesToCreationOrUpdatePayload(
        values.value as FormValues
      );

      const requestPromise = updateUser(id.value, updatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getGamesUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getGamesListUrl());
          }
          toast.show({
            variant: "success",
            title: "Users",
            body: "User successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "User",
            body: "Error updating users",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    return {
      isLoading,
      values,
      errors,
      model,
      isSubmitting,
      backButtonUrl: getUsersListUrl(),
      tabList,
      selectedTabId,

      featureFlagOptions,
      tagOptions,
      categoryOptions,
      campaignOptions,
      submitForm,
    };
  },
});

import { request, ResponseBody } from "@tager/admin-services";

import { SubscriptionInterface } from "@/modules/subscriptions/typings";

export interface CountData {
  count: number;
}

export function getSubscriptionsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/subscriptions/count" });
}

export function getSubscriptionsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<SubscriptionInterface>>> {
  return request.get({ path: "/admin/subscriptions", params });
}


import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";
import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldSelect,
} from "@tager/admin-ui";

import {
  getCallToActionsListUrl,
  getCallToActionsUpdateUrl,
} from "@/utils/paths";

import {
  updateCallToAction,
  createCallToAction,
  getCallToAction,
} from "../services";
import { callToActionTypesOptions, CallToActionType } from "../constants";

import {
  FormValues,
  getFormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
  getEventsFieldConfig,
} from "./CallToActionsFormView.helpers";

export default defineComponent({
  name: "CallToActionsFormView",
  components: {
    Page,
    FormField,
    FormFooter,
    FormFieldSelect,
    DynamicField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getCallToAction(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "CTA",
    });

    onMounted(() => {
      fetchModel();
    });

    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getFormValues(model.value));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getFormValues(model.value);
    }

    const eventsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(
        getEventsFieldConfig(values.value.type?.value),
        null
      )
    );

    const typeValue = computed<string>(() => {
      return values.value.type?.value || "";
    });

    watch([typeValue], () => {
      eventsField.value = universalFieldUtils.createFormField(
        getEventsFieldConfig(values.value.type?.value),
        null
      );
    });

    function updateEventsFields() {
      const incomingFieldList: RepeaterIncomingValue = model.value
        ?.amplitudeEvents
        ? model.value?.amplitudeEvents.map((value) => {
            return [
              {
                name: "type",
                value: value.type,
              },
              {
                name: "event",
                value: value.event,
              },
              {
                name: "params",
                value: value.params.map((item) => [
                  {
                    name: "param",
                    value: item.param,
                  },
                  {
                    name: "value",
                    value: item.value,
                  },
                ]),
              },
            ];
          })
        : [];

      eventsField.value = universalFieldUtils.createFormField(
        getEventsFieldConfig(values.value.type?.value),
        incomingFieldList
      ) as RepeaterField;
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();

      setTimeout(() => {
        updateEventsFields();
      }, 0);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(
          values.value,
          eventsField.value as RepeaterField
        );

      const requestPromise = isCreation.value
        ? createCallToAction(createOrUpdatePayload)
        : updateCallToAction(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getCallToActionsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getCallToActionsListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "CTAs",
            body: isCreation.value
              ? "CTA successfully created"
              : "CTA successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "CTAs",
            body: isCreation.value
              ? "Error creation CTA"
              : "Error updating CTA",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(() => isModelLoading.value);

    return {
      callToActionTypesOptions,
      CallToActionType,

      isLoading,
      values,
      errors,
      model,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getCallToActionsListUrl(),
      eventsField,
    };
  },
});

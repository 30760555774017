import type { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";
import UsersPushNotificationsListView from "@/modules/users/UsersPushNotificationsListView/UsersPushNotificationsListView.vue";
import UsersPushNotificationsDetailsView from "@/modules/users/UsersPushNotificationsDetailsView/UsersPushNotificationsDetailsView.vue";
import UsersListView from "@/modules/users/UsersListView";
import UsersFormView from "@/modules/users/UsersFormView";
import UsersSendPush from "@/modules/users/UsersSendPush";
import UsersStatsView from "@/modules/users/UsersStatsView";

export const USERS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_LIST,
  component: UsersListView,
  name: "Users List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().USERS],
  },
};

export const USERS_STATS_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_STATS,
  component: UsersStatsView,
  name: "Users Stats",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().USERS],
  },
};

export const USERS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_FORM,
  component: UsersFormView,
  name: "Users Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().USERS,
      {
        url: route.path,
        text: "User",
      },
    ],
  },
};

export const USERS_PUSH_NOTIFICATIONS_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_PUSH_NOTIFICATIONS,
  component: UsersPushNotificationsListView,
  name: "Users Push Notifications",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().USERS],
  },
};

export const USERS_PUSH_NOTIFICATIONS_DETAILS_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_PUSH_NOTIFICATIONS_DETAILS,
  component: UsersPushNotificationsDetailsView,
  name: "Users Push Notifications Details Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().USERS,
      getLinks().USERS_PUSH_NOTIFICATIONS,
      {
        url: route.path,
        text: "Push Notification",
      },
    ],
  },
};

export const USERS_SEND_PUSH_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_PUSH,
  component: UsersSendPush,
  name: "Users Send Push",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().USERS],
  },
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tabId": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: `Update Poll ID ${_ctx.isLoading || !_ctx.model ? '...' : _ctx.model.id}`,
    "is-content-loading": _ctx.isLoading,
    tabs: !_ctx.isLoading ? _ctx.tabs : []
  }, {
    footer: _withCtx(() => [
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "back-href": _ctx.backButtonUrl,
            "is-submitting": _ctx.isSubmitting,
            "is-creation": false,
            "can-create-another": false,
            onSubmit: _ctx.submitForm
          }, null, 8, ["back-href", "is-submitting", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.values)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'general' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormField, {
                value: _ctx.values.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
                name: "name",
                error: _ctx.errors.name,
                label: "Name"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.active,
                "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.active) = $event)),
                name: "active",
                error: _ctx.errors.active,
                label: "Active"
              }, null, 8, ["checked", "error"])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'questions' ? 'block' : 'none' })
            }, [
              _createVNode(_component_DynamicField, { field: _ctx.questionsField }, null, 8, ["field"])
            ], 4)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40098d7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reviews-list-view-visible-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseCheckbox, {
      checked: _ctx.value,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      disabled: _ctx.disabled,
      onChange: _ctx.change
    }, null, 8, ["checked", "disabled", "onChange"])
  ]))
}
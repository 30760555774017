
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FieldValue,
  FormFieldOptionsSwitcherTrueFalse,
} from "@tager/admin-ui";

import { getReviewsListUrl } from "@/modules/reviews";

import { getReview, updateReview } from "../services";

import {
  FormValues,
  getFormValues,
  convertFormValuesToPayload,
} from "./ReviewsUpdateView.helpers";

export default defineComponent({
  name: "DevelopersFormView",
  components: {
    Page,
    FormFieldOptionsSwitcherTrueFalse,
    FormFooter,
    FieldValue,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => +route.params.id);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => getReview(id.value as number),
      initialValue: null,
      resourceName: "Review",
    });

    const initPage = () => {
      fetchModel();
    };

    onMounted(initPage);
    onBeforeRouteUpdate(initPage);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getFormValues(model.value));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getFormValues(model.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const payload = convertFormValuesToPayload(values.value);

      updateReview(id.value, payload)
        .then(() => {
          errors.value = {};

          if (event.type === "save_exit") {
            navigateBack(router, getReviewsListUrl());
          }

          toast.show({
            variant: "success",
            title: "Reviews",
            body: "Review successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);

          toast.show({
            variant: "danger",
            title: "Reviews",
            body: "Error update review",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(() => isModelLoading.value);

    return {
      isLoading,
      values,
      errors,
      model,
      isSubmitting,
      submitForm,
      backButtonUrl: getReviewsListUrl(),
    };
  },
});

import { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";

import CampaignsListView from "./CampaignsListView/CampaignsListView.vue";
import CampaignsFormView from "./CampaignsFormView/CampaignsFormView.vue";

export const CAMPAIGNS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CAMPAIGNS_LIST,
  component: CampaignsListView,
  name: "Campaigns List",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: "/campaigns", text: "Campaigns" },
    ],
  },
};

export const CAMPAIGNS_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.DEVELOPERS_CREATE,
  component: CampaignsFormView,
  name: "Campaigns Create",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      { url: "/campaigns", text: "Campaigns" },
      {
        url: route.path,
        text: "New Campaign",
      },
    ],
  },
};

export const CAMPAIGNS_UPDATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CAMPAIGNS_FORM,
  component: CampaignsFormView,
  name: "Campaigns Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      { url: "/campaigns", text: "Campaigns" },
      {
        url: route.path,
        text: "Update Campaign",
      },
    ],
  },
};

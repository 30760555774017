import { OptionType } from "@tager/admin-ui";
import { Nullable } from "@tager/admin-services";

import { PlaylistCreatePayload } from "../services";

export type FormValues = {
  name: string;
  user: Nullable<OptionType<number>>;
};

export const DEFAULT_FORM_VALUES: FormValues = {
  name: "",
  user: null,
};

export function convertPageFormValuesToCreationPayload(
  values: FormValues
): PlaylistCreatePayload {
  return {
    name: values.name,
    user: values.user?.value || null,
  };
}

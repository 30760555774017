import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73ab6c2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_TableCellUser = _resolveComponent("TableCellUser")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Playlists",
    "header-buttons": [{ text: 'New Playlist', href: _ctx.getPlaylistsCreateUrl() }]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "use-search": true,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        sort: { options: _ctx.sortOptions, value: _ctx.sortValue },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.tagRemovalHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.categoryFilter,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.categoryFilter) = $event)),
                  label: "Category:",
                  name: "categoryFilter",
                  searchable: false,
                  options: _ctx.categoryOptions
                }, null, 8, ["value", "options"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.webAvailableFilter,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.webAvailableFilter) = $event)),
                  label: "Web Available:",
                  name: "webAvailableFilter",
                  searchable: false,
                  options: _ctx.webAvailableOptions
                }, null, 8, ["value", "options"]),
                _createVNode(_component_FormField, {
                  value: _ctx.userFilter,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userFilter) = $event)),
                  label: "User ID:",
                  name: "userFilter"
                }, null, 8, ["value"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.languageFilter,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.languageFilter) = $event)),
                  label: "Language:",
                  name: "languageFilter",
                  searchable: false,
                  clearable: true,
                  options: _ctx.LANGUAGE_OPTIONS
                }, null, 8, ["value", "options"])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(user)": _withCtx(({ row }) => [
          (row.user)
            ? (_openBlock(), _createBlock(_component_TableCellUser, {
                key: 0,
                user: row.user
              }, null, 8, ["user"]))
            : _createCommentVNode("", true)
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Просмотреть",
            href: _ctx.getPlaylistsViewUrl(row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EyeIcon)
            ]),
            _: 2
          }, 1032, ["href"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "sort", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}
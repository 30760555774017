import { OptionType } from "@tager/admin-ui";

export type Language = "EN" | "ES" | "DE" | "FR" | "IT" | "PT" | "PT-BR";

export const LANGUAGE_OPTIONS: Array<OptionType<Language | null>> = [
  { value: null, label: "All Languages" },
  { value: "EN", label: "English" },
  { value: "ES", label: "Spanish" },
  { value: "FR", label: "French" },
  { value: "IT", label: "Italian" },
  { value: "DE", label: "German" },
  { value: "PT", label: "Portugal" },
  { value: "PT-BR", label: "Portugal-Brazil" },
];

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldAjaxSelect = _resolveComponent("FormFieldAjaxSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Create Playlist" }, {
    content: _withCtx(() => [
      _createVNode(_component_FormFieldAjaxSelect, {
        value: _ctx.values.user,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.user) = $event)),
        name: "user",
        label: "User",
        "value-field": "value",
        "label-field": "label",
        "request-url": "/admin/users/ajax-search"
      }, null, 8, ["value"]),
      _createVNode(_component_FormField, {
        value: _ctx.values.name,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.name) = $event)),
        error: _ctx.errors.name,
        name: "name",
        label: "Name"
      }, null, 8, ["value", "error"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": true,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    _: 1
  }))
}
import { request, ResponseBody } from "@tager/admin-services";

import { FollowingInterface } from "@/modules/followings/typings";

export interface CountData {
  count: number;
}

export function getFollowingsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/followings/count" });
}

export function getFollowingList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<FollowingInterface>>> {
  return request.get({ path: "/admin/followings", params });
}

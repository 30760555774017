
import { defineComponent, onMounted } from "vue";

import { ColumnDefinition, DataTable, useDataTable } from "@tager/admin-ui";

import { getUserGamesQueue } from "@/modules/users/services";
import { UserGameInterface } from "@/modules/users/typings";
import { getGamesUpdateUrl } from "@/utils/paths";
import GamesListViewPlatform from "@/modules/games/GamesListView/components/GamesListViewPlatform.vue";

export const COLUMN_DEFS: Array<ColumnDefinition<UserGameInterface>> = [
  {
    id: 1,
    name: "#",
    field: "num",
    format: ({ row, rowIndex }) => {
      return rowIndex + 1;
    },
    style: {
      width: "40px",
      textAlign: "center",
    },
    headStyle: {
      width: "40px",
      textAlign: "center",
    },
  },
  {
    id: 2,
    name: "Name",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getGamesUpdateUrl(row.id),
      },
    }),
  },
  {
    id: 3,
    name: "Game",
    field: "game",
    headStyle: {
      width: "150px",
      textAlign: "center",
    },
  },
  {
    id: 4,
    name: "Genres",
    field: "categories",
    type: "list",
    headStyle: {
      width: "220px",
    },
  },
  {
    id: 5,
    name: "Categories",
    field: "tags",
    type: "list",
    headStyle: {
      width: "180px",
    },
  },
];

export default defineComponent({
  name: "UsersFormGamesList",
  components: {
    GamesListViewPlatform,
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserGameInterface>({
      fetchEntityList: () => getUserGamesQueue(props.id, props.platform),
      initialValue: [],
      resourceName: "Games List",
    });

    onMounted(() => {
      fetchData();
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});

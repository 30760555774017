
import { computed, defineComponent, onMounted } from "vue";

import { BaseButton, DataTable, EditIcon } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import {
  getCampaignsCreateUrl,
  getCampaignsUpdateUrl,
} from "@/modules/campaigns/links";

import { getCampaigns } from "../services";
import { CampaignInterface } from "../typings";

import { COLUMN_DEFS } from "./CampaignsListView.helpers";

export default defineComponent({
  name: "CampaignsListView",
  components: {
    EditIcon,
    BaseButton,
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchData,
      isLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<CampaignInterface>({
      fetchEntityList: (params) =>
        getCampaigns({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Campaigns List",
    });

    onMounted(() => {
      fetchData();
    });

    const isDataLoading = computed<boolean>(() => isLoading.value);

    return {
      columnDefs: COLUMN_DEFS,

      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
  methods: { getCampaignsUpdateUrl, getCampaignsCreateUrl },
});

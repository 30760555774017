import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableCellUser = _resolveComponent("TableCellUser")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Deleted Playlists" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "use-search": true,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        "cell(user)": _withCtx(({ row }) => [
          (row.user)
            ? (_openBlock(), _createBlock(_component_TableCellUser, {
                key: 0,
                user: row.user
              }, null, 8, ["user"]))
            : _createCommentVNode("", true)
        ]),
        "cell(games)": _withCtx(({ row }) => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.games, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id
              }, [
                _createVNode(_component_router_link, {
                  to: `/games/${item.id}`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.id) + " - " + _toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }))
}
import { request, ResponseBody } from "@tager/admin-services";

import {
  PollFullInterface,
  PollInterface,
  PollsCreatePayload,
  PollsUpdatePayload,
} from "@/modules/polls/typings";

export interface CountData {
  count: number;
}

export function getPollsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/polls/count" });
}

export function getPollsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<PollInterface>>> {
  return request.get({ path: "/admin/polls", params });
}

export function getPoll(
  id: string | number
): Promise<ResponseBody<PollFullInterface>> {
  return request.get({ path: `/admin/polls/${id}` });
}

export function createPoll(
  payload: PollsCreatePayload
): Promise<ResponseBody<PollFullInterface>> {
  return request.post({
    path: `/admin/polls`,
    body: payload,
  });
}

export function updatePoll(
  id: number,
  body: PollsUpdatePayload
): Promise<ResponseBody<PollFullInterface>> {
  return request.put({ path: `/admin/polls/${id}`, body });
}

export function clonePoll(
  id: number | string
): Promise<ResponseBody<PollFullInterface>> {
  return request.post({ path: `/admin/polls/${id}/clone` });
}

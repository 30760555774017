
import { computed, defineComponent, onMounted } from "vue";

import { DataTable, useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { SubscriptionInterface } from "@/modules/subscriptions/typings";
import { getSubscriptionsList } from "@/modules/subscriptions/services";

import { COLUMN_DEFS } from "./SubscriptionsListView.helpers";

export default defineComponent({
  name: "SubscriptionsListView",
  components: {
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchEntityList,
      isLoading: isItemsLoading,
      rowData: gamesData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<SubscriptionInterface>({
      fetchEntityList: (params) =>
        getSubscriptionsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Subscriptions List",
    });
    onMounted(() => {
      fetchEntityList();
    });

    const isDataLoading = computed<boolean>(() => isItemsLoading.value);

    return {
      columnDefs: COLUMN_DEFS,
      rowData: gamesData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});

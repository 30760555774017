import { request, ResponseBody } from "@tager/admin-services";

import {
  CampaignFullInterface,
  CampaignInterface,
} from "@/modules/campaigns/typings";

export function getCampaigns(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CampaignInterface>>> {
  return request.get({ path: "/admin/campaigns", params });
}

export function getCampaign(
  id: string | number
): Promise<ResponseBody<CampaignFullInterface>> {
  return request.get({ path: `/admin/campaigns/${id}` });
}

export type CampaignPayload = {
  name: string;
  active: boolean;
  downloadUrl: string | null;
  developer: number | null;
  games: Array<number>;
  gamelists: Array<number>;
  reviews: Array<number>;
};

export function createCampaign(
  payload: CampaignPayload
): Promise<ResponseBody<CampaignFullInterface>> {
  return request.post({
    path: `/admin/campaigns`,
    body: payload,
  });
}

export function updateCampaign(
  id: number | string,
  payload: CampaignPayload
): Promise<ResponseBody<CampaignFullInterface>> {
  return request.put({
    path: `/admin/campaigns/${id}`,
    body: payload,
  });
}

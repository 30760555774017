
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormField,
  FormFieldFileInput,
  FormFieldMultiSelect,
  FormFieldUrlAliasInput,
  FormFooter,
  OptionType,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { getCategoriesListUrl, getCategoriesUpdateUrl } from "@/utils/paths";
import app from "@/views/App.vue";
import CategoriesTopRight from "@/modules/categories/CategoriesFormView/containers/CategoriesTopRight.vue";

import {
  createCategory,
  getAppMagicCategories,
  getCategory,
  updateCategory,
} from "../services";

import {
  convertPageFormValuesToCreationOrUpdatePayload,
  FormValues,
  getCategoryFormValues,
  onboardingGamesDataConfig,
} from "./CategoriesFormView.helpers";

export default defineComponent({
  name: "CategoriesFormView",
  components: {
    CategoriesTopRight,
    FormFieldFileInput,
    FormFieldMultiSelect,
    FormFieldUrlAliasInput,
    DynamicField,
    Page,
    FormField,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getCategory(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Category",
    });

    const [
      fetchAppMagicCategories,
      { data: appMagicCategories, loading: isAppMagicCategoriesLoading },
    ] = useResource({
      fetchResource: () => getAppMagicCategories(),
      initialValue: [],
      resourceName: "AppMagic Categories List",
    });

    onMounted(() => {
      fetchModel();
      fetchAppMagicCategories();
    });

    watch(id, fetchModel);

    const onboardingGames = ref<FieldUnion>(
      universalFieldUtils.createFormField(onboardingGamesDataConfig, null)
    );

    watch(model, () => {
      const incomingOnboardingGamesFieldList: RepeaterIncomingValue =
        model.value?.onboardingGames?.map((item) => {
          return [
            {
              name: "game",
              value: {
                value: item.id,
                label: `ID ${item.id} - ${item.name}`,
              },
            },
          ];
        }) || [];

      onboardingGames.value = universalFieldUtils.createFormField(
        onboardingGamesDataConfig,
        incomingOnboardingGamesFieldList
      );
    });

    const appMagicCategoriesOptions = computed<Array<OptionType<number>>>(
      () =>
        appMagicCategories.value?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }) || []
    );

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getCategoryFormValues(model.value, appMagicCategoriesOptions.value)
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getCategoryFormValues(
        model.value,
        appMagicCategoriesOptions.value
      );
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model, appMagicCategoriesOptions], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(
          values.value,
          onboardingGames.value as RepeaterField
        );

      const requestPromise = isCreation.value
        ? createCategory(createOrUpdatePayload)
        : updateCategory(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getCategoriesUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getCategoriesListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getCategoryFormValues(
              null,
              appMagicCategoriesOptions.value
            );
          }

          toast.show({
            variant: "success",
            title: "Genres",
            body: isCreation.value
              ? "Category successfully created"
              : "Category successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Genres",
            body: isCreation.value
              ? "Error creation category"
              : "Error updating category",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isAppMagicCategoriesLoading.value
    );

    let websiteOrigin: string =
      process.env.VUE_APP_WEBSITE_URL || window.location.origin;
    if (websiteOrigin.substr(-1) === "/") {
      websiteOrigin = websiteOrigin.substr(0, websiteOrigin.length - 1);
    }

    return {
      isLoading,
      values,
      errors,
      model,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getCategoriesListUrl(),
      onboardingGames,
      websiteOrigin,
      appMagicCategoriesOptions,
    };
  },
});


import { defineComponent } from "vue";

import { DataTable, EditIcon, BaseButton, DeleteIcon } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete } from "@tager/admin-services";

import { getDevelopersUpdateUrl, getDevelopersCreateUrl } from "@/utils/paths";

import { deleteDeveloper, getDevelopersList } from "../services";
import { DeveloperInterface } from "../typings";

import { COLUMN_DEFS, SORT_OPTIONS } from "./DevelopersListView.helpers";

export default defineComponent({
  name: "DevelopersListView",
  components: {
    Page,
    DataTable,
    EditIcon,
    BaseButton,
    DeleteIcon,
  },
  setup() {
    const {
      fetchEntityList: fetchData,
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<DeveloperInterface>({
      fetchEntityList: (params) =>
        getDevelopersList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort,
        }),
      initialValue: [],
      resourceName: "Developers List",
      defaultSort: SORT_OPTIONS[0].value,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteDeveloper,
      resourceName: "Country",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      sortOptions: SORT_OPTIONS,
      sortValue: sort,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getDevelopersUpdateUrl,
      getDevelopersCreateUrl,
      handleResourceDelete,
    };
  },
});

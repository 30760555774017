import { Nullable } from "@tager/admin-services";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { PlaylistFullInterface } from "../typings";
import { PlaylistUpdatePayload } from "../services";

export type FormValues = {
  featuredEn: boolean;
  featuredIt: boolean;
  featuredEs: boolean;
  featuredDe: boolean;
  featuredFr: boolean;
  featuredPt: boolean;
  featuredPtBr: boolean;
  featuredPosition: number | null;
  weekly: boolean;
  weeklyDateStart: string | null;
  weeklyDateEnd: string | null;

  webAvailable: boolean;
  webUrlAlias: string | null;
  webDescription: string | null;
};

export function getCategoryFormValues(
  model: Nullable<PlaylistFullInterface>
): FormValues {
  if (!model) {
    return {
      featuredEn: false,
      featuredEs: false,
      featuredIt: false,
      featuredDe: false,
      featuredFr: false,
      featuredPt: false,
      featuredPtBr: false,
      featuredPosition: null,
      weekly: false,
      weeklyDateStart: null,
      weeklyDateEnd: null,
      webAvailable: false,
      webUrlAlias: null,
      webDescription: null,
    };
  }

  return {
    featuredEn: model.featuredEn,
    featuredDe: model.featuredDe,
    featuredIt: model.featuredIt,
    featuredEs: model.featuredEs,
    featuredFr: model.featuredFr,
    featuredPt: model.featuredPt,
    featuredPtBr: model.featuredPtBr,
    featuredPosition: model.featuredPriority,
    weekly: model.weekly,
    weeklyDateStart: model.weeklyDateStart,
    weeklyDateEnd: model.weeklyDateEnd,
    webAvailable: model.webAvailable,
    webUrlAlias: model.webUrlAlias,
    webDescription: model.webDescription,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues,
  games: RepeaterField,
  webFaq: RepeaterField,
  webTextBlocks: RepeaterField
): PlaylistUpdatePayload {
  const webFaqValue = webFaq.value
    ? webFaq.value.map((item) => {
        return {
          question: String(item.value[0].value),
          answer: String(item.value[1].value),
        };
      })
    : [];

  const webTextBlocksValue = webTextBlocks.value
    ? webTextBlocks.value.map((item) => {
        return {
          title: String(item.value[0].value),
          body: String(item.value[1].value),
        };
      })
    : [];

  return {
    featuredEn: values.featuredEn,
    featuredIt: values.featuredIt,
    featuredFr: values.featuredFr,
    featuredEs: values.featuredEs,
    featuredDe: values.featuredDe,
    featuredPt: values.featuredPt,
    featuredPtBr: values.featuredPtBr,
    featuredPriority: values.featuredPosition,

    weekly: values.weekly,
    weeklyDateStart: values.weeklyDateStart,
    weeklyDateEnd: values.weeklyDateEnd,

    webAvailable: values.webAvailable,
    webUrlAlias: values.webAvailable ? values.webUrlAlias : null,
    webDescription: values.webDescription ? values.webDescription : null,
    webFaq: webFaqValue,
    webTextBlocks: webTextBlocksValue,

    games:
      games.value.map((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return item.value[0]?.value.value;
      }) || [],
  };
}

export const playlistGamesDynamicFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Games",
  meta: {
    view: "TABLE",
  },
  fields: [
    {
      name: "game",
      type: "AJAX_SELECT",
      label: "Game",
      meta: {
        requestUrl: `/admin/games/ajax-search`,
      },
    },
  ],
};

export const fieldWebFaqConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "FAQ",
  meta: {
    view: "BLOCK",
    defaultIsOpen: true,
    noToggle: true,
    titleFormatter: (values) => {
      return String(values[0].value) || "Question";
    },
  },
  fields: [
    {
      name: "question",
      type: "STRING",
      label: "Question",
    },
    {
      name: "answer",
      type: "HTML",
      label: "Answer",
    },
  ],
};
export const fieldWebTextBlocksConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Text Blocks",
  meta: {
    view: "BLOCK",
    defaultIsOpen: true,
    noToggle: true,
    titleFormatter: (values) => {
      return String(values[0].value) || "Title";
    },
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Title",
    },
    {
      name: "body",
      type: "HTML",
      label: "Body",
    },
  ],
};

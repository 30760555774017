import { OptionType } from "@tager/admin-ui";

export enum CallToActionType {
  QueueQuiz = "QUEUE_QUIZ",
  QueueRate = "QUEUE_RATE",
  QueueInvite = "QUEUE_INVITE",
  NewUsersInactivityPush = "NEW_USERS_INACTIVITY_PUSH",
}

export const callToActionTypesOptions: Array<OptionType> = [
  {
    value: CallToActionType.QueueQuiz,
    label: "Queue - Quiz",
  },
  {
    value: CallToActionType.QueueRate,
    label: "Queue - Rate",
  },
  {
    value: CallToActionType.QueueInvite,
    label: "Queue - Invite",
  },
  {
    value: CallToActionType.NewUsersInactivityPush,
    label: "Push Notification - New Users Inactivity",
  },
];


import { defineComponent, ref } from "vue";

import { BaseCheckbox } from "@tager/admin-ui";

import { toggleReview } from "@/modules/reviews/services";

export default defineComponent({
  name: "ReviewsListViewVisibleCell",
  components: { BaseCheckbox },
  props: {
    id: {
      type: Number,
      required: true,
    },
    hidden: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const value = ref<boolean>(props.hidden);
    const disabled = ref<boolean>(false);

    const change = async () => {
      value.value = !value.value;

      disabled.value = true;
      try {
        await toggleReview(props.id);
      } finally {
        disabled.value = false;
      }
    };

    return {
      value,
      change,
      disabled,
    };
  },
});

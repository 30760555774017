import { ColumnDefinition } from "@tager/admin-ui";

import { FollowingInterface } from "@/modules/followings/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<FollowingInterface>> = [
  {
    id: 1,
    name: "Datetime",
    field: "datetime",
    type: "datetime",
  },
  {
    id: 2,
    name: "User",
    field: "user",
    format: ({ row }) => {
      return row.user?.name || "-";
    },
  },
  {
    id: 3,
    name: "Following",
    field: "followingUser",
    format: ({ row }) => {
      return row.followingUser?.name || "-";
    },
  },
];

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dffd8400"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-3" }
const _hoisted_2 = { class: "checkbox-filter" }
const _hoisted_3 = {
  key: 0,
  class: "prerelease"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Games Platforms" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        sort: { options: _ctx.sortOptions, value: _ctx.sortValue },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.handleTagRemove
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.statusFilter,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statusFilter) = $event)),
                  name: "status",
                  label: "Status",
                  options: _ctx.statusFilterOptions
                }, null, 8, ["value", "options"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.platformFilter,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.platformFilter) = $event)),
                  name: "platform",
                  label: "Platform",
                  options: _ctx.platformFilterOptions
                }, null, 8, ["value", "options"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_FormFieldCheckbox, {
                    checked: _ctx.preReleaseFilter,
                    "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.preReleaseFilter) = $event)),
                    name: "prerelease",
                    label: "Pre-Release"
                  }, null, 8, ["checked"]),
                  _createVNode(_component_FormFieldCheckbox, {
                    checked: _ctx.noScreenshotsFilter,
                    "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.noScreenshotsFilter) = $event)),
                    name: "no-screenshots",
                    label: "No Screenshots"
                  }, null, 8, ["checked"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(release)": _withCtx(({ row }) => [
          _createTextVNode(_toDisplayString(row.releaseDateRaw ? row.releaseDateRaw : (row.releaseDate?.split("-").reverse().join(".") || '')) + " ", 1),
          (row.isPrerelease)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Pre-Release "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "sort", "onChange"])
    ]),
    _: 1
  }))
}

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  isNotNullish,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  BaseButton,
  createTabErrorFinder,
  FieldValue,
  FormField,
  FormFieldCheckbox,
  FormFieldMultiSelect,
  FormFieldUrlAliasInput,
  FormFieldRichTextInput,
  FormFieldOptionsSwitcherTrueFalse,
  FormFooter,
  OptionType,
  TabType,
  TagerFormSubmitEvent,
  ToggleSection,
  FormFieldAjaxSelect,
} from "@tager/admin-ui";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { getGamesListUrl, getGamesUpdateUrl } from "@/utils/paths";
import { GameFullInterface } from "@/modules/games/typings";
import { getCategoriesList } from "@/modules/categories/services";
import { getTagsList } from "@/modules/tags/services";
import { getDevelopersList } from "@/modules/developers";

import {
  activatePlatform,
  deletePlatform,
  getGame,
  hidePlatform,
  showPlatform,
  syncGameWithAppMagic,
  syncGameWithAppTweak,
  updateGame,
} from "../services";

import GamesUpdateViewPlatform from "./components/GamesUpdateViewPlatform.vue";
import GamesUpdateViewPlatformMedia from "./components/GamesUpdateViewPlatformMedia.vue";
import {
  convertFormValuesToUpdatePayload,
  fieldVideoStreamsDataConfig,
  fieldWebFaqConfig,
  fieldWebTextBlocksConfig,
  FormValues,
  getGameFormValues,
} from "./GamesUpdateView.helpers";

export default defineComponent({
  name: "GamesUpdateView",
  components: {
    FormFieldAjaxSelect,
    DynamicField,
    FormFieldCheckbox,
    FormFieldMultiSelect,
    FormFieldOptionsSwitcherTrueFalse,
    FormField,
    FormFieldRichTextInput,
    FieldValue,
    Page,
    FormFooter,
    GamesUpdateViewPlatform,
    GamesUpdateViewPlatformMedia,
    BaseButton,
    ToggleSection,
    FormFieldUrlAliasInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const isActionLoading = ref<boolean>(false);
    const androidEnabled = ref<boolean>(false);
    const androidVisible = ref<boolean>(false);
    const iosEnabled = ref<boolean>(false);
    const iosVisible = ref<boolean>(false);
    const iosAltStoresDeleted = ref<string[]>([]);
    const androidAltStoresDeleted = ref<string[]>([]);

    const id = computed(() => route.params.id as string);
    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getGame(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Game",
    });

    const [
      fetchDeveloperList,
      { data: developerList, loading: isDeveloperListLoading },
    ] = useResource({
      fetchResource: () =>
        getDevelopersList({
          pageNumber: 1,
          pageSize: 100000,
          view: "short",
        }),
      initialValue: [],
      resourceName: "Developer list",
    });

    const [
      fetchCategoryList,
      { data: categoryList, loading: isCategoryListLoading },
    ] = useResource({
      fetchResource: () => getCategoriesList(),
      initialValue: [],
      resourceName: "Categories list",
    });

    const [fetchTagList, { data: tagList, loading: isTagListLoading }] =
      useResource({
        fetchResource: () => getTagsList(),
        initialValue: [],
        resourceName: "Tag list",
      });

    onMounted(() => {
      fetchModel();
      fetchCategoryList();
      fetchDeveloperList();
      fetchTagList();
    });

    watch(id, fetchModel);

    const tagOptions = computed<Array<OptionType<number>>>(() => {
      return tagList.value.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }));
    });

    const developerOptions = computed<Array<OptionType<number>>>(() => {
      return developerList.value.map((developer) => ({
        value: developer.id,
        label: "ID " + developer.id + ": " + developer.name,
      }));
    });

    const categoryOptions = computed<Array<OptionType<number>>>(() => {
      return categoryList.value.map((category) => ({
        value: category.id,
        label: category.name,
      }));
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<Nullable<FormValues>>(
      model.value
        ? getGameFormValues(
            model.value as GameFullInterface,
            categoryOptions.value,
            tagOptions.value
          )
        : null
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = model.value
        ? getGameFormValues(
            model.value as GameFullInterface,
            categoryOptions.value,
            tagOptions.value
          )
        : null;

      iosEnabled.value = Boolean(model.value?.ios.enabled);
      iosVisible.value = !model.value?.ios.hidden;
      androidEnabled.value = Boolean(model.value?.android.enabled);
      androidVisible.value = !model.value?.android.hidden;
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
      updateVideoStreamsData();
      updateWebFaq();
      updateWebTextBlocks();
    });

    watch([categoryOptions, tagOptions], () => {
      updateFormValues();
    });

    const videoStreams = ref<FieldUnion>(
      universalFieldUtils.createFormField(fieldVideoStreamsDataConfig, null)
    );

    const webFaq = ref<FieldUnion>(
      universalFieldUtils.createFormField(fieldWebFaqConfig, null)
    );

    const webTextBlocks = ref<FieldUnion>(
      universalFieldUtils.createFormField(fieldWebTextBlocksConfig, null)
    );

    function updateVideoStreamsData() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.videoStreams
        ? model.value?.videoStreams?.map((item) => {
            return [
              {
                name: "name",
                value: item.name,
              },
              {
                name: "youtubeUrl",
                value: item.url,
              },
              {
                name: "author",
                value: item.author,
              },
              {
                name: "datetime",
                value: item.datetime,
              },
            ];
          })
        : [];

      if (model.value) {
        videoStreams.value = universalFieldUtils.createFormField(
          fieldVideoStreamsDataConfig,
          incomingFieldList
        );
      }
    }

    function updateWebFaq() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.web.faq
        ? model.value?.web.faq?.map((item) => {
            return [
              {
                name: "question",
                value: item.question,
              },
              {
                name: "answer",
                value: item.answer,
              },
            ];
          })
        : [];

      if (model.value) {
        webFaq.value = universalFieldUtils.createFormField(
          fieldWebFaqConfig,
          incomingFieldList
        );
      }
    }

    function updateWebTextBlocks() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.web
        .textBlocks
        ? model.value?.web.textBlocks?.map((item) => {
            return [
              {
                name: "title",
                value: item.title,
              },
              {
                name: "body",
                value: item.body,
              },
            ];
          })
        : [];

      if (model.value) {
        webTextBlocks.value = universalFieldUtils.createFormField(
          fieldWebTextBlocksConfig,
          incomingFieldList
        );
      }
    }

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload = convertFormValuesToUpdatePayload(
        values.value as FormValues,
        videoStreams.value as RepeaterField,
        androidAltStoresDeleted.value,
        iosAltStoresDeleted.value,
        webFaq.value as RepeaterField,
        webTextBlocks.value as RepeaterField
      );

      updateGame(id.value || "", createOrUpdatePayload)
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getGamesUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getGamesListUrl());
          }
          toast.show({
            variant: "success",
            title: "Games",
            body: isCreation.value
              ? "Game successfully created"
              : "Game successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Games",
            body: isCreation.value
              ? "Error creation game"
              : "Error updating game",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () =>
        isActionLoading.value ||
        isModelLoading.value ||
        isTagListLoading.value ||
        isCategoryListLoading.value ||
        isDeveloperListLoading.value
    );

    const tabList = computed<Array<TabType>>(() => {
      const hasErrors = createTabErrorFinder(errors.value);

      return [
        {
          id: "general",
          label: "General",
          hasErrors: hasErrors([
            "name",
            "displayOnOnboarding",
            "categories",
            "tags",
          ]),
        },
        {
          id: "web",
          label: "Web",
          hasErrors: hasErrors(["webUrlAlias", "webHeading", "webDescription"]),
        },
        {
          id: "android",
          label:
            "Android" +
            (!androidEnabled.value
              ? " (Disabled)"
              : !androidVisible.value
              ? " (Hidden)"
              : ""),
          hasErrors: hasErrors([
            "androidStoreUrl",
            "androidStoreRating",
            "androidFirstReleaseDate",
            "androidPreRelease",
            "androidDeveloper",
            "androidSize",
            "androidLastReleaseDate",
            "androidLastVersion",
            "androidFullDescription",
          ]),
        },
        androidEnabled.value
          ? {
              id: "android_media",
              label: "Android - Media",
              hasErrors: hasErrors([
                "androidIcon",
                "androidScreenshots",
                "androidVideos",
              ]),
            }
          : null,
        {
          id: "ios",
          label:
            "iOS" +
            (!iosEnabled.value
              ? " (Disabled)"
              : !iosVisible.value
              ? " (Hidden)"
              : ""),
          hasErrors: hasErrors([
            "iosStoreUrl",
            "iosStoreRating",
            "iosFirstReleaseDate",
            "iosPreRelease",
            "iosDeveloper",
            "iosSize",
            "iosLastReleaseDate",
            "iosLastVersion",
            "iosFullDescription",
          ]),
        },
        iosEnabled.value
          ? {
              id: "ios_media",
              label: "iOS - Media",
              hasErrors: hasErrors(["iosIcon", "iosScreenshots", "iosVideos"]),
            }
          : null,
        {
          id: "media",
          label: "Media",
          hasErrors: hasErrors(["restrictSyncVideoStreams", "videoStreams"]),
        },
        { id: "apptweak", label: "Sources" },
        model.value?.igdb.length ? { id: "igdb", label: "IGDB" } : null,
      ].filter(isNotNullish);
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    const gameActionFunction = async (
      taskName: string,
      action: () => void,
      withConfirm?: boolean,
      responseHandler?: (response: any) => void
    ) => {
      if (withConfirm && !confirm("Are you sure?")) return;

      try {
        isActionLoading.value = true;
        const response = await action();
        if (responseHandler) {
          responseHandler(response);
        } else {
          toast.show({
            variant: "success",
            title: "Games",
            body: taskName + " - Success",
          });
        }
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Games",
          body: taskName + " - Failure",
        });
      } finally {
        isActionLoading.value = false;
      }
    };

    const onApptweakSync = async () => {
      gameActionFunction(
        "Sync AppTweak",
        () => syncGameWithAppTweak(id.value),
        true,
        (response) => {
          Object.keys(response).forEach((platform) => {
            const syncedMessage = `Synced - ${
              response[platform].synced
                ? "OK"
                : "Error (" + response[platform].syncedError + ")"
            }`;

            toast.show({
              variant:
                response[platform].synced && response[platform].saved
                  ? "success"
                  : "danger",
              title: platform,
              body:
                syncedMessage +
                (response[platform].synced
                  ? `, Saved - ${
                      response[platform].saved
                        ? "OK"
                        : "Error (" + response[platform].savedError + ")"
                    }`
                  : ""),
            });
          });
        }
      );
    };

    const onAppMagicSync = async () => {
      gameActionFunction(
        "Sync AppMagic",
        () => syncGameWithAppMagic(id.value),
        true,
        (response) => {
          Object.keys(response).forEach((platform) => {
            const syncedMessage = `Synced - ${
              response[platform].synced
                ? "OK"
                : "Error (" + response[platform].syncedError + ")"
            }`;

            toast.show({
              variant:
                response[platform].synced && response[platform].saved
                  ? "success"
                  : "danger",
              title: platform,
              body:
                syncedMessage +
                (response[platform].synced
                  ? `, Saved - ${
                      response[platform].saved
                        ? "OK"
                        : "Error (" + response[platform].savedError + ")"
                    }`
                  : ""),
            });
          });
        }
      );
    };

    const onActivatePlatform = async (platform: "ios" | "android") =>
      gameActionFunction("Activation", async () => {
        await activatePlatform(Number(id.value), platform);

        fetchModel();
      });

    const onDeletePlatform = async (platform: "ios" | "android") =>
      gameActionFunction(
        "Delete Platform",
        async () => {
          await deletePlatform(Number(id.value), platform);
          if (platform === "ios") {
            iosEnabled.value = false;
          } else {
            androidEnabled.value = false;
          }
        },
        true
      );

    const onShowPlatform = async (platform: "ios" | "android") =>
      gameActionFunction("Show Platform", async () => {
        await showPlatform(Number(id.value), platform);
        if (platform === "ios") {
          iosVisible.value = true;
        } else {
          androidVisible.value = true;
        }
      });

    const onHidePlatform = async (platform: "ios" | "android") =>
      gameActionFunction(
        "Hide Platform",
        async () => {
          isActionLoading.value = true;
          await hidePlatform(Number(id.value), platform);
          if (platform === "ios") {
            iosVisible.value = false;
          } else {
            androidVisible.value = false;
          }
        },
        true
      );

    const onDeleteStore = (platform: "ios" | "android", store: string) => {
      if (platform === "ios") {
        iosAltStoresDeleted.value = [...iosAltStoresDeleted.value, store];
      } else {
        androidAltStoresDeleted.value = [
          ...androidAltStoresDeleted.value,
          store,
        ];
      }
    };

    let websiteOrigin: string =
      process.env.VUE_APP_WEBSITE_URL || window.location.origin;
    if (websiteOrigin.substr(-1) === "/") {
      websiteOrigin = websiteOrigin.substr(0, websiteOrigin.length - 1);
    }

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getGamesListUrl(),
      tabList,
      selectedTabId,

      tagOptions,
      categoryOptions,
      developerOptions,

      videoStreams,
      webFaq,
      webTextBlocks,

      onApptweakSync,
      onAppMagicSync,

      androidVisible,
      androidEnabled,
      iosVisible,
      iosEnabled,

      onActivatePlatform,
      onDeletePlatform,
      onShowPlatform,
      onHidePlatform,
      onDeleteStore,

      androidAltStoresDeleted,
      iosAltStoresDeleted,
      websiteOrigin,
    };
  },
  computed: {
    console: () => console,
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46dea23e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rows" }
const _hoisted_2 = { class: "row-cols-3" }
const _hoisted_3 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangeFilter = _resolveComponent("DateRangeFilter")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_UsersStatsWidget = _resolveComponent("UsersStatsWidget")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Users Stats" }, {
    default: _withCtx(() => [
      _createVNode(_component_DateRangeFilter, {
        label: "Registered:",
        disabled: _ctx.isLoading,
        onSubmit: _ctx.onDateChange
      }, null, 8, ["disabled", "onSubmit"]),
      _createVNode(_component_FieldValue, {
        label: "Registered Users",
        value: _ctx.isLoading ? '...' : String(_ctx.statsData?.count || 0)
      }, null, 8, ["value"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UsersStatsWidget, {
          title: "Retention",
          loading: _ctx.isLoading,
          value: [
          {
            label: 'D1',
            value: _ctx.numWithPercent(_ctx.statsData?.retention.d1),
          },
          {
            label: 'D7',
            value: _ctx.numWithPercent(_ctx.statsData?.retention.d7),
          },
          {
            label: 'D30',

            value: _ctx.numWithPercent(_ctx.statsData?.retention.d30),
          },
          {
            label: 'M1',
            value: _ctx.numWithPercent(_ctx.statsData?.retention.m1),
          },
        ]
        }, null, 8, ["loading", "value"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UsersStatsWidget, {
            title: "Launched 1+ games",
            loading: _ctx.isLoading,
            value: _ctx.getBlockParams(_ctx.statsData?.launches.min1)
          }, null, 8, ["loading", "value"]),
          _createVNode(_component_UsersStatsWidget, {
            title: "Launched 3+ games",
            loading: _ctx.isLoading,
            value: _ctx.getBlockParams(_ctx.statsData?.launches.min3)
          }, null, 8, ["loading", "value"]),
          _createVNode(_component_UsersStatsWidget, {
            title: "Launched 5+ games",
            loading: _ctx.isLoading,
            value: _ctx.getBlockParams(_ctx.statsData?.launches.min5)
          }, null, 8, ["loading", "value"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UsersStatsWidget, {
            title: "Downloaded 1+ games",
            loading: _ctx.isLoading,
            value: _ctx.getBlockParams(_ctx.statsData?.downloads.min1)
          }, null, 8, ["loading", "value"]),
          _createVNode(_component_UsersStatsWidget, {
            title: "Downloaded 3+ games",
            loading: _ctx.isLoading,
            value: _ctx.getBlockParams(_ctx.statsData?.downloads.min3)
          }, null, 8, ["loading", "value"]),
          _createVNode(_component_UsersStatsWidget, {
            title: "Downloaded 5+ games",
            loading: _ctx.isLoading,
            value: _ctx.getBlockParams(_ctx.statsData?.downloads.min5)
          }, null, 8, ["loading", "value"])
        ])
      ])
    ]),
    _: 1
  }))
}
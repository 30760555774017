
import { defineComponent, onMounted } from "vue";

import { ColumnDefinition, DataTable, useDataTable } from "@tager/admin-ui";

import { getUserActivity } from "@/modules/users/services";
import { getUsersUpdateUrl, getGamesUpdateUrl } from "@/utils/paths";
import { UserActivityInterface } from "@/modules/users/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserActivityInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "40px",
  },
  {
    id: 2,
    name: "Date",
    field: "date",
    type: "date",
    width: "100px",
  },
  {
    id: 3,
    name: "Type",
    field: "type",
    width: "100px",
  },
  {
    id: 4,
    name: "",
    field: "payload",
  },
];

export default defineComponent({
  name: "UsersViewActivity",
  components: {
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserActivityInterface>({
      fetchEntityList: () => getUserActivity(props.id),
      initialValue: [],
      resourceName: "Activity List",
    });

    onMounted(() => {
      fetchData();
    });

    return {
      getUsersUpdateUrl,
      getGamesUpdateUrl,
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});

import { Nullable } from "@tager/admin-services";
import { BaseOptionType, OptionType } from "@tager/admin-ui";
import { FieldConfigUnion } from "@tager/admin-dynamic-field/dist/typings/model";
import { RepeaterField } from "@tager/admin-dynamic-field";

import { CampaignFullInterface } from "../typings";
import { CampaignPayload } from "../services";

export type FormValues = {
  name: string;
  active: boolean;
  downloadUrl: Nullable<string>;
  developer: Nullable<OptionType<number>>;
};

export function getFormValues(
  model: Nullable<CampaignFullInterface>
): FormValues {
  if (!model) {
    return {
      name: "",
      active: false,
      downloadUrl: null,
      developer: null,
    };
  }

  return {
    name: model.name || "",
    active: model.active,
    downloadUrl: model.downloadUrl,
    developer: model.developer
      ? {
          value: model.developer.id,
          label: model.developer.name,
        }
      : null,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues,
  games: RepeaterField,
  gamelists: RepeaterField,
  reviews: RepeaterField
): CampaignPayload {
  return {
    name: values.name,
    active: values.active,
    downloadUrl: values.downloadUrl,
    developer: values.developer?.value || null,
    games: games.value.map((item) => {
      return +((item.value[0].value as BaseOptionType)?.value ?? 0);
    }),
    gamelists: gamelists.value.map((item) => {
      return +((item.value[0].value as BaseOptionType)?.value ?? 0);
    }),
    reviews: reviews.value.map((item) => {
      return +((item.value[0].value as BaseOptionType)?.value ?? 0);
    }),
  };
}

export const GAMES_FIELD_REPEATER_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Featured Games",
  meta: {
    view: "TABLE",
    noToggle: true,
    isEmptyHidden: true,
  },
  fields: [
    {
      name: "id",
      type: "AJAX_SELECT",
      label: "Game",
      meta: {
        requestUrl: "/admin/games/ajax-search",
      },
    },
  ],
};

export const GAMELISTS_FIELD_REPEATER_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Featured Gamelists",
  meta: {
    view: "TABLE",
    noToggle: true,
    isEmptyHidden: true,
  },
  fields: [
    {
      name: "id",
      type: "AJAX_SELECT",
      label: "Gamelist",
      meta: {
        requestUrl: "/admin/playlists/ajax-search",
      },
    },
  ],
};

export const REVIEWS_FIELD_REPEATER_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Featured Reviews",
  meta: {
    view: "TABLE",
    noToggle: true,
    isEmptyHidden: true,
  },
  fields: [
    {
      name: "id",
      type: "AJAX_SELECT",
      label: "Review",
      meta: {
        requestUrl: "/admin/reviews/ajax-search",
      },
    },
  ],
};

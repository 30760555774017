import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { RouteLocationNormalizedLoaded } from "vue-router";

import {
  FilterTagType,
  getFilterParamAsString,
  getFilterParams,
  OptionType,
} from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import { Language, LANGUAGE_OPTIONS } from "@/constants/languages";

interface Params {
  route: RouteLocationNormalizedLoaded;
}

interface State {
  categoryFilter: Ref<OptionType<string | null>>;
  webAvailableFilter: Ref<OptionType<string | null>>;
  languageFilter: Ref<OptionType<Language | null>>;
  userFilter: Ref<string>;
  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;
  tagRemovalHandler(event: FilterTagType): void;
}

enum FilterTypes {
  Category = "category",
  WebAvailable = "web-available",
  User = "user",
  Language = "language",
}

export const categoryOptions: Array<OptionType<string | null>> = [
  { label: "All", value: null },
  { label: "Featured", value: "featured" },
  { label: "Weekly", value: "weekly" },
];

export const webAvailableOptions: Array<OptionType<string | null>> = [
  { label: "All", value: null },
  { label: "Visible", value: "visible" },
  { label: "Hidden", value: "hidden" },
];

export function useAdvancedSearch({ route }: Params): State {
  const initialCategoryFilter = computed<OptionType<string | null>>(
    () =>
      categoryOptions.find(
        (item) =>
          item.value ===
          getFilterParamAsString(route.query, FilterTypes.Category)
      ) ?? categoryOptions[0]
  );
  const categoryFilter = ref<OptionType<string | null>>(
    initialCategoryFilter.value
  );
  watch(initialCategoryFilter, () => {
    categoryFilter.value = initialCategoryFilter.value;
  });

  const initialLanguageFilter = computed<OptionType<Language | null>>(
    () =>
      LANGUAGE_OPTIONS.find(
        (item) =>
          item.value ===
          getFilterParamAsString(route.query, FilterTypes.Language)
      ) ?? LANGUAGE_OPTIONS[0]
  );
  const languageFilter = ref<OptionType<Language | null>>(
    initialLanguageFilter.value
  );
  watch(initialLanguageFilter, () => {
    languageFilter.value = initialLanguageFilter.value;
  });

  const initialWebAvailableFilter = computed<OptionType<string | null>>(
    () =>
      webAvailableOptions.find(
        (item) =>
          item.value ===
          getFilterParamAsString(route.query, FilterTypes.WebAvailable)
      ) ?? webAvailableOptions[0]
  );
  const webAvailableFilter = ref<OptionType<string | null>>(
    initialWebAvailableFilter.value
  );
  watch(initialWebAvailableFilter, () => {
    webAvailableFilter.value = initialWebAvailableFilter.value;
  });

  const initialUserFilter = computed<string>(() => {
    return getFilterParamAsString(route.query, FilterTypes.User) ?? "";
  });
  const userFilter = ref<string>(initialUserFilter.value);
  watch(initialUserFilter, () => {
    userFilter.value = initialUserFilter.value;
  });

  /** Params **/

  const filterParams = computed(() => {
    return getFilterParams({
      [FilterTypes.Category]:
        (categoryFilter.value || categoryOptions[0]).value || "",
      [FilterTypes.Language]:
        (languageFilter.value || LANGUAGE_OPTIONS[0]).value || "",
      [FilterTypes.WebAvailable]:
        (webAvailableFilter.value || webAvailableOptions[0]).value || "",
      [FilterTypes.User]: userFilter.value || "",
    });
  });

  const tagRemovalHandler = (event: FilterTagType): void => {
    if (event.name === FilterTypes.Category) {
      categoryFilter.value = categoryOptions[0];
    } else if (event.name === FilterTypes.WebAvailable) {
      webAvailableFilter.value = webAvailableOptions[0];
    } else if (event.name === FilterTypes.User) {
      userFilter.value = "";
    } else if (event.name === FilterTypes.Language) {
      languageFilter.value = LANGUAGE_OPTIONS[0];
    }
  };

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [
      categoryFilter.value && categoryFilter.value.value
        ? {
            value: categoryFilter.value.value || "",
            label: categoryFilter.value.label || "",
            name: FilterTypes.Category,
            title: "Category",
          }
        : null,
      languageFilter.value && languageFilter.value.value
        ? {
            value: languageFilter.value.value || "",
            label: languageFilter.value.label || "",
            name: FilterTypes.Language,
            title: "Language",
          }
        : null,
      webAvailableFilter.value && webAvailableFilter.value.value
        ? {
            value: webAvailableFilter.value.value || "",
            label: webAvailableFilter.value.label || "",
            name: FilterTypes.WebAvailable,
            title: "Web Available",
          }
        : null,
      userFilter.value
        ? {
            value: userFilter.value,
            label: userFilter.value,
            name: FilterTypes.User,
            title: "User ID",
          }
        : null,
    ].filter(isNotNullish)
  );

  return {
    userFilter,
    languageFilter,
    categoryFilter,
    webAvailableFilter,
    filterParams,
    tags,
    tagRemovalHandler,
  };
}


import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  ColumnDefinition,
  DataTable,
  useDataTable,
} from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { getUserDevices, sendTestPush } from "@/modules/users/services";
import { UserDeviceInterface } from "@/modules/users/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserDeviceInterface>> = [
  {
    id: 0,
    name: "Active",
    field: "active",
    type: "boolean",
    width: "30px",
    view: "TICK",
  },
  {
    id: 7,
    name: "Last Active",
    field: "activeAt",
    type: "datetime",
    width: "168px",
  },
  {
    id: 3,
    name: "Device ID",
    field: "deviceId",
    style: {
      fontSize: "0.8rem",
    },
  },
  {
    id: 1,
    name: "Device",
    field: "device",
    style: {
      fontSize: "0.8rem",
    },
  },
  {
    id: 3,
    name: "Version",
    field: "version",
    width: "80px",
    headStyle: {
      textAlign: "center",
    },
    style: {
      textAlign: "center",
    },
  },
  {
    id: 3,
    name: "Token",
    field: "token",
    width: "80px",
    type: "boolean",
    view: "TICK",
    headStyle: {
      textAlign: "center",
    },
    style: {
      textAlign: "center",
    },
  },
  {
    id: 3,
    name: "OneSignal ID",
    field: "oneSignalId",
    type: "link",
    options: {
      shouldOpenNewTab: true,
      disableCopyButton: true,
    },
    format: ({ row, column }) =>
      row.oneSignalId
        ? {
            url: row.oneSignalUrl || "",
            text: row.oneSignalId,
          }
        : null,
    style: {
      fontSize: "0.8rem",
    },
    width: "300px",
  },
  {
    id: 5,
    name: "Languages",
    field: "languages",
    type: "key-value",
    options: {
      view: "row",
    },
    format: ({ row }) => {
      return [
        {
          key: "System",
          value: row.systemLanguage,
        },
        {
          key: "App",
          value: row.appLanguage,
        },
        {
          key: "Country",
          value: row.country,
        },
        {
          key: "Store",
          value: row.storeRegion,
        },
      ];
    },
  },
  {
    id: 6,
    name: "Timezone",
    field: "timezone",
    width: "100px",
  },
  {
    id: 9,
    name: "Launchers",
    field: "launchersInstalled",
    type: "list",
    width: "100px",
  },
  {
    id: 10,
    name: "AppsFlyer",
    field: "appsFlyerData",
    type: "json",
    width: "300px",
  },
];

export default defineComponent({
  name: "UsersViewDevicesList",
  components: {
    BaseButton,
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserDeviceInterface>({
      fetchEntityList: () => getUserDevices(props.id),
      initialValue: [],
      resourceName: "Devices List",
    });

    onMounted(() => {
      fetchData();
    });

    const onSendPushClick = async () => {
      try {
        await sendTestPush(props.id);

        toast.show({
          variant: "success",
          title: "Push",
          body: "Test push successfully sent",
        });
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Push",
          body: "Test push error",
        });
      }
    };

    return {
      onSendPushClick,
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});

import {
  FieldConfigUnion,
  RepeaterField,
  TextOutgoingValue,
} from "@tager/admin-dynamic-field";
import { OptionType } from "@tager/admin-ui";

import { PollFullInterface, PollsUpdatePayload } from "../typings";

export type FormValues = {
  name: string;
  active: boolean;
};

export function getPollFormValues(model: PollFullInterface): FormValues {
  return {
    name: model.name,
    active: model.active,
  };
}

export function convertPageFormValuesToUpdatePayload(
  values: FormValues,
  questions: RepeaterField,
  ids: Record<string, number>
): PollsUpdatePayload {
  const newIds = questions.value.map((item) => item.id);

  return {
    name: values.name,
    active: values.active,
    questions: questions.value.map((item, ind) => {
      return {
        id: ids[newIds[ind]] ?? null,
        required: Boolean(item.value[0].value),
        question: String(item.value[1].value),
        questionEs: String(item.value[2].value),
        questionFr: String(item.value[3].value),
        questionDe: String(item.value[4].value),
        questionIt: String(item.value[5].value),
        questionPt: String(item.value[6].value),
        questionPtBr: String(item.value[7].value),
        icon: String(item.value[8].value),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: String(item.value[9].value?.value),
        options: (item.value[10].value as TextOutgoingValue)
          ? (item.value[10].value as TextOutgoingValue).split("\n")
          : [],
        optionsEs: (item.value[11].value as TextOutgoingValue)
          ? (item.value[11].value as TextOutgoingValue).split("\n")
          : [],
        optionsFr: (item.value[12].value as TextOutgoingValue)
          ? (item.value[12].value as TextOutgoingValue).split("\n")
          : [],
        optionsDe: (item.value[13].value as TextOutgoingValue)
          ? (item.value[13].value as TextOutgoingValue).split("\n")
          : [],
        optionsIt: (item.value[14].value as TextOutgoingValue)
          ? (item.value[14].value as TextOutgoingValue).split("\n")
          : [],
        optionsPt: (item.value[15].value as TextOutgoingValue)
          ? (item.value[15].value as TextOutgoingValue).split("\n")
          : [],
        optionsPtBr: (item.value[16].value as TextOutgoingValue)
          ? (item.value[16].value as TextOutgoingValue).split("\n")
          : [],
        description: String(item.value[17].value) || null,
        descriptionEs: String(item.value[18].value) || null,
        descriptionFr: String(item.value[19].value) || null,
        descriptionDe: String(item.value[20].value) || null,
        descriptionIt: String(item.value[21].value) || null,
        descriptionPt: String(item.value[22].value) || null,
        descriptionPtBr: String(item.value[23].value) || null,
        placeholder: String(item.value[24].value) || null,
        placeholderEs: String(item.value[25].value) || null,
        placeholderFr: String(item.value[26].value) || null,
        placeholderDe: String(item.value[27].value) || null,
        placeholderIt: String(item.value[28].value) || null,
        placeholderPt: String(item.value[29].value) || null,
        placeholderPtBr: String(item.value[30].value) || null,
        dependsOnQuestionNumber: Number(item.value[31].value) || null,
        dependsOnQuestionAnswer: String(item.value[32].value) || null,
        combineWithPrevious: Boolean(item.value[33].value),
      };
    }),
  };
}

export const questionTypes: Array<OptionType> = [
  { label: "Options", value: "OPTIONS" },
  { label: "Rate", value: "RATE" },
  { label: "Text", value: "TEXT" },
];

export const questionsFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  meta: {
    view: "BLOCK",
    noToggle: true,
    hideCount: true,
    titleFormatter: (values) => {
      return String(values[1].value) || "Question";
    },
  },
  fields: [
    {
      name: "required",
      type: "TRUE_FALSE",
      label: "Required",
      meta: {
        defaultValue: true,
      },
    },
    { name: "question", type: "STRING", label: "Question" },
    { name: "questionEs", type: "STRING", label: "Question (ES)" },
    { name: "questionFr", type: "STRING", label: "Question (FR)" },
    { name: "questionDe", type: "STRING", label: "Question (DE)" },
    { name: "questionIt", type: "STRING", label: "Question (IT)" },
    { name: "questionPt", type: "STRING", label: "Question (PT)" },
    { name: "questionPtBr", type: "STRING", label: "Question (PT-BR)" },
    {
      name: "icon",
      type: "STRING",
      label: "Icon",
    },
    {
      name: "type",
      type: "SELECT",
      label: "Type",
      meta: {
        options: questionTypes,
      },
    },
    {
      name: "options",
      type: "TEXT",
      label: "Options",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "OPTIONS";
      },
    },

    {
      name: "optionsEs",
      type: "TEXT",
      label: "Options (ES)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "OPTIONS";
      },
    },

    {
      name: "optionsFr",
      type: "TEXT",
      label: "Options (FR)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "OPTIONS";
      },
    },

    {
      name: "optionsDe",
      type: "TEXT",
      label: "Options (DE)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "OPTIONS";
      },
    },

    {
      name: "optionsIt",
      type: "TEXT",
      label: "Options (IT)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "OPTIONS";
      },
    },

    {
      name: "optionsPt",
      type: "TEXT",
      label: "Options (PT)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "OPTIONS";
      },
    },

    {
      name: "optionsPtBr",
      type: "TEXT",
      label: "Options (PT-BR)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "OPTIONS";
      },
    },
    { name: "description", type: "TEXT", label: "Description" },
    { name: "descriptionEs", type: "TEXT", label: "Description (ES)" },
    { name: "descriptionFr", type: "TEXT", label: "Description (FR)" },
    { name: "descriptionDe", type: "TEXT", label: "Description (DE)" },
    { name: "descriptionIt", type: "TEXT", label: "Description (IT)" },
    { name: "descriptionPt", type: "TEXT", label: "Description (PT)" },
    { name: "descriptionPtBr", type: "TEXT", label: "Description (PT-BR)" },
    {
      name: "placeholder",
      type: "STRING",
      label: "Placeholder",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "TEXT";
      },
    },

    {
      name: "placeholderEs",
      type: "STRING",
      label: "Placeholder (ES)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "TEXT";
      },
    },

    {
      name: "placeholderFr",
      type: "STRING",
      label: "Placeholder (FR)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "TEXT";
      },
    },

    {
      name: "placeholderDe",
      type: "STRING",
      label: "Placeholder (DE)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "TEXT";
      },
    },

    {
      name: "placeholderIt",
      type: "STRING",
      label: "Placeholder (IT)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "TEXT";
      },
    },

    {
      name: "placeholderPt",
      type: "STRING",
      label: "Placeholder (PT)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "TEXT";
      },
    },

    {
      name: "placeholderPtBr",
      type: "STRING",
      label: "Placeholder (PT-BR)",
      checkVisible: (values): boolean => {
        return (values.type as OptionType)?.value === "TEXT";
      },
    },

    {
      name: "dependsOnQuestionNumber",
      type: "NUMBER",
      meta: {},
      label: "Depend on Question Number",
    },
    {
      name: "dependsOnQuestionAnswer",
      type: "STRING",
      label: "Depend on Question Answer",
      checkVisible: (values): boolean => {
        return String(values.dependsOnQuestionNumber).trim().length > 0;
      },
    },
    {
      name: "combineWithPrevious",
      type: "TRUE_FALSE",
      label: "Combine with previous",
      meta: {
        defaultValue: false,
      },
    },
  ],
};

import { Nullable, request, ResponseBody } from "@tager/admin-services";

import { RecipientType } from "@/modules/users/UsersSendPush/UsersSendPush.helpers";

import {
  UserActivityCalendarInterface,
  UserActivityInterface,
  UserDeviceInterface,
  UserFullInterface,
  UserGameActionInterface,
  UserGameInterface,
  UserInterface,
  UserLauncherHistoryInterface,
  UserLauncherInterface,
  UserPushNotificationDetailedInterface,
  UserPushNotificationInterface,
  UserViewPushNotificationInterface,
} from "./typings";

export interface CountData {
  count: number;
}

export function getUsersCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/users/count" });
}

export function getUserPushNotifications(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<UserPushNotificationInterface>>> {
  return request.get({ path: "/admin/users/push-notifications", params });
}

export function getUserPushNotificationDetailed(
  id: string | number
): Promise<ResponseBody<UserPushNotificationDetailedInterface>> {
  return request.get({ path: `/admin/users/push-notifications/${id}` });
}

export function getUsers(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: Nullable<string>;
}): Promise<ResponseBody<Array<UserInterface>>> {
  return request.get({ path: "/admin/users", params });
}

export function getUser(
  id: string | number
): Promise<ResponseBody<UserFullInterface>> {
  return request.get({ path: `/admin/users/${id}` });
}

export function getUserGamesQueue(
  id: number,
  platform: string
): Promise<ResponseBody<Array<UserGameInterface>>> {
  return request.get({
    path: `/admin/users/${id}/games-queue`,
    params: { platform },
  });
}

export function getUserActivity(
  id: number
): Promise<ResponseBody<Array<UserActivityInterface>>> {
  return request.get({
    path: `/admin/users/${id}/activity`,
  });
}

export function getUserGameActions(
  id: number
): Promise<ResponseBody<Array<UserGameActionInterface>>> {
  return request.get({
    path: `/admin/users/${id}/game-actions`,
    params: {
      pageSize: 100000,
      pageNumber: 1,
    },
  });
}

export function getUserDevices(
  id: number
): Promise<ResponseBody<Array<UserDeviceInterface>>> {
  return request.get({
    path: `/admin/users/${id}/devices`,
  });
}

export function getUserProfilePushNotifications(
  id: number
): Promise<ResponseBody<Array<UserViewPushNotificationInterface>>> {
  return request.get({
    path: `/admin/users/${id}/push-notifications`,
  });
}

export function getUserLauncher(
  id: number
): Promise<ResponseBody<Array<UserLauncherInterface>>> {
  return request.get({
    path: `/admin/users/${id}/launcher`,
    params: {
      pageSize: 100000,
      pageNumber: 1,
    },
  });
}

export function getUserLauncherHistory(
  id: number
): Promise<ResponseBody<Array<UserLauncherHistoryInterface>>> {
  return request.get({
    path: `/admin/users/${id}/launcher-history`,
    params: {
      pageSize: 100000,
      pageNumber: 1,
    },
  });
}

export function getUserActivityCalendar(
  id: number
): Promise<ResponseBody<Array<UserActivityCalendarInterface>>> {
  return request.get({
    path: `/admin/users/${id}/activity-calendar`,
  });
}

export type UserUpdatePayload = {
  priority: number;
  emailAuthCode: Nullable<string>;
  emailAuthDisableSend: boolean;
  categories: number[];
  tags: number[];
  featureFlags: string[];
  campaign: number | null;
};

export function updateUser(
  id: number | string,
  payload: UserUpdatePayload
): Promise<ResponseBody<UserFullInterface>> {
  return request.put({
    path: `/admin/users/${id}`,
    body: payload,
  });
}

export type FeatureFlag = {
  id: string;
  label: string;
};

export function getUserFeatureFlags(): Promise<
  ResponseBody<Array<FeatureFlag>>
> {
  return request.get({
    path: `/admin/users/feature-flags`,
  });
}

export type SendUserPushRequest = {
  type: RecipientType;
  user: number | null;
  preReleases: number[];

  titleEn: string;
  titleFr: string;
  titleIt: string;
  titleEs: string;
  titleDe: string;
  titlePt: string;
  titlePtBr: string;

  textEn: string;
  textDe: string;
  textFr: string;
  textIt: string;
  textEs: string;
  textPt: string;
  textPtBr: string;

  icon: string | null;
  amplitudeEventJSON: string;
  actionJSON: string;
};

export async function sendUserPushRequest(body: SendUserPushRequest) {
  return request.post({
    path: `/admin/users/push-notifications`,
    body,
  });
}

export async function sendTestPush(id: number) {
  return request.post({
    path: `/admin/users/${id}/test-push`,
  });
}

type UserStatsResponseRetention = {
  d1: number;
  d7: number;
  d30: number;
  m1: number;
};

export type UserStatsResponseBlock = {
  "30days": number;
  allTime: number;
  retention: UserStatsResponseRetention;
};

export type UserStatsResponse = {
  count: number;
  retention: UserStatsResponseRetention;
  launches: {
    min1: UserStatsResponseBlock;
    min3: UserStatsResponseBlock;
    min5: UserStatsResponseBlock;
  };
  downloads: {
    min1: UserStatsResponseBlock;
    min3: UserStatsResponseBlock;
    min5: UserStatsResponseBlock;
  };
};

export async function getUserStats(
  dateFrom: string,
  dateTo: string
): Promise<ResponseBody<UserStatsResponse>> {
  return request.get({
    path: `/admin/users/stats`,
    params: {
      dateFrom,
      dateTo,
    },
  });
}

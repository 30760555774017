
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  AdvancedSearch,
  CountButton,
  BaseButton,
  DataTable,
  FilterTagType,
  FormFieldCheckbox,
  FormFieldSelect,
  getFilterParamAsString,
  getFilterParams,
  OptionType,
  useDataTable,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { isNotNullish } from "@tager/admin-services";

import { getPreReleases } from "@/modules/games/services";
import { GamePreReleaseInterface } from "@/modules/games/typings";

import { COLUMN_DEFS, SORT_OPTIONS } from "./GamesPreReleasesListView.helpers";

export default defineComponent({
  name: "GamesListView",
  components: {
    CountButton,
    AdvancedSearch,
    FormFieldSelect,
    Page,
    DataTable,
    BaseButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      fetchEntityList: fetchEntityList,
      isLoading: isGamesLoading,
      rowData: gamesData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<GamePreReleaseInterface>({
      fetchEntityList: (params) =>
        getPreReleases({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Pre-Releases List",
      defaultSort: SORT_OPTIONS[0].value,
    });

    onMounted(() => {
      fetchEntityList();
    });

    const platformFilterOptions: Array<OptionType> = [
      { label: "All", value: "" },
      { label: "Android", value: "android" },
      { label: "iOS", value: "ios" },
    ];

    const initialPlatformFilter = computed<string>(() => {
      return getFilterParamAsString(route.query, "platform") || "";
    });

    const getPlatformFilterValue = () =>
      platformFilterOptions.find(
        (item) => item.value === initialPlatformFilter.value
      ) || platformFilterOptions[0];

    watch(initialPlatformFilter, () => {
      platformFilter.value = getPlatformFilterValue();
    });

    const platformFilter = ref<OptionType | null>(getPlatformFilterValue());

    const statusFilterOptions: Array<OptionType> = [
      { label: "All", value: "" },
      { label: "Hidden", value: "hidden" },
      { label: "Visible", value: "visible" },
    ];

    const initialStatusFilter = computed<string>(() => {
      return getFilterParamAsString(route.query, "status") || "";
    });
    const getStatusFilterValue = () =>
      statusFilterOptions.find(
        (item) => item.value === initialStatusFilter.value
      ) || statusFilterOptions[0];

    watch(initialStatusFilter, () => {
      statusFilter.value = getStatusFilterValue();
    });
    const statusFilter = ref<OptionType | null>(getStatusFilterValue());

    const filterParams = computed(() => {
      return getFilterParams({
        platform: platformFilter.value ? platformFilter.value.value : "",
        status: statusFilter.value ? statusFilter.value.value : "",
      });
    });

    watch(filterParams, () => {
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber", "sort"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchEntityList();
      }
    });

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "platform") {
        platformFilter.value =
          platformFilterOptions.find((item) => item.value === event.value) ||
          platformFilterOptions[0];
      }

      if (event.name === "status") {
        statusFilter.value =
          statusFilterOptions.find((item) => item.value === event.value) ||
          statusFilterOptions[0];
      }
    }

    const tags = computed<Array<FilterTagType>>(() =>
      [
        statusFilter.value && statusFilter.value.value
          ? {
              value: String(statusFilter.value.value),
              label: statusFilter.value.label,
              name: "status",
              title: "Status",
            }
          : null,
        platformFilter.value && platformFilter.value.value
          ? {
              value: String(platformFilter.value.value),
              label: platformFilter.value.label,
              name: "platform",
              title: "Platform",
            }
          : null,
      ].filter(isNotNullish)
    );

    const isDataLoading = computed<boolean>(() => isGamesLoading.value);

    return {
      columnDefs: COLUMN_DEFS,
      sortOptions: SORT_OPTIONS,
      sortValue: sort,
      rowData: gamesData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      tags,
      handleTagRemove,
      statusFilter,
      statusFilterOptions,
      platformFilter,
      platformFilterOptions,
    };
  },
});


import { computed, defineComponent, onMounted } from "vue";

import { DataTable, useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getSuggestionsList } from "@/modules/suggestions/services";
import { SuggestionInterface } from "@/modules/suggestions/typings";

import { COLUMN_DEFS } from "./SuggestionsListView.helpers";

export default defineComponent({
  name: "SuggestionsListView",
  components: {
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchEntityList,
      isLoading: isItemsLoading,
      rowData: gamesData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<SuggestionInterface>({
      fetchEntityList: (params) =>
        getSuggestionsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Suggestions List",
    });
    onMounted(() => {
      fetchEntityList();
    });

    const isDataLoading = computed<boolean>(() => isItemsLoading.value);

    return {
      columnDefs: COLUMN_DEFS,
      rowData: gamesData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});

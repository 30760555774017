
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGrid, NavigationGridItem } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { getLinks } from "@/constants/links";
import { getGamesCount } from "@/modules/games/services";
import { getCategoriesCount } from "@/modules/categories/services";
import { getTagsCount } from "@/modules/tags/services";
import { getUsersCount } from "@/modules/users/services";
import { getReviewsCount } from "@/modules/reviews/services";
import { getFollowingsCount } from "@/modules/followings/services";
import { getSuggestionsCount } from "@/modules/suggestions/services";
import { getSubscriptionsCount } from "@/modules/subscriptions";
import { getPollsCount } from "@/modules/polls";
import { getCallToActionsCount } from "@/modules/ctas/services";
import { getPlaylistsCount } from "@/modules/playlists";
import { getDevelopersCount } from "@/modules/developers";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const links = computed(() => getLinks());

    const [
      fetchDevelopersCount,
      { data: developersCountData, status: developersCountDataStatus },
    ] = useResource({
      fetchResource: () => getDevelopersCount(),
      resourceName: "Developers Count",
      initialValue: null,
    });

    const [
      fetchGamesCount,
      { data: gamesCountData, status: gamesCountDataStatus },
    ] = useResource({
      fetchResource: () => getGamesCount(),
      resourceName: "Games Count",
      initialValue: null,
    });

    const [
      fetchUsersCount,
      { data: usersCountData, status: usersCountDataStatus },
    ] = useResource({
      fetchResource: () => getUsersCount(),
      resourceName: "Users Count",
      initialValue: null,
    });

    const [
      fetchCategoriesCount,
      { data: categoriesCountData, status: categoriesCountDataStatus },
    ] = useResource({
      fetchResource: () => getCategoriesCount(),
      resourceName: "Categories Count",
      initialValue: null,
    });

    const [
      fetchTagsCount,
      { data: tagsCountData, status: tagsCountDataStatus },
    ] = useResource({
      fetchResource: () => getTagsCount(),
      resourceName: "Tags Count",
      initialValue: null,
    });

    const [
      fetchReviewsCount,
      { data: reviewsCountData, status: reviewsCountDataStatus },
    ] = useResource({
      fetchResource: () => getReviewsCount(),
      resourceName: "Reviews Count",
      initialValue: null,
    });

    const [
      fetchFollowingsCount,
      { data: folloginwsCountData, status: folloginwsCountDataStatus },
    ] = useResource({
      fetchResource: () => getFollowingsCount(),
      resourceName: "Followings Count",
      initialValue: null,
    });

    const [
      fetchSuggestionsCount,
      { data: suggestionsCountData, status: suggestionsCountDataStatus },
    ] = useResource({
      fetchResource: () => getSuggestionsCount(),
      resourceName: "Suggestions Count",
      initialValue: null,
    });

    const [
      fetchSubscriptionsCount,
      { data: subscriptionsCountData, status: subscriptionsCountDataStatus },
    ] = useResource({
      fetchResource: () => getSubscriptionsCount(),
      resourceName: "Subscriptions Count",
      initialValue: null,
    });

    const [
      fetchPollsCount,
      { data: pollsCountData, status: pollsCountDataStatus },
    ] = useResource({
      fetchResource: () => getPollsCount(),
      resourceName: "Polls Count",
      initialValue: null,
    });

    const [
      fetchCTAsCount,
      { data: CTAsCountData, status: CTAsCountDataStatus },
    ] = useResource({
      fetchResource: () => getCallToActionsCount(),
      resourceName: "CTAs Count",
      initialValue: null,
    });

    const [
      fetchPlaylistsCount,
      { data: playlistCountData, status: playlistCountDataStatus },
    ] = useResource({
      fetchResource: () => getPlaylistsCount(),
      resourceName: "Playlists Count",
      initialValue: null,
    });

    onMounted(() => {
      fetchSuggestionsCount();
      fetchGamesCount();
      fetchUsersCount();
      fetchCategoriesCount();
      fetchTagsCount();
      fetchReviewsCount();
      fetchFollowingsCount();
      fetchSubscriptionsCount();
      fetchPollsCount();
      fetchCTAsCount();
      fetchPlaylistsCount();
      fetchDevelopersCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.GAMES.text,
          url: links.value.GAMES.url,
          total: {
            status: gamesCountDataStatus.value,
            value: gamesCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.DEVELOPERS.text,
          url: links.value.DEVELOPERS.url,
          total: {
            status: developersCountDataStatus.value,
            value: developersCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.USERS.text,
          url: links.value.USERS.url,
          total: {
            status: usersCountDataStatus.value,
            value: usersCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.REVIEWS.text,
          url: links.value.REVIEWS.url,
          total: {
            status: reviewsCountDataStatus.value,
            value: reviewsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.CATEGORIES.text,
          url: links.value.CATEGORIES.url,
          total: {
            status: categoriesCountDataStatus.value,
            value: categoriesCountData.value?.count ?? 0,
          },
          width: 33.33333,
        },
        {
          name: links.value.TAGS.text,
          url: links.value.TAGS.url,
          total: {
            status: tagsCountDataStatus.value,
            value: tagsCountData.value?.count ?? 0,
          },
          width: 33.33333,
        },
        {
          name: links.value.PLAYLISTS.text,
          url: links.value.PLAYLISTS.url,
          total: {
            status: playlistCountDataStatus.value,
            value: playlistCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.FOLLOWINGS.text,
          url: links.value.FOLLOWINGS.url,
          total: {
            status: folloginwsCountDataStatus.value,
            value: folloginwsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.SUBSCRIPTIONS.text,
          url: links.value.SUBSCRIPTIONS.url,
          total: {
            status: subscriptionsCountDataStatus.value,
            value: subscriptionsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.SUGGESTIONS.text,
          url: links.value.SUGGESTIONS.url,
          total: {
            status: suggestionsCountDataStatus.value,
            value: suggestionsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.CALLS_TO_ACTION.text,
          url: links.value.CALLS_TO_ACTION.url,
          total: {
            status: CTAsCountDataStatus.value,
            value: CTAsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.POLLS.text,
          url: links.value.POLLS.url,
          total: {
            status: pollsCountDataStatus.value,
            value: pollsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: "Settings",
          url: links.value.SETTINGS.url,
          width: 33.33333,
        },
        {
          name: "Commands",
          linkList: [
            links.value.CRON_COMMANDS,
            links.value.CRON_COMMANDS_LOGS,
            links.value.CRON_LOGS,
          ],
          width: 33.33333,
        },
        {
          name: "Export",
          url: links.value.EXPORT.url,
          width: 33.33333,
        },
        {
          name: "E-Mail",
          linkList: [
            links.value.MAIL_LOGS_LIST,
            links.value.MAIL_TEMPLATES_LIST,
          ],
          width: 33.33333,
        },
        {
          name: "Administrators",
          url: links.value.ADMIN_ADMINS.url,
          width: 33.33333,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});

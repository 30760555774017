import { Nullable, request, ResponseBody } from "@tager/admin-services";

import { ReviewInterface, SaveReviewPayload } from "@/modules/reviews/typings";

export interface CountData {
  count: number;
}

export function getReviewsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/reviews/count" });
}

export function getReviewsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: Nullable<string>;
}): Promise<ResponseBody<Array<ReviewInterface>>> {
  return request.get({ path: "/admin/reviews", params });
}

export function getReview(id: number): Promise<ResponseBody<ReviewInterface>> {
  return request.get({ path: "/admin/reviews/" + id });
}

export function updateReview(
  id: number,
  body: SaveReviewPayload
): Promise<ResponseBody<ReviewInterface>> {
  return request.put({ path: "/admin/reviews/" + id, body });
}

export function toggleReview(id: number): Promise<ResponseBody> {
  return request.post({ path: "/admin/reviews/" + id + "/toggle" });
}

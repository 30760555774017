import { Nullable, request, ResponseBody } from "@tager/admin-services";

import { CallToActionInterface, CallToActionFullInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getCallToActionsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/calls-to-action/count" });
}

export function getCallToActionsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CallToActionInterface>>> {
  return request.get({ path: "/admin/calls-to-action", params });
}

export function getCallToAction(
  id: string | number
): Promise<ResponseBody<CallToActionFullInterface>> {
  return request.get({ path: `/admin/calls-to-action/${id}` });
}

export type CallToActionCreateOrUpdateConfig = {
  actionsCount?: Nullable<number>;
  newUsersInactivityPushDayNumber?: Nullable<number>;
  newUsersInactivityPushTime?: Nullable<string>;
  pushTitle?: Nullable<string>;
  pushText?: Nullable<string>;
};

export interface CallToActionCreateOrUpdatePayload {
  name: string;
  type: Nullable<string>;
  config: CallToActionCreateOrUpdateConfig;
  amplitudeEvents: unknown;
}

export function createCallToAction(
  payload: CallToActionCreateOrUpdatePayload
): Promise<ResponseBody<CallToActionFullInterface>> {
  return request.post({
    path: `/admin/calls-to-action`,
    body: payload,
  });
}

export function updateCallToAction(
  id: number | string,
  payload: CallToActionCreateOrUpdatePayload
): Promise<ResponseBody<CallToActionFullInterface>> {
  return request.put({
    path: `/admin/calls-to-action/${id}`,
    body: payload,
  });
}

export function deleteCallToAction(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/calls-to-action/${id}` });
}


import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { Page } from "@tager/admin-layout";
import {
  FormField,
  FormFieldAjaxSelect,
  FormFieldFileInput,
  FormFieldSelect,
  FormFooter,
  FormFieldCheckbox,
  OptionType,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";
import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { getUsersListUrl } from "@/utils/paths";
import { sendUserPushRequest } from "@/modules/users/services";
import { getPreReleases } from "@/modules/games/services";
import { GAMES_FIELD_REPEATER_CONFIG } from "@/modules/campaigns/CampaignsFormView/CampaignsFormView.helpers";

import {
  convertFormValuesToPayload,
  FormValues,
  getInitialFormValues,
  PRERELEASES_FIELD_REPEATER_CONFIG,
  RecipientType,
  recipientTypeOptions,
} from "./UsersSendPush.helpers";

export default defineComponent({
  name: "UsersSendPush",
  components: {
    FormFieldFileInput,
    FormFieldAjaxSelect,
    FormField,
    FormFieldSelect,
    Page,
    FormFooter,
    DynamicField,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();

    const isSubmitting = ref<boolean>(false);
    const formValues = ref<FormValues>(getInitialFormValues());
    const errors = ref<Record<string, string>>({});

    const prereleasesField = ref<FieldUnion>(
      universalFieldUtils.createFormField(
        PRERELEASES_FIELD_REPEATER_CONFIG,
        null
      )
    );

    const submitForm = async (event: TagerFormSubmitEvent) => {
      isSubmitting.value = true;

      const payload = convertFormValuesToPayload(
        formValues.value,
        prereleasesField.value as RepeaterField
      );
      try {
        await sendUserPushRequest(payload);

        if (event.type === "create_exit" || event.type === "save_exit") {
          navigateBack(router, getUsersListUrl());
        }

        toast.show({
          variant: "success",
          title: "Push",
          body: "Push successfully sent",
        });
      } catch (e: any) {
        errors.value = convertRequestErrorToMap(e);
        toast.show({
          variant: "danger",
          title: "Push",
          body: "Failure sending push",
        });
      }
    };

    const recipientType = computed<RecipientType>(
      () => formValues.value.recipientType.value
    );

    const preReleaseId = computed<number | null>(
      () => formValues?.value?.preRelease?.value || null
    );

    watch([recipientType, preReleaseId], () => {
      if (recipientType.value === RecipientType.PreReleaseSubscriptions) {
        formValues.value = {
          ...formValues.value,
          amplitudeEventJSON: `{"name": "AppOpenedWithNotification", "params": [{"param":"type", "value": "pre-release"}, {"param":"id", "value": "${preReleaseId.value}"}]}`,
        };
      }
    });

    return {
      formValues,
      errors,
      submitForm,
      isSubmitting: false,
      backButtonUrl: getUsersListUrl(),
      recipientTypeOptions,
      prereleasesField,
    };
  },
  computed: {
    RecipientType() {
      return RecipientType;
    },
  },
});

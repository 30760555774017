
import { defineComponent, onMounted, PropType, watch } from "vue";

import {
  AdvancedSearch,
  AdvancedSearchDateFilter,
  FormFieldCheckbox,
  FormField,
  useAdvancedSearch,
  useAdvancedSearchDateFilter,
  useAdvancedSearchTextFilter,
  useAdvancedSearchBooleanFilter,
} from "@tager/admin-ui";

enum FilterName {
  Rating = "rating",
  User = "user",
  Game = "game",
  WithBody = "with-body",
  DisplayHome = "display-home",
}

export default defineComponent({
  name: "ReviewsListViewFilter",
  components: {
    FormFieldCheckbox,
    FormField,
    AdvancedSearchDateFilter,
    AdvancedSearch,
  },
  props: {
    fetchData: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const dateFilter = useAdvancedSearchDateFilter();
    const ratingFilter = useAdvancedSearchTextFilter(
      FilterName.Rating,
      "Rating"
    );
    const userFilter = useAdvancedSearchTextFilter(FilterName.User, "User");
    const gameFilter = useAdvancedSearchTextFilter(FilterName.Game, "Game");
    const withBodyFilter = useAdvancedSearchBooleanFilter(
      FilterName.WithBody,
      "With Body"
    );
    const displayHomeFilter = useAdvancedSearchBooleanFilter(
      FilterName.DisplayHome,
      "Display Home"
    );

    const { filterParams, tags, tagRemovalHandler } = useAdvancedSearch({
      filters: [
        dateFilter,
        userFilter,
        gameFilter,
        ratingFilter,
        withBodyFilter,
        displayHomeFilter,
      ],
      fetchEntityList: props.fetchData,
    });

    watch(filterParams, () => {
      emit("update:value", filterParams.value);
    });

    onMounted(() => emit("update:value", filterParams.value));

    return {
      dateFilter: dateFilter.value,
      ratingFilter: ratingFilter.value,
      userFilter: userFilter.value,
      gameFilter: gameFilter.value,
      withBody: withBodyFilter.value,
      displayHome: displayHomeFilter.value,
      tags,
      tagRemovalHandler,
    };
  },
});

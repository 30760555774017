import { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";
import { getPollsCreateUrl, getPollsListUrl } from "@/utils/paths";
import PollsUpdateView from "@/modules/polls/PollsUpdateView";

import PollsListView from "./PollsListView";
import PollsCreateView from "./PollsCreateView";

export const POLLS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.POLLS_LIST,
  component: PollsListView,
  name: "Polls",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: getPollsListUrl(), text: "Polls" },
    ],
  },
};

export const POLLS_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.POLLS_CREATE,
  component: PollsCreateView,
  name: "Polls Create",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().POLLS,
      { url: getPollsCreateUrl(), text: "Polls Create" },
    ],
  },
};

export const POLLS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.POLLS_FORM,
  component: PollsUpdateView,
  name: "Polls Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().POLLS,
      {
        url: route.path,
        text: "Polls Form",
      },
    ],
  },
};


import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";
import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  type TabType,
  TagerFormSubmitEvent,
  FormField,
  FormFieldCheckbox,
} from "@tager/admin-ui";

import { getPollsListUrl, getPollsUpdateUrl } from "@/utils/paths";

import { getPoll, updatePoll } from "../services";

import {
  FormValues,
  convertPageFormValuesToUpdatePayload,
  getPollFormValues,
  questionsFieldConfig,
  questionTypes,
} from "./PollsUpdateView.helpers";

export default defineComponent({
  name: "PollsFormView",
  components: {
    Page,
    FormField,
    FormFieldCheckbox,
    FormFooter,
    DynamicField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed<number>(() => +(route.params.id as string));

    const questionIds = ref<Record<string, number>>({});

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => getPoll(id.value),
      initialValue: null,
      resourceName: "Poll",
    });

    onMounted(fetchModel);
    watch(id, () => {
      if (id.value) {
        fetchModel();
      }
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>();

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      if (model.value) {
        values.value = getPollFormValues(model.value);
      }
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
      updateQuestions();
    });

    const questionsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(questionsFieldConfig, null)
    );

    function updateQuestions() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.questions
        ? model.value?.questions.map((value) => {
            return [
              {
                name: "required",
                value: value.required,
              },
              {
                name: "type",
                value: value.type,
              },
              {
                name: "icon",
                value: value.icon,
              },
              { name: "question", value: value.question },
              { name: "questionDe", value: value.questionDe },
              { name: "questionEs", value: value.questionEs },
              { name: "questionIt", value: value.questionIt },
              { name: "questionFr", value: value.questionFr },
              { name: "questionPt", value: value.questionPt },
              { name: "questionPtBr", value: value.questionPtBr },
              { name: "options", value: value.options.join("\n") },
              { name: "optionsDe", value: value.optionsDe?.join("\n") },
              { name: "optionsEs", value: value.optionsEs?.join("\n") },
              { name: "optionsIt", value: value.optionsIt?.join("\n") },
              { name: "optionsFr", value: value.optionsFr?.join("\n") },
              { name: "optionsPt", value: value.optionsPt?.join("\n") },
              { name: "optionsPtBr", value: value.optionsPtBr?.join("\n") },
              { name: "description", value: value.description },
              { name: "descriptionDe", value: value.descriptionDe },
              { name: "descriptionEs", value: value.descriptionEs },
              { name: "descriptionIt", value: value.descriptionIt },
              { name: "descriptionFr", value: value.descriptionFr },
              { name: "descriptionPt", value: value.descriptionPt },
              { name: "descriptionPtBr", value: value.descriptionPtBr },
              { name: "placeholder", value: value.placeholder },
              { name: "placeholderDe", value: value.placeholderDe },
              { name: "placeholderEs", value: value.placeholderEs },
              { name: "placeholderIt", value: value.placeholderIt },
              { name: "placeholderFr", value: value.placeholderFr },
              { name: "placeholderPt", value: value.placeholderPt },
              { name: "placeholderPtBr", value: value.placeholderPtBr },
              {
                name: "dependsOnQuestionNumber",
                value: value.dependsOnQuestionNumber,
              },
              {
                name: "dependsOnQuestionAnswer",
                value: value.dependsOnQuestionAnswer,
              },
              {
                name: "combineWithPrevious",
                value: Boolean(value.combineWithPrevious),
              },
            ];
          })
        : [];

      questionsField.value = universalFieldUtils.createFormField(
        questionsFieldConfig,
        incomingFieldList
      ) as RepeaterField;

      const result: Record<string, number> = {};
      questionsField.value.value.forEach((item, ind) => {
        if (model.value?.questions[ind].id) {
          result[String(item.id)] = model.value?.questions[ind].id;
        }
      });
      questionIds.value = result;
    }

    async function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      if (!values.value) return;

      try {
        const response = await updatePoll(
          id.value,
          convertPageFormValuesToUpdatePayload(
            values.value,
            questionsField.value as RepeaterField,
            questionIds.value
          )
        );

        errors.value = {};

        if (event.type === "save") {
          await router.push(getPollsUpdateUrl(response.data.id));
        } else if (event.type === "save_exit") {
          navigateBack(router, getPollsListUrl());
        }

        toast.show({
          variant: "success",
          title: "Polls",
          body: "Poll successfully updated",
        });
      } catch (error: any) {
        console.error(error);
        errors.value = convertRequestErrorToMap(error);

        toast.show({
          variant: "danger",
          title: "Polls",
          body: "Poll failure updated",
        });
      } finally {
        isSubmitting.value = false;
      }
    }

    const isLoading = computed<boolean>(() => isModelLoading.value);

    const tabs: Array<TabType> = [
      {
        id: "general",
        label: "General",
      },
      {
        id: "questions",
        label: "Questions",
      },
    ];

    const selectedTabId = ref<string>(tabs[0].id);

    return {
      isLoading,
      values,
      errors,
      model,
      isSubmitting,
      tabs,
      selectedTabId,
      submitForm,
      backButtonUrl: getPollsListUrl(),

      questionsField,
    };
  },
});


import { computed, defineComponent, onMounted } from "vue";

import { DataTable } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getUsersUpdateUrl } from "@/utils/paths";
import { getPlaylistsListUrl } from "@/modules/playlists";

import { getUserPushNotifications } from "../services";
import { UserPushNotificationInterface } from "../typings";

import { COLUMN_DEFS } from "./UsersPushNotificationsListView.helpers";

export default defineComponent({
  name: "UsersPushNotificationsListView",
  components: {
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchData,
      isLoading: isLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<UserPushNotificationInterface>({
      fetchEntityList: (params) =>
        getUserPushNotifications({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "User Push Notifications List",
    });

    onMounted(() => {
      fetchData();
    });

    const isDataLoading = computed<boolean>(() => isLoading.value);

    return {
      columnDefs: COLUMN_DEFS,

      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      getUsersUpdateUrl,
    };
  },
  methods: { getPlaylistsListUrl },
});


import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  isNotNullish,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldRichTextInput,
  FormFieldFileInput,
  FormFieldSelect,
  OptionType,
  getFilterParams,
  TabType,
  createTabErrorFinder,
  FieldValue,
  FormFieldUrlAliasInput,
} from "@tager/admin-ui";

import { getDevelopersListUrl, getDevelopersUpdateUrl } from "@/utils/paths";
import { getGames } from "@/modules/games/services";

import { updateDeveloper, createDeveloper } from "../services";
import { getDeveloper } from "../services";

import {
  FormValues,
  getFormValues,
  convertFormValuesToPayload,
} from "./DevelopersFormView.helpers";
import DevelopersGamesTable from "./components/DevelopersGamesTable.vue";

export default defineComponent({
  name: "DevelopersFormView",
  components: {
    FormFieldUrlAliasInput,
    Page,
    FormField,
    FormFooter,
    FormFieldFileInput,
    FormFieldSelect,
    FormFieldRichTextInput,
    DevelopersGamesTable,
    FieldValue,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const alternativeNamesDeleted = ref<string[]>([]);

    const id = computed(() => route.params.id as string | undefined);
    const isCreation = computed<boolean>(() => typeof id.value === "undefined");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => getDeveloper(id.value as string),
      initialValue: null,
      resourceName: "Developer",
    });

    const [fetchGames, { data: gamesList, loading: isGamesListLoading }] =
      useResource({
        fetchResource: () =>
          getGames({
            pageSize: 1000,
            pageNumber: 1,
            ...getFilterParams({
              developer_id: id.value as string,
            }),
          }),
        initialValue: [],
        resourceName: "Games List",
      });

    const initPage = () => {
      if (!isCreation.value) {
        fetchModel();
        fetchGames();
      }
    };

    onMounted(initPage);
    onBeforeRouteUpdate(initPage);

    const gamesPriorities = ref<Array<{ id: number; priority: number | null }>>(
      []
    );
    watch(gamesList, () => {
      gamesPriorities.value = gamesList.value.map((item) => {
        return {
          id: item.id,
          priority: item.developerPriority,
        };
      });
    });

    const gamesOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...gamesList.value.map((game) => ({
          value: game.id,
          label: game.name,
        })),
      ];
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getFormValues(model.value));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getFormValues(model.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload = convertFormValuesToPayload(
        values.value,
        gamesPriorities.value,
        alternativeNamesDeleted.value
      );

      const requestPromise = isCreation.value
        ? createDeveloper(createOrUpdatePayload)
        : updateDeveloper(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getDevelopersUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getDevelopersListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Developers",
            body: isCreation.value
              ? "Developer successfully created"
              : "Developer successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Developers",
            body: isCreation.value
              ? "Error creation developer"
              : "Error updating developer",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isGamesListLoading.value
    );

    const tabList = computed<Array<TabType>>(() => {
      const hasErrors = createTabErrorFinder(errors.value);
      return [
        {
          id: "general",
          label: "General",
          hasErrors: hasErrors(["name", "description"]),
        },
        {
          id: "web",
          label: "Web",
          hasErrors: hasErrors(["urlAlias"]),
        },
        {
          id: "media",
          label: "Media",
          hasErrors: hasErrors(["icon", "cover"]),
        },
        isCreation.value
          ? null
          : {
              id: "games",
              label: "Games",
              hasErrors: hasErrors(["featuredGame"]),
            },
      ].filter(isNotNullish);
    });
    const selectedTabId = ref<string>(tabList.value[0].id);

    const deleteAltName = (name: string) =>
      (alternativeNamesDeleted.value = [
        ...alternativeNamesDeleted.value,
        name,
      ]);

    let websiteOrigin: string =
      process.env.VUE_APP_WEBSITE_URL || window.location.origin;
    if (websiteOrigin.substr(-1) === "/") {
      websiteOrigin = websiteOrigin.substr(0, websiteOrigin.length - 1);
    }

    return {
      tabList,
      selectedTabId,
      games: gamesList,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getDevelopersListUrl(),
      gamesOptions,
      gamesPriorities,
      alternativeNamesDeleted,
      deleteAltName,
      websiteOrigin,
    };
  },
  computed: {
    console: () => console,
  },
  watch: {
    $route(to, from) {
      console.log("route changed");
    },
  },
});

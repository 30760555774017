import {
  FileType,
  MoveDirection,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import { CategoryInterface, CategoryFullInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getCategoriesCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/categories/count" });
}

export function getCategoriesList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CategoryInterface>>> {
  return request.get({ path: "/admin/categories", params });
}

export function getCategory(
  id: string | number
): Promise<ResponseBody<CategoryFullInterface>> {
  return request.get({ path: `/admin/categories/${id}` });
}

export interface CategoryCreateOrUpdatePayload {
  name: string;
  nameShort: string;
  nameDe: string;
  nameShortDe: string;
  nameIt: string;
  nameShortIt: string;
  nameEs: string;
  nameShortEs: string;
  nameFr: string;
  nameShortFr: string;
  namePt: string;
  nameShortPt: string;
  namePtBr: string;
  nameShortPtBr: string;
  urlAlias: string;
  iconFile: string | null;
  iconSymbol: string | null;
  igdbAliases: string;
  appMagicIds: number[];
  onboardingGames: number[];
}

export function createCategory(
  payload: CategoryCreateOrUpdatePayload
): Promise<ResponseBody<CategoryFullInterface>> {
  return request.post({
    path: `/admin/categories`,
    body: payload,
  });
}

export function updateCategory(
  id: number | string,
  payload: CategoryCreateOrUpdatePayload
): Promise<ResponseBody<CategoryFullInterface>> {
  return request.put({
    path: `/admin/categories/${id}`,
    body: payload,
  });
}

export function deleteCategory(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/categories/${id}` });
}

export function moveCategory(
  id: number | string,
  direction: MoveDirection
): Promise<{ success: boolean }> {
  return request.post({ path: `/admin/categories/${id}/move/${direction}` });
}

type AppMagicCategoriesResponse = Array<{
  id: number;
  name: string;
}>;

export function getAppMagicCategories(): Promise<
  ResponseBody<AppMagicCategoriesResponse>
> {
  return request.get({ path: `/admin/categories/appmagic` });
}

export function setCategoryAsTag(
  id: number | string
): Promise<ResponseBody<{ id: number }>> {
  return request.post({ path: `/admin/categories/${id}/set-as-tag` });
}

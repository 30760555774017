import { request, ResponseBody } from "@tager/admin-services";

import { SuggestionInterface } from "@/modules/suggestions/typings";

export interface CountData {
  count: number;
}

export function getSuggestionsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/suggestions/count" });
}

export function getSuggestionsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<SuggestionInterface>>> {
  return request.get({ path: "/admin/suggestions", params });
}

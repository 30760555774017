import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row-cols-2" }
const _hoisted_3 = { class: "row-cols-3" }
const _hoisted_4 = { class: "row-cols-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "row-cols-2" }
const _hoisted_7 = { class: "row-cols-2" }
const _hoisted_8 = { class: "row-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_ToggleSection = _resolveComponent("ToggleSection")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_UsersViewActivityCalendarList = _resolveComponent("UsersViewActivityCalendarList")!
  const _component_UsersViewActivity = _resolveComponent("UsersViewActivity")!
  const _component_UsersViewDevicesList = _resolveComponent("UsersViewDevicesList")!
  const _component_UsersViewGameActionsList = _resolveComponent("UsersViewGameActionsList")!
  const _component_UsersFormGamesList = _resolveComponent("UsersFormGamesList")!
  const _component_UsersViewStats = _resolveComponent("UsersViewStats")!
  const _component_UsersViewLauncherList = _resolveComponent("UsersViewLauncherList")!
  const _component_UsersViewLauncherHistoryList = _resolveComponent("UsersViewLauncherHistoryList")!
  const _component_UsersViewPushNotificationsList = _resolveComponent("UsersViewPushNotificationsList")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tabId": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: `View User - ID ${_ctx.model?.id || '...'} - ${
      _ctx.model?.username || '...'
    }`,
    "is-content-loading": _ctx.isLoading,
    tabs: _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": false,
        "can-create-another": false,
        "submit-and-exit-label": null,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isLoading && _ctx.values && _ctx.model)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("form", {
              novalidate: "",
              onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
            }, [
              (_ctx.selectedTabId === 'general')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_FormField, {
                      value: _ctx.values.priority,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.priority) = $event)),
                      label: "Priority"
                    }, null, 8, ["value"]),
                    _createVNode(_component_ToggleSection, { label: "E-Mail Auth" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_FieldValue, {
                          label: "E-Mail",
                          value: _ctx.model.email
                        }, null, 8, ["value"]),
                        (_ctx.model.email)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createVNode(_component_FormField, {
                                value: _ctx.values.emailAuthCode,
                                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.emailAuthCode) = $event)),
                                label: "E-Mail auth code"
                              }, null, 8, ["value"]),
                              _createVNode(_component_FormFieldCheckbox, {
                                value: _ctx.values.emailAuthDisabled,
                                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.emailAuthDisabled) = $event)),
                                label: "Disable send e-mail"
                              }, null, 8, ["value"])
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_FieldValue, {
                        label: "Username",
                        value: _ctx.model.username
                      }, null, 8, ["value"]),
                      _createVNode(_component_FieldValue, {
                        label: "Name",
                        value: _ctx.model.name
                      }, null, 8, ["value"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_FieldValue, {
                        label: "Paid Preference",
                        value: _ctx.model.paidPreference ?? 'Unknown'
                      }, null, 8, ["value"]),
                      _createVNode(_component_FieldValue, {
                        label: "Tutorials",
                        value: _ctx.model.tutorials
                      }, null, 8, ["value"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_FieldValue, {
                        label: "Referral Code",
                        value: _ctx.model.referralCode
                      }, null, 8, ["value"]),
                      _createVNode(_component_FieldValue, { label: "Referrer" }, {
                        value: _withCtx(() => [
                          (_ctx.model.referrer)
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                to: `/users/${_ctx.model.referrer.id}`
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("ID " + _toDisplayString(_ctx.model.referrer.id) + " - " + _toDisplayString(_ctx.model.referrer.username), 1)
                                ]),
                                _: 1
                              }, 8, ["to"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(" - ")
                              ], 64))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_FieldValue, { label: "Referrals" }, {
                        value: _withCtx(() => [
                          (_ctx.model.referrals.length)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.referrals, (referral) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    key: referral.id
                                  }, [
                                    _createVNode(_component_router_link, {
                                      to: `/users/${referral.id}`
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("ID " + _toDisplayString(referral.id) + " - " + _toDisplayString(referral.username), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to"])
                                  ]))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(" - ")
                              ], 64))
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_FieldValue, {
                        label: "App Rating",
                        value: _ctx.model.appRating ?? '-'
                      }, null, 8, ["value"]),
                      _createVNode(_component_FieldValue, {
                        label: "App Rating Comment",
                        value: _ctx.model.appRatingComment ?? '-'
                      }, null, 8, ["value"])
                    ]),
                    _createVNode(_component_FormFieldSelect, {
                      value: _ctx.values.campaign,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.campaign) = $event)),
                      name: "campaign",
                      label: "Campaign",
                      options: _ctx.campaignOptions
                    }, null, 8, ["value", "options"]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_FormFieldMultiSelect, {
                        "selected-options": _ctx.values.categories,
                        "onUpdate:selectedOptions": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.categories) = $event)),
                        name: "categories",
                        label: "Genres",
                        options: _ctx.categoryOptions
                      }, null, 8, ["selected-options", "options"]),
                      _createVNode(_component_FormFieldMultiSelect, {
                        "selected-options": _ctx.values.tags,
                        "onUpdate:selectedOptions": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.tags) = $event)),
                        name: "tags",
                        label: "Теги",
                        options: _ctx.tagOptions
                      }, null, 8, ["selected-options", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_FormFieldMultiSelect, {
                        "selected-options": _ctx.values.featureFlags,
                        "onUpdate:selectedOptions": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.featureFlags) = $event)),
                        name: "featureFlags",
                        label: "Feature Flags",
                        options: _ctx.featureFlagOptions
                      }, null, 8, ["selected-options", "options"])
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'activity-calendar')
                ? (_openBlock(), _createBlock(_component_UsersViewActivityCalendarList, {
                    key: 1,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'activity')
                ? (_openBlock(), _createBlock(_component_UsersViewActivity, {
                    key: 2,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'devices')
                ? (_openBlock(), _createBlock(_component_UsersViewDevicesList, {
                    key: 3,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'game-actions')
                ? (_openBlock(), _createBlock(_component_UsersViewGameActionsList, {
                    key: 4,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'android_queue')
                ? (_openBlock(), _createBlock(_component_UsersFormGamesList, {
                    key: 5,
                    id: _ctx.model.id,
                    platform: "ANDROID"
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'ios_queue')
                ? (_openBlock(), _createBlock(_component_UsersFormGamesList, {
                    key: 6,
                    id: _ctx.model.id,
                    platform: "IOS"
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'stats')
                ? (_openBlock(), _createBlock(_component_UsersViewStats, {
                    key: 7,
                    model: _ctx.model
                  }, null, 8, ["model"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'launcher')
                ? (_openBlock(), _createBlock(_component_UsersViewLauncherList, {
                    key: 8,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'launcher-history')
                ? (_openBlock(), _createBlock(_component_UsersViewLauncherHistoryList, {
                    key: 9,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true),
              (_ctx.selectedTabId === 'push-notifications')
                ? (_openBlock(), _createBlock(_component_UsersViewPushNotificationsList, {
                    key: 10,
                    id: _ctx.model.id
                  }, null, 8, ["id"]))
                : _createCommentVNode("", true)
            ], 32)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}

import { ref, defineComponent, onMounted } from "vue";
import { format, parseISO, subMonths, setDate, subDays } from "date-fns";
import { useRoute } from "vue-router";

import { BaseButton, BaseInput } from "@tager/admin-ui";

import { router } from "@/router";

export type DateRangeFilterSubmitEvent = { from: Date; to: Date };

export default defineComponent({
  name: "DateRangeFilter",
  components: { BaseButton, BaseInput },
  props: {
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const route = useRoute();

    const { dateFrom, dateTo } = route.query;

    const from = ref<string>();
    const to = ref<string>();

    onMounted(() => {
      from.value = dateFrom
        ? String(dateFrom)
        : format(subMonths(new Date(), 1).setDate(1), "yyyy-MM-dd");
      to.value = dateTo
        ? String(dateTo)
        : format(subDays(setDate(new Date(), 1), 1), "yyyy-MM-dd");
    });

    return {
      from,
      to,
    };
  },
  async mounted() {
    await this.$nextTick();

    this.handleSubmit();
  },
  methods: {
    handleSubmit() {
      if (!this.from || !this.to) return;

      router.push({
        query: {
          dateFrom: format(parseISO(this.from), "yyyy-MM-dd"),
          dateTo: format(parseISO(this.to), "yyyy-MM-dd"),
        },
      });

      this.$emit("submit", {
        from: parseISO(this.from),
        to: parseISO(this.to),
      });
    },
  },
});

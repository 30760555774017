import { Nullable } from "@tager/admin-services";
import { BaseOptionType, OptionType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import {
  CallToActionType,
  callToActionTypesOptions,
} from "@/modules/ctas/constants";

import { CallToActionFullInterface } from "../typings";
import {
  CallToActionCreateOrUpdateConfig,
  CallToActionCreateOrUpdatePayload,
} from "../services";

export type FormValues = {
  name: string;
  type: Nullable<OptionType>;
  config: {
    actionsCount?: Nullable<number>;
    newUsersInactivityPushDayNumber?: Nullable<number>;
    newUsersInactivityPushTime?: Nullable<string>;
    pushTitle?: Nullable<string>;
    pushText?: Nullable<string>;
  };
};

export function getFormValues(
  model: Nullable<CallToActionFullInterface>
): FormValues {
  if (!model) {
    return {
      name: "",
      type: null,
      config: {},
    };
  }

  return {
    name: model.name,
    type:
      callToActionTypesOptions.find((item) => item.value === model.type) ||
      null,
    config: {
      actionsCount: model.config?.actionsCount || null,
      newUsersInactivityPushDayNumber:
        model.config?.newUsersInactivityPushDayNumber || null,
      newUsersInactivityPushTime:
        model.config?.newUsersInactivityPushTime || null,
      pushTitle: model.config?.pushTitle || null,
      pushText: model.config?.pushText || null,
    },
  };
}

function getConfigUpdatePayload(
  values: FormValues
): CallToActionCreateOrUpdateConfig {
  if (!values.type) {
    return {};
  }

  if (
    [
      String(CallToActionType.QueueQuiz),
      String(CallToActionType.QueueRate),
      String(CallToActionType.QueueInvite),
    ].includes(values.type.value)
  ) {
    return {
      actionsCount: values.config.actionsCount,
    };
  } else if (values.type.value === CallToActionType.NewUsersInactivityPush) {
    return {
      newUsersInactivityPushDayNumber:
        values.config.newUsersInactivityPushDayNumber,
      newUsersInactivityPushTime: values.config.newUsersInactivityPushTime,
      pushTitle: values.config.pushTitle,
      pushText: values.config.pushText,
    };
  } else {
    return {};
  }
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues,
  events: RepeaterField
): CallToActionCreateOrUpdatePayload {
  return {
    name: values.name,
    type: values.type?.value || null,
    config: getConfigUpdatePayload(values),
    amplitudeEvents: events.value.map((item) => {
      return {
        type: (item.value[0].value as BaseOptionType)?.value,
        event: item.value[1].value,
        params: (item.value[2].value as any[]).map((item) => {
          return {
            param: item.value[0].value,
            value: item.value[1].value,
          };
        }),
      };
    }),
  };
}

export function getEventsFieldConfig(ctaType?: string): FieldConfigUnion {
  let eventTypes: Array<OptionType> = [];

  if (
    ctaType &&
    [
      String(CallToActionType.QueueQuiz),
      String(CallToActionType.QueueInvite),
    ].includes(ctaType)
  ) {
    eventTypes = [
      { label: "Skip", value: "skip" },
      { label: "Ok", value: "ok" },
    ];
  } else if (ctaType === CallToActionType.QueueRate) {
    eventTypes = [
      { label: "Skip", value: "skip" },
      { label: "Rate 1", value: "rate_1" },
      { label: "Rate 2", value: "rate_2" },
      { label: "Rate 3", value: "rate_3" },
      { label: "Rate 4", value: "rate_4" },
      { label: "Rate 5", value: "rate_5" },
      { label: "Review", value: "review" },
      { label: "Store", value: "store" },
    ];
  } else if (ctaType === CallToActionType.NewUsersInactivityPush) {
    eventTypes = [{ label: "Open Push", value: "open_push" }];
  }

  return {
    name: "",
    type: "REPEATER",
    label: "Amplitude Events",
    meta: {
      view: "TABLE",
      noToggle: true,
      noMoveActions: true,
    },
    fields: [
      {
        name: "type",
        type: "SELECT",
        label: "Type",
        meta: {
          options: eventTypes,
        },
        width: "180px",
      },
      {
        name: "event",
        type: "STRING",
        label: "Event",
        width: "350px",
      },
      {
        name: "params",
        type: "REPEATER",
        label: "Params",
        meta: {
          view: "TABLE",
          noToggle: true,
          noMoveActions: true,
        },
        fields: [
          {
            name: "param",
            type: "STRING",
            label: "Param",
          },
          {
            name: "value",
            type: "STRING",
            label: "Value",
          },
        ],
      },
    ],
  };
}

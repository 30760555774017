
import { defineComponent } from "vue";

import StatsWidget from "./StatsWidget.vue";

export default defineComponent({
  name: "UsersStatsWidget",
  components: { StatsWidget },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
});

import { ColumnDefinition } from "@tager/admin-ui";

import { SubscriptionInterface } from "@/modules/subscriptions/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<SubscriptionInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    headStyle: { width: "50px" },
  },
  {
    id: 2,
    name: "Date / Time",
    field: "createdAt",
    type: "datetime",
    headStyle: { width: "180px" },
  },
  {
    id: 3,
    name: "Platform",
    field: "platform",
    headStyle: { width: "120px" },
  },
  {
    id: 4,
    name: "User",
    field: "user",
    format: ({ row }) => {
      return row.user ? "ID " + row.user.id + " - " + row.user.username : "-";
    },
    headStyle: { width: "300px" },
  },
  {
    id: 5,
    name: "Game",
    field: "game",
    format: ({ row }) => {
      return row.game ? "ID " + row.game.id + " - " + row.game.name : "-";
    },
  },
];

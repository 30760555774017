import { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";
import { getPlaylistsListUrl } from "@/modules/playlists/paths";

import PlaylistListView from "./PlaylistListView";
import PlaylistDeletedListView from "./PlaylistDeletedListView";
import PlaylistUpdateView from "./PlaylistUpdateView";
import PlaylistCreateView from "./PlaylistCreateView";

export const PLAYLISTS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PLAYLISTS_LIST,
  component: PlaylistListView,
  name: "Playlists",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: getPlaylistsListUrl(), text: "Playlists" },
    ],
  },
};

export const PLAYLISTS_DELETED_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PLAYLISTS_DELETED_LIST,
  component: PlaylistDeletedListView,
  name: "Playlists Deleted",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: getPlaylistsListUrl(), text: "Playlists" },
    ],
  },
};

export const PLAYLISTS_UPDATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PLAYLISTS_FORM,
  component: PlaylistUpdateView,
  name: "Playlists Update",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: getPlaylistsListUrl(), text: "Playlists" },
    ],
  },
};

export const PLAYLISTS_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PLAYLISTS_CREATE,
  component: PlaylistCreateView,
  name: "Playlists Create",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      { url: getPlaylistsListUrl(), text: "Playlists" },
    ],
  },
};


import { computed, defineComponent, onMounted, ref } from "vue";

import {
  BaseButton,
  DataTable,
  EditIcon,
  useDataTable,
  AdvancedSearchFilterParams,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getReviewsList } from "@/modules/reviews/services";
import { ReviewInterface } from "@/modules/reviews/typings";
import { getReviewsUpdateUrl } from "@/modules/reviews";
import ReviewsListViewFilter from "@/modules/reviews/ReviewsListView/ReviewsListView.filter.vue";
import ReviewsListViewVisibleCell from "@/modules/reviews/ReviewsListView/components/ReviewsListViewVisibleCell.vue";

import { COLUMN_DEFS, SORT_OPTIONS } from "./ReviewsListView.helpers";

export default defineComponent({
  name: "ReviewsListView",
  components: {
    ReviewsListViewVisibleCell,
    ReviewsListViewFilter,
    BaseButton,
    EditIcon,
    Page,
    DataTable,
  },
  setup() {
    const filter = ref<AdvancedSearchFilterParams>({});

    const {
      fetchEntityList: fetchEntityList,
      isLoading: isReviewsLoading,
      rowData: gamesData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<ReviewInterface>({
      fetchEntityList: (params) =>
        getReviewsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort,
          ...filter.value,
        }),
      initialValue: [],
      resourceName: "Reviews List",
      defaultSort: SORT_OPTIONS[0].value,
    });
    onMounted(() => {
      fetchEntityList();
    });

    const isDataLoading = computed<boolean>(() => isReviewsLoading.value);

    return {
      columnDefs: COLUMN_DEFS,
      sortOptions: SORT_OPTIONS,
      sortValue: sort,
      rowData: gamesData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getReviewsUpdateUrl,
      fetchEntityList,
      filter,
    };
  },
});

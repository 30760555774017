
import { defineComponent, PropType } from "vue";

import { ColumnDefinition, DataTable, FormField } from "@tager/admin-ui";

import { DeveloperInterface } from "@/modules/developers/typings";
import GamesListViewPlatform from "@/modules/games/GamesListView/components/GamesListViewPlatform.vue";
import { getGamesUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<DeveloperInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "40px",
  },
  {
    id: 5,
    name: "Priority",
    width: "100px",
    field: "priority",
  },
  {
    id: 3,
    name: "Name",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getGamesUpdateUrl(row.id),
      },
    }),
  },
  {
    id: 3,
    name: "Android",
    field: "android",
    width: "250px",
  },
  {
    id: 4,
    name: "iOS",
    field: "ios",
    width: "250px",
  },
];

type PriorityItem = { id: number; priority: number | null };

export default defineComponent({
  name: "CategoriesFormView",
  components: {
    DataTable,
    GamesListViewPlatform,
    FormField,
  },
  props: {
    games: {
      type: Array,
    },
    priorities: {
      type: Array as PropType<Array<PriorityItem>>,
      default: () => [],
    },
  },
  setup(_props, context) {
    function emitUpdateEvent(newValue: Array<PriorityItem>) {
      context.emit("update:priorities", newValue);
    }

    const onChange = (id: number, priority: string) => {
      const newValue: Array<PriorityItem> = _props.priorities.map((item) => {
        if (item.id === id) {
          return { id: item.id, priority: priority ? +priority : null };
        } else {
          return {
            id: item.id,
            priority: item.priority ? +item.priority : null,
          };
        }
      });

      emitUpdateEvent(newValue);
    };

    return {
      columnDefs: COLUMN_DEFS,
      onChange,
    };
  },
});

import { Nullable } from "@tager/admin-services";

import { ReviewInterface, SaveReviewPayload } from "../typings";

export type FormValues = {
  displayWebHome: boolean;
};

export function getFormValues(model: Nullable<ReviewInterface>): FormValues {
  if (!model) {
    return {
      displayWebHome: false,
    };
  }

  return {
    displayWebHome: model.displayWebHome,
  };
}

export function convertFormValuesToPayload(
  values: FormValues
): SaveReviewPayload {
  return {
    displayWebHome: values.displayWebHome,
  };
}

import { createId, Nullable } from "@tager/admin-services";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { CategoryFullInterface } from "../typings";
import { CategoryCreateOrUpdatePayload } from "../services";

export type FormValues = {
  name: string;
  nameShort: string;
  nameDe: string;
  nameShortDe: string;
  nameEs: string;
  nameShortEs: string;
  nameIt: string;
  nameShortIt: string;
  nameFr: string;
  nameShortFr: string;
  namePt: string;
  nameShortPt: string;
  namePtBr: string;
  nameShortPtBr: string;
  urlAlias: string;

  iconFile: Nullable<SingleFileInputValueType>;
  iconSymbol: string | null;

  igdbAliases: string;

  appMagicIds: Array<OptionType<number>>;
};

export function getCategoryFormValues(
  model: Nullable<CategoryFullInterface>,
  appMagicCategoryOptions: Array<OptionType<number>>
): FormValues {
  if (!model) {
    return {
      name: "",
      nameShort: "",
      nameDe: "",
      nameShortDe: "",
      nameEs: "",
      nameShortEs: "",
      nameIt: "",
      nameShortIt: "",
      nameFr: "",
      nameShortFr: "",
      namePt: "",
      nameShortPt: "",
      namePtBr: "",
      nameShortPtBr: "",
      urlAlias: "",
      iconFile: null,
      iconSymbol: "",
      igdbAliases: "",
      appMagicIds: [],
    };
  }

  return {
    name: model.name || "",
    nameShort: model.nameShort || "",
    nameDe: model.nameDe || "",
    nameShortDe: model.nameShortDe || "",
    nameEs: model.nameEs || "",
    nameShortEs: model.nameShortEs || "",
    nameIt: model.nameIt || "",
    nameShortIt: model.nameShortIt || "",
    nameFr: model.nameFr || "",
    nameShortFr: model.nameShortFr || "",
    namePt: model.namePt || "",
    nameShortPt: model.nameShortPt || "",
    namePtBr: model.namePtBr || "",
    nameShortPtBr: model.nameShortPtBr || "",
    urlAlias: model.urlAlias || "",
    iconFile: model.iconFile ? { id: createId(), file: model.iconFile } : null,
    iconSymbol: model.iconSymbol,
    igdbAliases: model.igdbAliases || "",
    appMagicIds: model.appMagicIds
      ? appMagicCategoryOptions.filter((item) =>
          model.appMagicIds.includes(item.value)
        )
      : [],
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues,
  onboardingGames: RepeaterField
): CategoryCreateOrUpdatePayload {
  return {
    name: values.name,
    nameShort: values.nameShort,
    nameDe: values.nameDe,
    nameShortDe: values.nameShortDe,
    nameEs: values.nameEs,
    nameShortEs: values.nameShortEs,
    nameIt: values.nameIt,
    nameShortIt: values.nameShortIt,
    nameFr: values.nameFr,
    nameShortFr: values.nameShortFr,
    namePt: values.namePt,
    nameShortPt: values.nameShortPt,
    namePtBr: values.namePtBr,
    nameShortPtBr: values.nameShortPtBr,
    urlAlias: values.urlAlias,
    iconFile: values.iconFile?.file?.id || null,
    iconSymbol: values.iconSymbol,
    igdbAliases: values.igdbAliases,
    onboardingGames:
      onboardingGames.value.map((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return item.value[0]?.value.value;
      }) || [],
    appMagicIds: values.appMagicIds.map((item) => item.value),
  };
}

export const onboardingGamesDataConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Onboarding Games",
  meta: {
    view: "TABLE",
  },
  fields: [
    {
      name: "game",
      type: "AJAX_SELECT",
      label: "Game",
      meta: {
        requestUrl: `/admin/games/ajax-search`,
      },
    },
  ],
};

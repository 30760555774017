import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    variant: "primary",
    loading: _ctx.loading,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createTextVNode("Set as Genre")
    ]),
    _: 1
  }, 8, ["loading", "onClick"]))
}
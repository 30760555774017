import { parseISO } from "date-fns";

import { ColumnDefinition, OptionType } from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";
import { formatDateTime } from "@tager/admin-ui";

import { getUsersUpdateUrl } from "@/utils/paths";

import { UserInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserInterface>> = [
  {
    id: 3,
    name: "User",
    type: "name",
    field: "id",
    headStyle: { width: "200px" },
    format: ({ row }) => ({
      adminLink: {
        text: "ID " + row.id,
        url: getUsersUpdateUrl(row.id),
      },
      paramList: [
        row.createdAt
          ? {
              name: "Registered",
              value: formatDateTime(parseISO(row.createdAt)),
            }
          : null,
        {
          name: "Priority",
          value: String(row.priority),
        },
        {
          name: "Paid Preference",
          value: row.paidPreference ? row.paidPreference : "Unknown",
        },
        {
          name: "Campaign",
          value: row.campaign ? row.campaign : "-",
        },
      ].filter(isNotNullish),
    }),
  },
  {
    id: 2,
    name: "Avatar",
    field: "avatar",
    type: "image",
    headStyle: { width: "150px" },
  },
  {
    id: 3,
    name: "Username",
    field: "username",
    width: "100px",
  },
  {
    id: 4,
    name: "Name",
    field: "name",
    headStyle: { width: "200px" },
  },
  {
    id: 7,
    name: "Stats",
    field: "stats",
    type: "key-value",
    format: ({ row }) => {
      return row.stats
        ? [
            { key: "Followers", value: String(row.stats.followers) },
            { key: "Following", value: String(row.stats.following) },
            { key: "Wanted", value: String(row.stats.wanted) },
            { key: "Played", value: String(row.stats.played) },
            { key: "Skipped", value: String(row.stats.skipped) },
            { key: "Playlists", value: String(row.stats.playlists) },
            { key: "Reviews", value: String(row.stats.reviews) },
            { key: "Downloads", value: String(row.stats.downloaded) },
            { key: "Launched", value: String(row.stats.launched) },
          ]
        : [];
    },
    options: {
      view: "table",
    },
    width: "200px",
  },
  {
    id: 6,
    name: "Taxonomies",
    field: "taxonomies",
    type: "key-value",
    format: ({ row }) => {
      return [
        row.categories.length
          ? { key: "Genres", value: row.categories.join(", ") }
          : null,
        row.tags.length
          ? { key: "Categories", value: row.tags.join(", ") }
          : null,
      ].filter(isNotNullish);
    },
  },
  {
    id: 5,
    name: "",
    field: "relations",
    headStyle: { width: "180px" },
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "priority_desc",
    label: "Priority",
  },
  {
    value: "created_at_desc",
    label: "Date added",
  },
  {
    value: "following_desc",
    label: "Following",
  },
  {
    value: "followers_desc",
    label: "Followers",
  },
  {
    value: "reviews_desc",
    label: "Reviews",
  },
  {
    value: "wanted_desc",
    label: "Wanted",
  },
  {
    value: "played_desc",
    label: "Played",
  },
  {
    value: "playlists_desc",
    label: "Playlists",
  },
  {
    value: "downloaded_desc",
    label: "Downloaded",
  },
  {
    value: "skipped_desc",
    label: "Skipped",
  },
];

import { MoveDirection, request, ResponseBody } from "@tager/admin-services";

import {
  PlaylistDeletedInterface,
  PlaylistFullInterface,
  PlaylistGameInterface,
  PlaylistInterface,
} from "@/modules/playlists/typings";

export interface CountData {
  count: number;
}

export function getPlaylistsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/playlists/count" });
}

export function getPlaylists(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string | null;
}): Promise<ResponseBody<Array<PlaylistInterface>>> {
  return request.get({ path: "/admin/playlists", params });
}

export function getDeletedPlaylists(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<PlaylistDeletedInterface>>> {
  return request.get({ path: "/admin/playlists/deleted", params });
}

export function getPlaylist(
  id: string | number
): Promise<ResponseBody<PlaylistFullInterface>> {
  return request.get({ path: `/admin/playlists/${id}` });
}

export function getPlaylistGames(
  id: string | number
): Promise<ResponseBody<Array<PlaylistGameInterface>>> {
  return request.get({ path: `/admin/playlists/${id}/games` });
}

export interface PlaylistCreatePayload {
  name: string;
  user: number | null;
}

export function createPlaylist(
  payload: PlaylistCreatePayload
): Promise<ResponseBody<PlaylistInterface>> {
  return request.post({
    path: `/admin/playlists`,
    body: payload,
  });
}

export interface PlaylistUpdatePayload {
  featuredEn: boolean;
  featuredEs: boolean;
  featuredIt: boolean;
  featuredDe: boolean;
  featuredFr: boolean;
  featuredPt: boolean;
  featuredPtBr: boolean;
  featuredPriority: number | null;
  weekly: boolean;
  weeklyDateStart: string | null;
  weeklyDateEnd: string | null;
  webAvailable: boolean;
  webUrlAlias: string | null;

  webDescription: string | null;
  webFaq: Array<{ question: string; answer: string }>;
  webTextBlocks: Array<{ title: string; body: string }>;

  games: number[];
}

export function updatePlaylist(
  id: number | string,
  payload: PlaylistUpdatePayload
): Promise<ResponseBody<PlaylistInterface>> {
  return request.put({
    path: `/admin/playlists/${id}`,
    body: payload,
  });
}

export function movePlaylistGame(
  playlistId: number | string,
  gameId: number | string,
  direction: MoveDirection
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/playlists/${playlistId}/move-game/${gameId}/${direction}`,
  });
}


import { defineComponent, onMounted } from "vue";

import { DataTable, useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { FollowingInterface } from "@/modules/followings/typings";
import { getFollowingList } from "@/modules/followings/services";

import { COLUMN_DEFS } from "./FollowingsListView.helpers";

export default defineComponent({
  name: "FollowingsListView",
  components: {
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchEntityList,
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<FollowingInterface>({
      fetchEntityList: (params) =>
        getFollowingList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Followings List",
    });

    onMounted(() => {
      fetchEntityList();
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});

import { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { ReviewsListView, ReviewsUpdateView } from "@/modules/reviews";
import { getLinks } from "@/constants/links";

export const REVIEWS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.REVIEWS_LIST,
  component: ReviewsListView,
  name: "Reviews List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().REVIEWS],
  },
};

export const REVIEWS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.REVIEWS_FORM,
  component: ReviewsUpdateView,
  name: "Reviews Form",
  meta: {
    getBreadcrumbs: (route) => [
      getLinks().HOME,
      getLinks().REVIEWS,
      {
        url: route.path,
        text: "Review Form",
      },
    ],
  },
};

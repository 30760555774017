
import { defineComponent, PropType } from "vue";

import {
  BaseButton,
  FieldValue,
  FormField,
  FormFieldAjaxSelect,
  FormFieldCheckbox,
  FormFieldFileInput,
  FormFieldMultiSelect,
  FormFieldOptionsSwitcherTrueFalse,
  FormFieldRichTextInput,
  FormFieldSelect,
} from "@tager/admin-ui";
import { PlatformFullData } from "@/modules/games/typings";
import { MONTH_OPTION_VALUES } from "@/utils/common";

export default defineComponent({
  name: "GamesUpdateViewPlatform",
  computed: {
    MONTH_OPTION_VALUES() {
      return MONTH_OPTION_VALUES;
    },
  },
  components: {
    FormFieldAjaxSelect,
    FormField,
    FieldValue,
    FormFieldCheckbox,
    FormFieldFileInput,
    FormFieldSelect,
    FormFieldMultiSelect,
    BaseButton,
    FormFieldRichTextInput,
    FormFieldOptionsSwitcherTrueFalse,
  },
  props: {
    model: {
      type: Object as PropType<PlatformFullData>,
    },
    visible: {
      type: Boolean,
    },
    enabled: {
      type: Boolean,
    },
    platform: {
      type: String,
    },
    alternativeStores: {
      type: Array,
    },
    alternativeStoresDeleted: {
      type: Array,
    },
    countryOptions: {
      type: Array,
    },
    values: {
      type: Array,
    },
    errors: {
      type: Array,
    },
  },
  emits: ["activate", "show", "hide", "delete", "delete-store"],
});

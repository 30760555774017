
import { computed, defineComponent } from "vue";

import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";
import { MenuItemType, PageLayout } from "@tager/admin-layout";

import { getLinks } from "@/constants/links";
import {
  getDevelopersCreateUrl,
  getDevelopersMergeUrl,
  getGamesCreateUrl,
  getGamesMergeUrl,
  getUsersSendPushUrl,
  getUsersStatsUrl,
} from "@/utils/paths";
import { getCampaignsListUrl } from "@/modules/campaigns/links";

export default defineComponent({
  name: "BaseApp",
  components: { PageLayout },
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },

      {
        id: "games",
        icon: ViewListIcon,
        text: "Games",
        children: [
          links.value.GAMES,
          links.value.GAMES_PLATFORMS,
          links.value.GAMES_PRE_RELEASES,
          {
            text: "New Game",
            url: getGamesCreateUrl(),
          },
          {
            text: "Merge Games",
            url: getGamesMergeUrl(),
          },
          {
            text: "Launcher Games",
            url: "/games/launcher-custom",
          },
        ],
      },
      {
        id: "developers",
        icon: ViewListIcon,
        text: "Developers",
        children: [
          links.value.DEVELOPERS,
          {
            text: "New Developer",
            url: getDevelopersCreateUrl(),
          },
          {
            text: "Merge Developers",
            url: getDevelopersMergeUrl(),
          },
        ],
      },
      {
        id: "users",
        icon: ViewListIcon,
        text: "Users",
        children: [
          links.value.USERS,
          {
            text: "Campaigns",
            url: getCampaignsListUrl(),
          },
          links.value.USERS_PUSH_NOTIFICATIONS,
          {
            text: "Send Push",
            url: getUsersSendPushUrl(),
          },
          {
            text: "Stats",
            url: getUsersStatsUrl(),
          },
        ],
      },

      { id: "reviews", icon: ViewListIcon, ...links.value.REVIEWS },
      { id: "categories", icon: ViewListIcon, ...links.value.CATEGORIES },
      { id: "tags", icon: ViewListIcon, ...links.value.TAGS },
      {
        id: "playlists",
        icon: ViewListIcon,
        ...links.value.PLAYLISTS,
        children: [
          links.value.PLAYLISTS,
          {
            text: "Deleted",
            url: "/playlists/deleted",
          },
        ],
      },
      { id: "followings", icon: ViewListIcon, ...links.value.FOLLOWINGS },
      { id: "subscriptions", icon: ViewListIcon, ...links.value.SUBSCRIPTIONS },
      { id: "suggestions", icon: ViewListIcon, ...links.value.SUGGESTIONS },
      { id: "ctas", icon: ViewListIcon, ...links.value.CALLS_TO_ACTION },
      { id: "polls", icon: ViewListIcon, ...links.value.POLLS },
      { id: "export", icon: ViewListIcon, ...links.value.EXPORT },
      { id: "settings", icon: ViewListIcon, ...links.value.SETTINGS },
      {
        id: "email",
        text: "E-Mail",
        icon: ViewListIcon,
        children: [links.value.MAIL_TEMPLATES_LIST, links.value.MAIL_LOGS_LIST],
      },
      {
        id: "commands",
        icon: SettingsIcon,
        text: "Commands",
        children: [
          links.value.CRON_COMMANDS,
          links.value.CRON_COMMANDS_LOGS,
          links.value.CRON_LOGS,
        ],
      },
      {
        id: "admins",
        text: "Administrators",
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});

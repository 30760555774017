
import { defineComponent, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  BaseButton,
  DataTable,
  EyeIcon,
  FormField,
  FormFieldSelect,
  AdvancedSearch,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import {
  getPlaylistsCreateUrl,
  getPlaylistsViewUrl,
} from "@/modules/playlists";
import { getPlaylists } from "@/modules/playlists/services";
import TableCellUser from "@/components/TableCellUser.vue";
import {
  useAdvancedSearch,
  categoryOptions,
  webAvailableOptions,
} from "@/modules/playlists/PlaylistListView/PlaylistListView.search";
import { LANGUAGE_OPTIONS } from "@/constants/languages";

import { PlaylistInterface } from "../typings";

import { COLUMN_DEFS } from "./PlaylistListView.helpers";
import { SORT_OPTIONS } from "./PlaylistListView.helpers";

export default defineComponent({
  name: "PlaylistsListView",
  components: {
    EyeIcon,
    BaseButton,
    Page,
    DataTable,
    TableCellUser,
    FormField,
    FormFieldSelect,
    AdvancedSearch,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      isLoading: isDataLoading,
      rowData: data,
      fetchEntityList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<PlaylistInterface>({
      fetchEntityList: (params) =>
        getPlaylists({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Playlists List",
      defaultSort: SORT_OPTIONS[0].value,
    });

    const {
      userFilter,
      categoryFilter,
      webAvailableFilter,
      filterParams,
      languageFilter,
      tags,
      tagRemovalHandler,
    } = useAdvancedSearch({
      route,
    });

    watch(filterParams, () => {
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber", "sort"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchEntityList();
      }
    });

    return {
      userFilter,
      categoryFilter,
      webAvailableFilter,
      languageFilter,
      tags,
      tagRemovalHandler,
      columnDefs: COLUMN_DEFS,
      sortOptions: SORT_OPTIONS,
      sortValue: sort,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getPlaylistsViewUrl,
      getPlaylistsCreateUrl,
      categoryOptions,
      webAvailableOptions,
      LANGUAGE_OPTIONS,
    };
  },
});

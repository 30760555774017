import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getReviewsListUrl() {
  return ROUTE_PATHS.REVIEWS_LIST;
}

export function getReviewsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.REVIEWS_FORM)({ id: id });
}

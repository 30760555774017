import { Nullable, request, ResponseBody } from "@tager/admin-services";

import { CategoryFullInterface } from "@/modules/categories/typings";

import {
  GameCreatePayload,
  GameFullInterface,
  GameInterface,
  GamePlatformInterface,
  GamePreReleaseInterface,
  GameShortInterface,
  GamesLauncherCustomInterface,
  GameUpdatePayload,
} from "./typings";

export interface CountData {
  count: number;
}

export function getGamesLauncherCustom(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<GamesLauncherCustomInterface>>> {
  return request.get({ path: "/admin/games/launcher-custom", params });
}

export function getGamesCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/games/count" });
}

export function getGames(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: Nullable<string>;
  view?: string;
}): Promise<ResponseBody<Array<GameInterface>>> {
  return request.get({ path: "/admin/games", params });
}

export function getGamesPlatforms(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: Nullable<string>;
}): Promise<ResponseBody<Array<GamePlatformInterface>>> {
  return request.get({ path: "/admin/games/platforms", params });
}

export function getPreReleases(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: Nullable<string>;
}): Promise<ResponseBody<Array<GamePreReleaseInterface>>> {
  return request.get({ path: "/admin/games/pre-releases", params });
}

export function getGamesWithShortView(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: Nullable<string>;
}): Promise<ResponseBody<Array<GameShortInterface>>> {
  return request.get({
    path: "/admin/games",
    params: { ...params, view: "short" },
  });
}

export function getGame(
  id: string | number
): Promise<ResponseBody<GameFullInterface>> {
  return request.get({ path: `/admin/games/${id}` });
}

export function createGame(
  payload: GameCreatePayload
): Promise<ResponseBody<CategoryFullInterface>> {
  return request.post({
    path: `/admin/games`,
    body: payload,
  });
}

export function updateGame(
  id: number | string,
  payload: GameUpdatePayload
): Promise<ResponseBody<CategoryFullInterface>> {
  return request.put({
    path: `/admin/games/${id}`,
    body: payload,
  });
}

type SyncAppTweakResponse = {
  ANDROID: {
    synced: boolean;
    syncedError: string | null;
    saved: boolean;
    savedError: string | null;
  };
  IOS: {
    synced: boolean;
    syncedError: string | null;
    saved: boolean;
    savedError: string | null;
  };
};

export function syncGameWithAppTweak(
  id: string | number
): Promise<SyncAppTweakResponse> {
  return request.post({ path: `/admin/games/${id}/apptweak` });
}

export function syncGameWithAppMagic(
  id: string | number
): Promise<SyncAppTweakResponse> {
  return request.post({ path: `/admin/games/${id}/appmagic` });
}

export function deleteGame(id: number | string): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/games/${id}` });
}

export function mergeGames(from: number, to: number): Promise<ResponseBody> {
  return request.post({
    path: `/admin/games/merge`,
    body: { from, to },
  });
}

type Platform = "android" | "ios";

export function activatePlatform(
  id: number,
  platform: Platform
): Promise<ResponseBody> {
  return request.post({
    path: `/admin/games/${id}/${platform}/activate`,
  });
}

export function deletePlatform(
  id: number,
  platform: Platform
): Promise<ResponseBody> {
  return request.post({
    path: `/admin/games/${id}/${platform}/delete`,
  });
}

export function showPlatform(
  id: number,
  platform: Platform
): Promise<ResponseBody> {
  return request.post({
    path: `/admin/games/${id}/${platform}/show`,
  });
}

export function hidePlatform(
  id: number,
  platform: Platform
): Promise<ResponseBody> {
  return request.post({
    path: `/admin/games/${id}/${platform}/hide`,
  });
}

import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { DeveloperFullInterface, SaveDeveloperPayload } from "../typings";

export type FormValues = {
  priority: number | null;
  name: string;

  urlAlias: string;
  description: string;
  webOverview: string;
  webOverviewFull: string;

  icon: Nullable<SingleFileInputValueType>;
  cover: Nullable<SingleFileInputValueType>;
  featuredGame: OptionType<number> | null;
};

export function getFormValues(
  model: Nullable<DeveloperFullInterface>
): FormValues {
  if (!model) {
    return {
      priority: null,
      urlAlias: "",
      name: "",
      description: "",
      webOverview: "",
      webOverviewFull: "",
      icon: null,
      cover: null,
      featuredGame: null,
    };
  }

  return {
    priority: model.priority,
    urlAlias: model.urlAlias,
    name: model.name || "",

    description: model.description || "",
    webOverview: model.webOverview || "",
    webOverviewFull: model.webOverviewFull || "",

    icon: model.icon ? { id: createId(), file: model.icon } : null,
    cover: model.cover ? { id: createId(), file: model.cover } : null,
    featuredGame: model.featuredGame
      ? { value: model.featuredGame.id, label: model.featuredGame.name }
      : null,
  };
}

export function convertFormValuesToPayload(
  values: FormValues,
  priorities?: Array<{ id: number; priority: number | null }>,
  alternativeNamesDeleted?: string[]
): SaveDeveloperPayload {
  return {
    priority: values.priority,
    name: values.name,
    urlAlias: values.urlAlias,
    description: values.description,
    webOverview: values.webOverview,
    webOverviewFull: values.webOverviewFull,
    icon: values.icon?.file?.id || null,
    cover: values.cover?.file?.id || null,
    featuredGame: values.featuredGame?.value || null,
    gamePriorities: priorities || [],
    alternativeNamesDeleted: alternativeNamesDeleted || [],
  };
}


import { defineComponent, onMounted } from "vue";

import { ColumnDefinition, DataTable, useDataTable } from "@tager/admin-ui";

import { getUserLauncher } from "@/modules/users/services";
import { UserLauncherInterface } from "@/modules/users/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserLauncherInterface>> = [
  {
    id: 1,
    name: "#",
    field: "num",
    format: ({ row, rowIndex }) => {
      return rowIndex + 1;
    },
    style: {
      width: "40px",
      textAlign: "center",
    },
    headStyle: {
      width: "40px",
      textAlign: "center",
    },
  },
  {
    id: 2,
    name: "Game",
    type: "link",
    field: "game",
    options: {
      shouldOpenNewTab: true,
      disableCopyButton: true,
    },
    format: ({ row }) => {
      return row.game
        ? {
            url: "/games/" + row.game.id,
            text: String(row.game.id) + " - " + row.game.name,
          }
        : null;
    },
  },
  {
    id: 3,
    name: "Package Name",
    field: "packageName",
    width: "150px",
  },
  {
    id: 4,
    name: "Device",
    field: "deviceId",
    width: "150px",
  },
  {
    id: 5,
    name: "Version",
    field: "version",
    width: "100px",
    options: {
      maxLength: 8,
    },
  },
  {
    id: 7,
    name: "Playtime",
    field: "playtime",
    width: "140px",
    format: ({ row }) => {
      if (!row.playtime) return "-";

      const seconds = Math.floor(row.playtime / 1000);

      if (seconds < 60) {
        return seconds + " sec.";
      }

      const minutes = Math.floor(seconds / 60);
      if (minutes < 60) {
        return minutes + " min.";
      }

      const hoursMinutes = minutes % 60;
      return (
        Math.floor(minutes / 60) +
        " h., " +
        (hoursMinutes < 10 ? "0" : "") +
        hoursMinutes +
        " min."
      );
    },
  },
  {
    id: 5,
    name: "Installed At",
    field: "installedAt",
    width: "168px",
    type: "datetime",
  },
  {
    id: 6,
    name: "Updated At",
    field: "updatedAt",
    width: "168px",
    type: "datetime",
  },
];

export default defineComponent({
  name: "UsersViewLauncherList",
  components: {
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserLauncherInterface>({
      fetchEntityList: () => getUserLauncher(props.id),
      initialValue: [],
      resourceName: "Launcher List",
    });

    onMounted(() => {
      fetchData();
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});

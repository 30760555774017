
import { defineComponent, ref } from "vue";
import { format } from "date-fns";

import { Page } from "@tager/admin-layout";
import { FieldValue } from "@tager/admin-ui";
import { Nullable, useResource } from "@tager/admin-services";

import DateRangeFilter, {
  DateRangeFilterSubmitEvent,
} from "@/components/DateRangeFilter.vue";
import {
  getUserStats,
  UserStatsResponse,
  UserStatsResponseBlock,
} from "@/modules/users/services";

import UsersStatsWidget from "./components/UsersStatsWidget.vue";

export default defineComponent({
  name: "ReviewsListView",
  components: {
    UsersStatsWidget,
    DateRangeFilter,
    Page,
    FieldValue,
  },
  setup() {
    const dateFrom = ref<Date | null>(null);
    const dateTo = ref<Date | null>(null);

    const [fetchStats, { data: statsData, loading: isStatsDataLoading }] =
      useResource<Nullable<UserStatsResponse>>({
        fetchResource: () =>
          getUserStats(
            dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
            dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : ""
          ),
        initialValue: null,
        resourceName: "Stats",
      });

    const onDateChange = (range: DateRangeFilterSubmitEvent) => {
      dateFrom.value = range.from;
      dateTo.value = range.to;

      fetchStats();
    };

    const numWithPercent = (value: undefined | number): string => {
      if (typeof value === "undefined") return "-";
      if (!statsData.value) return String(value);

      return (
        value + ` / ${((value / statsData.value.count) * 100).toFixed(2)} %`
      );
    };

    const getBlockParams = (data: UserStatsResponseBlock | undefined) => {
      return [
        {
          label: "30 days",
          value: numWithPercent(data ? data["30days"] : undefined),
        },
        {
          label: "All time",
          value: numWithPercent(data?.allTime),
        },
        {
          label: "D1",
          value: numWithPercent(data?.retention.d1),
        },
        {
          label: "D7",
          value: numWithPercent(data?.retention.d7),
        },
        {
          label: "D30",
          value: numWithPercent(data?.retention.d30),
        },
        {
          label: "M1",
          value: numWithPercent(data?.retention.m1),
        },
      ];
    };

    return {
      onDateChange,
      statsData,
      numWithPercent,
      getBlockParams,
      isLoading: isStatsDataLoading,
    };
  },
});

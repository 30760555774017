import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44af5dbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "relations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountButton = _resolveComponent("CountButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SouthIcon = _resolveComponent("SouthIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_NorthIcon = _resolveComponent("NorthIcon")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Genres",
    "header-buttons": [
      {
        text: 'Create genre',
        href: _ctx.getCategoriesCreateUrl(),
      },
    ]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        "cell(icon)": _withCtx(({ row }) => [
          (row.iconSymbol)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(row.iconSymbol), 1))
            : (row.iconFile)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: row.iconFile
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true)
        ]),
        "cell(relations)": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: {
              path: _ctx.getUsersListUrl(),
              query: { 'filter[category]': row.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CountButton, {
                  variant: "outline-secondary",
                  count: row.usersCount,
                  class: "margin-right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Users ")
                  ]),
                  _: 2
                }, 1032, ["count"])
              ]),
              _: 2
            }, 1032, ["to"]),
            _createVNode(_component_router_link, {
              to: {
              path: _ctx.getGamesListUrl(),
              query: { 'filter[category]': row.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CountButton, {
                  variant: "outline-secondary",
                  count: row.gamesCount,
                  class: "margin-right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Games ")
                  ]),
                  _: 2
                }, 1032, ["count"])
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        "cell(actions)": _withCtx(({ row, rowIndex }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            disabled: _ctx.isBusy(row.id) || rowIndex === _ctx.rowData.length - 1,
            onClick: ($event: any) => (_ctx.handleResourceMove(row.id, 'down'))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SouthIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            disabled: _ctx.isBusy(row.id) || rowIndex === 0,
            onClick: ($event: any) => (_ctx.handleResourceMove(row.id, 'up'))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NorthIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Редактировать",
            disabled: _ctx.isBusy(row.id),
            href: _ctx.getCategoriesUpdateUrl(row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "href"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Удалить",
            disabled: _ctx.isBusy(row.id),
            onClick: ($event: any) => (_ctx.handleResourceDelete(row.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}

import { defineComponent } from "vue";

import { BaseButton, DataTable, DeleteIcon, EditIcon } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete } from "@tager/admin-services";

import {
  getCallToActionsCreateUrl,
  getCallToActionsUpdateUrl,
} from "@/utils/paths";

import { deleteCallToAction, getCallToActionsList } from "../services";
import { CallToActionInterface } from "../typings";

import { COLUMN_DEFS } from "./CallToActionsListView.helpers";

export default defineComponent({
  name: "CallToActionsListView",
  components: {
    DeleteIcon,
    EditIcon,
    BaseButton,
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchData,
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<CallToActionInterface>({
      fetchEntityList: (params) =>
        getCallToActionsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "CTA List",
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteCallToAction,
      resourceName: "Category",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      handleResourceDelete,
      getCallToActionsCreateUrl,
      getCallToActionsUpdateUrl,
    };
  },
});

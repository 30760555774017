import { ColumnDefinition, OptionType } from "@tager/admin-ui";

import { getDevelopersUpdateUrl } from "@/utils/paths";

import { DeveloperInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<DeveloperInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "70px",
  },
  {
    id: 2,
    name: "Name",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getDevelopersUpdateUrl(row.id),
      },
      websiteLink: {
        text: row.url,
        url: row.url,
      },
    }),
    options: {
      displayFullWebsiteLink: true,
    },
  },
  {
    id: 2,
    name: "Priority",
    field: "priority",
    width: "100px",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 2,
    name: "Icon",
    field: "icon",
    type: "image",
    width: "150px",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 4,
    name: "Games Android",
    field: "gamesAndroidCount",
    width: "150px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 5,
    name: "Games iOS",
    field: "gamesIosCount",
    width: "150px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 6,
    name: "Followers",
    field: "followersCount",
    width: "150px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 5,
    name: "",
    width: "105px",
    field: "actions",
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "priority_desc",
    label: "Priority",
  },
  {
    value: "games_ios_desc",
    label: "Games - iOS",
  },
  {
    value: "games_android_desc",
    label: "Games - Android",
  },
  {
    value: "followers_desc",
    label: "Followers",
  },
];

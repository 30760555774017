
import { defineComponent } from "vue";

import {
  BaseButton,
  DataTable,
  EditIcon,
  ContentCopyIcon,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceClone } from "@tager/admin-services";

import { getPollsUpdateUrl, getPollsCreateUrl } from "@/utils/paths";
import { clonePoll, getPollsList } from "@/modules/polls/services";

import { PollFullInterface, PollInterface } from "../typings";

import { COLUMN_DEFS } from "./PollsListView.helpers";

export default defineComponent({
  name: "PollsListView",
  components: {
    EditIcon,
    BaseButton,
    ContentCopyIcon,
    Page,
    DataTable,
  },
  setup() {
    const {
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<PollInterface>({
      fetchEntityList: (params) =>
        getPollsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Polls List",
    });

    const { isCloning, handleResourceClone } = useResourceClone({
      cloneResource: clonePoll,
      confirmMessage: "Are you sure?",
      successMessage: "Poll is successfully cloned",
      failureMessage: "Clone failure",
      onSuccessRedirectTo: (data: PollFullInterface) => "/polls/" + data.id,
    });

    function isBusy(id: number): boolean {
      return isCloning(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getPollsUpdateUrl,
      getPollsCreateUrl,
      isBusy,
      handleResourceClone,
    };
  },
});

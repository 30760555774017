import { OptionType } from "@tager/admin-ui";

import { GameCreatePayload } from "../typings";

export type FormValues = {
  name: string;
  categories: Array<OptionType<number>>;
  tags: Array<OptionType<number>>;
};

export const defaultFormValues: FormValues = {
  name: "",
  categories: [],
  tags: [],
};

export function convertFormValuesToCreationPayload(
  values: FormValues
): GameCreatePayload {
  return {
    name: values.name,
    categories: values.categories.map((item) => item.value),
    tags: values.tags.map((item) => item.value),
  };
}

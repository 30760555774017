import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dbd7184"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Games Pre-Releases" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        sort: { options: _ctx.sortOptions, value: _ctx.sortValue },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.handleTagRemove
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.statusFilter,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statusFilter) = $event)),
                  name: "status",
                  label: "Status",
                  options: _ctx.statusFilterOptions
                }, null, 8, ["value", "options"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.platformFilter,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.platformFilter) = $event)),
                  name: "platform",
                  label: "Platform",
                  options: _ctx.platformFilterOptions
                }, null, 8, ["value", "options"])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_router_link, {
            to: {
              path: '/users/push',
              query: { 'pre-release': row.id },
            }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseButton, {
                variant: "outline-secondary",
                class: "margin-right"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Send Push ")
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "sort", "onChange"])
    ]),
    _: 1
  }))
}
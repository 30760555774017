import { PollsCreatePayload } from "../typings";

export type FormValues = {
  name: string;
};

export const defaultFormValues: FormValues = {
  name: "",
};

export function convertFormValuesToCreationPayload(
  values: FormValues
): PollsCreatePayload {
  return {
    name: values.name,
  };
}

import {
  FileType,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import {
  GameCreatePayload,
  GameFullInterface,
  GameInterface,
  GameUpdatePayload,
} from "@/modules/games/typings";
import { CategoryFullInterface } from "@/modules/categories/typings";

import {
  DeveloperFullInterface,
  DeveloperInterface,
  SaveDeveloperPayload,
} from "./typings";

export interface CountData {
  count: number;
}

export function getDevelopersCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/developers/count" });
}

export function getDevelopersList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: Nullable<string>;
  view?: Nullable<string>;
}): Promise<ResponseBody<Array<DeveloperInterface>>> {
  return request.get({ path: "/admin/developers", params });
}

export function getDeveloper(
  id: string | number
): Promise<ResponseBody<DeveloperFullInterface>> {
  return request.get({ path: `/admin/developers/${id}` });
}

export function createDeveloper(
  payload: SaveDeveloperPayload
): Promise<ResponseBody<DeveloperFullInterface>> {
  return request.post({
    path: `/admin/developers`,
    body: payload,
  });
}

export function updateDeveloper(
  id: number | string,
  payload: SaveDeveloperPayload
): Promise<ResponseBody<DeveloperFullInterface>> {
  return request.put({
    path: `/admin/developers/${id}`,
    body: payload,
  });
}

export function mergeDevelopers(
  from: number,
  to: number
): Promise<ResponseBody> {
  return request.post({
    path: `/admin/developers/merge`,
    body: { from, to },
  });
}

export function deleteDeveloper(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/developers/${id}` });
}

import { ColumnDefinition, OptionType } from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import { GamePreReleaseInterface } from "@/modules/games/typings";
import { getGamesUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<GamePreReleaseInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px" },
    headStyle: { width: "50px" },
  },
  {
    id: 2,
    field: "platform",
    name: "Platform",
    width: "110px",
  },
  {
    id: 3,
    field: "subscriptionsCount",
    name: "Subscriptions",
    width: "120px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
    format: ({ row }) => row.subscriptionsCount.toString(),
  },
  {
    id: 3,
    field: "visible",
    name: "Visible",
    type: "boolean",
    view: "TICK",
    width: "100px",
  },
  {
    id: 5,
    field: "icon",
    name: "Icon",
    type: "image",
    width: "150px",
    headStyle: { textAlign: "center" },
  },
  {
    id: 6,
    field: "releaseDate",
    name: "Release Date",
    width: "150px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 7,
    name: "Name",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getGamesUpdateUrl(row.gameId),
      },
      websiteLink: row.storeUrl
        ? {
            text: row.storeUrl,
            url: row.storeUrl,
          }
        : null,
      paramList: [
        row.developer
          ? { name: "Developer", value: String(row.developer) }
          : null,
      ].filter(isNotNullish),
    }),
    options: {
      displayFullWebsiteLink: true,
    },
  },
  {
    id: 9,
    name: "",
    field: "actions",
    width: "130px",
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "created_at_desc",
    label: "Date added - Newest to oldest",
  },
  {
    value: "first_release_asc",
    label: "Release - Oldest to newest",
  },
  {
    value: "first_release_desc",
    label: "Release - Newest to oldest",
  },
];

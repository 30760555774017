import {
  BaseOptionType,
  OptionType,
  SingleFileInputValueType,
} from "@tager/admin-ui";
import { Nullable } from "@tager/admin-services";
import { FieldConfigUnion } from "@tager/admin-dynamic-field/dist/typings/model";
import { RepeaterField } from "@tager/admin-dynamic-field";

import { SendUserPushRequest } from "@/modules/users/services";

export enum RecipientType {
  User = "user",
  UserFollowers = "user-follower",
  PreReleaseSubscriptions = "pre-release",
}

export const recipientTypeOptions: Array<OptionType<RecipientType>> = [
  { value: RecipientType.User, label: "User" },
  { value: RecipientType.UserFollowers, label: "User Followers" },
  {
    value: RecipientType.PreReleaseSubscriptions,
    label: "Pre-Release Subscriptions",
  },
];

export type FormValues = {
  recipientType: OptionType<RecipientType>;
  preRelease: Nullable<OptionType<number>>;
  user: Nullable<OptionType<number>>;
  titleEn?: string;
  titleDe?: string;
  titleFr?: string;
  titleEs?: string;
  titleIt?: string;
  titlePt?: string;
  titlePtBr?: string;

  textEn?: string;
  textDe?: string;
  textFr?: string;
  textEs?: string;
  textIt?: string;
  textPt?: string;
  textPtBr?: string;

  icon: Nullable<SingleFileInputValueType>;
  amplitudeEventJSON: string;
  actionJSON: string;
};

export const getInitialFormValues = (): FormValues => {
  return {
    recipientType: recipientTypeOptions[0],
    user: null,
    preRelease: null,
    amplitudeEventJSON: "",
    actionJSON: "",
    icon: null,
  };
};

export function convertFormValuesToPayload(
  formValues: FormValues,
  preReleases: RepeaterField
): SendUserPushRequest {
  return {
    type: formValues.recipientType.value,
    user:
      formValues.recipientType.value == RecipientType.User
        ? formValues.user?.value || null
        : null,

    preReleases: preReleases.value.map((item) => {
      return +((item.value[0].value as BaseOptionType)?.value ?? 0);
    }),

    titleEn: formValues.titleEn || "",
    titleDe: formValues.titleDe || "",
    titleFr: formValues.titleFr || "",
    titleEs: formValues.titleEs || "",
    titleIt: formValues.titleIt || "",
    titlePt: formValues.titlePt || "",
    titlePtBr: formValues.titlePtBr || "",

    textEn: formValues.textEn || "",
    textDe: formValues.textDe || "",
    textFr: formValues.textFr || "",
    textEs: formValues.textEs || "",
    textIt: formValues.textIt || "",
    textPt: formValues.textPt || "",
    textPtBr: formValues.textPtBr || "",

    icon: formValues.icon?.file?.id || null,
    amplitudeEventJSON: formValues.amplitudeEventJSON || "",
    actionJSON: formValues.actionJSON || "",
  };
}

export const PRERELEASES_FIELD_REPEATER_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "PreReleases",
  meta: {
    view: "TABLE",
    noToggle: true,
    isEmptyHidden: true,
  },
  fields: [
    {
      name: "id",
      type: "AJAX_SELECT",
      label: "Game",
      meta: {
        requestUrl: "/admin/games/platforms/ajax-search",
      },
    },
  ],
};

import { ColumnDefinition } from "@tager/admin-ui";

import { callToActionTypesOptions } from "@/modules/ctas/constants";

import { CallToActionInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<CallToActionInterface>> = [
  {
    id: 1,
    name: "Name",
    field: "name",
  },
  {
    id: 2,
    name: "Type",
    field: "string",
    format: ({ row }) => {
      return (
        callToActionTypesOptions.find((item) => item.value === row.type)
          ?.label || "Unknown"
      );
    },
  },
  {
    id: 3,
    name: "Actions",
    field: "stats",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
    width: "120px",
    format: ({ row }) => row.actionsCompletedCount + " / " + row.actionsCount,
  },
  {
    id: 3,
    name: "",
    field: "actions",
    width: "120px",
  },
];

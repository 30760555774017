import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c4295e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "store-list" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldUrlAliasInput = _resolveComponent("FormFieldUrlAliasInput")!
  const _component_FormFieldRichTextInput = _resolveComponent("FormFieldRichTextInput")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_DevelopersGamesTable = _resolveComponent("DevelopersGamesTable")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tabId": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: _ctx.isCreation ? 'New Developer' : 'Update Developer',
    "is-content-loading": _ctx.isLoading,
    tabs: _ctx.isLoading ? [] : _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'general' ? 'block' : 'none' })
        }, [
          _createVNode(_component_FormField, {
            value: _ctx.values.priority,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.priority) = $event)),
            name: "priority",
            error: _ctx.errors.priority,
            label: "Priority"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.name,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.name) = $event)),
            name: "name",
            error: _ctx.errors.name,
            label: "Name"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.description,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.description) = $event)),
            name: "description",
            type: "textarea",
            label: "Description"
          }, null, 8, ["value"]),
          (_ctx.model?.alternativeNames.length > 0)
            ? (_openBlock(), _createBlock(_component_FieldValue, {
                key: 0,
                label: "Alternative Names",
                type: "list",
                value: _ctx.model?.alternativeNames.map((item) => item.name)
              }, {
                value: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model?.alternativeNames, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.name,
                        class: _normalizeClass(
                  _ctx.alternativeNamesDeleted.includes(item.name) ? '_deleted' : ''
                )
                      }, [
                        _createTextVNode(" - " + _toDisplayString(item.name) + " ", 1),
                        (!_ctx.alternativeNamesDeleted.includes(item.name))
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: "store-delete",
                              onClick: () => _ctx.deleteAltName(item.name)
                            }, " X ", 8, _hoisted_2))
                          : _createCommentVNode("", true)
                      ], 2))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["value"]))
            : _createCommentVNode("", true)
        ], 4),
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'web' ? 'block' : 'none' })
        }, [
          (!_ctx.isCreation)
            ? (_openBlock(), _createBlock(_component_FormFieldUrlAliasInput, {
                key: 0,
                value: _ctx.values.urlAlias,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.urlAlias) = $event)),
                "url-template": _ctx.websiteOrigin + '/developers/',
                label: "URL",
                error: _ctx.errors.urlAlias,
                name: "urlAlias"
              }, null, 8, ["value", "url-template", "error"]))
            : _createCommentVNode("", true),
          _createVNode(_component_FormField, {
            value: _ctx.values.webOverview,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.webOverview) = $event)),
            name: "webOverview",
            type: "textarea",
            label: "Overview"
          }, null, 8, ["value"]),
          _createVNode(_component_FormFieldRichTextInput, {
            value: _ctx.values.webOverviewFull,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.webOverviewFull) = $event)),
            name: "webOverviewFull",
            label: "Overview - Full"
          }, null, 8, ["value"])
        ], 4),
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'media' ? 'block' : 'none' })
        }, [
          _createVNode(_component_FormFieldFileInput, {
            value: _ctx.values.icon,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.icon) = $event)),
            error: _ctx.errors.icon,
            label: "Icon",
            name: "icon",
            "file-type": "image",
            scenario: "developer-icon-s3"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormFieldFileInput, {
            value: _ctx.values.cover,
            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.cover) = $event)),
            error: _ctx.errors.cover,
            label: "Cover",
            name: "cover",
            "file-type": "image",
            scenario: "developer-cover-s3"
          }, null, 8, ["value", "error"])
        ], 4),
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'games' ? 'block' : 'none' })
        }, [
          _createVNode(_component_FormFieldSelect, {
            value: _ctx.values.featuredGame,
            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.featuredGame) = $event)),
            error: _ctx.errors.featuredGame,
            label: "Featured Game",
            name: "featuredGame",
            options: _ctx.gamesOptions
          }, null, 8, ["value", "error", "options"]),
          _createVNode(_component_DevelopersGamesTable, {
            priorities: _ctx.gamesPriorities,
            "onUpdate:priorities": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.gamesPriorities) = $event)),
            games: _ctx.games
          }, null, 8, ["priorities", "games"])
        ], 4)
      ], 32)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}
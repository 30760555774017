import { ColumnDefinition } from "@tager/admin-ui";

import { UserPushNotificationInterface } from "../typings";

export const COLUMN_DEFS: Array<
  ColumnDefinition<UserPushNotificationInterface>
> = [
  {
    id: 0,
    name: "ID",
    field: "id",
    width: "60px",
    type: "link",
    format: (params) => {
      return {
        url: "/users/push-notifications/" + params.row.id,
        text: String(params.row.id),
      };
    },
    options: {
      disableCopyButton: true,
    },
  },
  {
    id: 1,
    name: "DateTime",
    field: "datetime",
    type: "datetime",
    width: "168px",
  },
  {
    id: 2,
    name: "Users",
    field: "usersCount",
    width: "90px",
    format: ({ row }) => String(row.usersCount),
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 2,
    name: "Sent",
    field: "usersSentCount",
    width: "90px",
    format: ({ row }) => String(row.usersSentCount),
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 2,
    name: "Success",
    field: "usersSuccessCount",
    format: ({ row }) => String(row.usersSuccessCount),
    width: "90px",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 2,
    name: "Failure",
    field: "usersFailureCount",
    format: ({ row }) => String(row.usersFailureCount),
    width: "90px",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 3,
    name: "Title",
    field: "title",
  },
  {
    id: 4,
    name: "Body",
    field: "body",
  },
  {
    id: 5,
    name: "Android Image",
    field: "iconAndroid",
    type: "image",
    width: "180px",
  },
  {
    id: 6,
    name: "iOS Image",
    field: "iconIos",
    type: "image",
    width: "180px",
  },
  {
    id: 7,
    name: "Payload",
    field: "payload",
    type: "json",
  },
];


import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  ColumnDefinition,
  DataTable,
  useDataTable,
} from "@tager/admin-ui";

import { getUserProfilePushNotifications } from "@/modules/users/services";
import { UserViewPushNotificationInterface } from "@/modules/users/typings";

export const COLUMN_DEFS: Array<
  ColumnDefinition<UserViewPushNotificationInterface>
> = [
  {
    id: 0,
    name: "ID",
    field: "id",
    width: "60px",
    type: "link",
    format: (params) => {
      return {
        url: "/users/push-notifications/" + params.row.id,
        text: String(params.row.id),
      };
    },
    options: {
      disableCopyButton: true,
    },
  },
  {
    id: 1,
    name: "Status",
    field: "status",
    width: "100px",
  },
  {
    id: 1,
    name: "DateTime",
    field: "datetime",
    type: "datetime",
    width: "168px",
  },
  {
    id: 3,
    name: "Title",
    field: "title",
  },
  {
    id: 4,
    name: "Body",
    field: "body",
  },
  {
    id: 7,
    name: "Payload",
    field: "pushPayload",
    type: "json",
  },
  {
    id: 8,
    name: "Devices",
    field: "payload",
    type: "json",
  },
];

export default defineComponent({
  name: "UsersViewPushNotificationsList",
  components: {
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserViewPushNotificationInterface>({
      fetchEntityList: () => getUserProfilePushNotifications(props.id),
      initialValue: [],
      resourceName: "Push Notifications List",
    });

    onMounted(() => {
      fetchData();
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});

import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { TagFullInterface } from "../typings";
import { TagCreateOrUpdatePayload } from "../services";

export type FormValues = {
  name: string;
  nameDe: string;
  nameEs: string;
  nameFr: string;
  nameIt: string;
  namePt: string;
  namePtBr: string;
  section: string;
  sectionDe: string;
  sectionEs: string;
  sectionFr: string;
  sectionIt: string;
  sectionPt: string;
  sectionPtBr: string;

  iconFile: Nullable<SingleFileInputValueType>;
  iconSymbol: string | null;

  igdbAliases: string;
  appMagicIds: Array<OptionType<number>>;
};

export function getTagFormValues(
  model: Nullable<TagFullInterface>,
  appMagicCategoryOptions: Array<OptionType<number>>
): FormValues {
  if (!model) {
    return {
      name: "",
      nameDe: "",
      nameEs: "",
      nameFr: "",
      nameIt: "",
      namePt: "",
      namePtBr: "",

      iconFile: null,
      iconSymbol: "",
      section: "",
      sectionDe: "",
      sectionEs: "",
      sectionFr: "",
      sectionIt: "",
      sectionPt: "",
      sectionPtBr: "",
      igdbAliases: "",
      appMagicIds: [],
    };
  }

  return {
    name: model.name || "",
    nameDe: model.nameDe || "",
    nameEs: model.nameEs || "",
    nameIt: model.nameIt || "",
    nameFr: model.nameFr || "",
    namePt: model.namePt || "",
    namePtBr: model.namePtBr || "",
    section: model.section || "",
    sectionDe: model.sectionDe || "",
    sectionEs: model.sectionEs || "",
    sectionIt: model.sectionIt || "",
    sectionFr: model.sectionFr || "",
    sectionPt: model.sectionPt || "",
    sectionPtBr: model.sectionPtBr || "",

    iconFile: model.iconFile ? { id: createId(), file: model.iconFile } : null,
    iconSymbol: model.iconSymbol,

    igdbAliases: model.igdbAliases || "",
    appMagicIds: appMagicCategoryOptions.filter((item) =>
      model.appMagicIds?.includes(item.value)
    ),
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): TagCreateOrUpdatePayload {
  return {
    name: values.name,
    nameDe: values.nameDe,
    nameEs: values.nameEs,
    nameIt: values.nameIt,
    nameFr: values.nameFr,
    namePt: values.namePt,
    namePtBr: values.namePtBr,
    section: values.section,
    sectionDe: values.sectionDe,
    sectionEs: values.sectionEs,
    sectionFr: values.sectionFr,
    sectionIt: values.sectionIt,
    sectionPt: values.sectionPt,
    sectionPtBr: values.sectionPtBr,
    iconFile: values.iconFile?.file?.id || null,
    iconSymbol: values.iconSymbol,
    igdbAliases: values.igdbAliases,
    appMagicIds: values.appMagicIds.map((item) => item.value),
  };
}

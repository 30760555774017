import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GamesListViewPlatform = _resolveComponent("GamesListViewPlatform")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "column-defs": _ctx.columnDefs,
    "row-data": _ctx.rowData,
    loading: _ctx.isRowDataLoading,
    "error-message": _ctx.errorMessage,
    "use-search": false
  }, {
    "cell(game)": _withCtx(({ row }) => [
      _createVNode(_component_GamesListViewPlatform, {
        platform: row.platform
      }, null, 8, ["platform"])
    ]),
    _: 1
  }, 8, ["column-defs", "row-data", "loading", "error-message"]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsWidget = _resolveComponent("StatsWidget")!

  return (_openBlock(), _createBlock(_component_StatsWidget, {
    title: _ctx.title,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.label
          }, [
            _createElementVNode("span", null, _toDisplayString(item.label), 1),
            _createElementVNode("span", null, _toDisplayString(item.value === null ? "" : item.value), 1)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "loading"]))
}
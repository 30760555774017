
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  DynamicField,
  RepeaterField,
  RepeaterIncomingValue,
} from "@tager/admin-dynamic-field";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldOptionsSwitcherTrueFalse,
  FormFieldAjaxSelect,
} from "@tager/admin-ui";
import { FieldUnion, universalFieldUtils } from "@tager/admin-dynamic-field";

import { getCampaignsListUrl, getCampaignsUpdateUrl } from "../links";
import { createCampaign, getCampaign, updateCampaign } from "../services";

import {
  FormValues,
  getFormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
  GAMES_FIELD_REPEATER_CONFIG,
  GAMELISTS_FIELD_REPEATER_CONFIG,
  REVIEWS_FIELD_REPEATER_CONFIG,
} from "./CampaignsFormView.helpers";

export default defineComponent({
  name: "CampaignsFormView",
  components: {
    Page,
    FormField,
    FormFieldOptionsSwitcherTrueFalse,
    FormFooter,
    FormFieldAjaxSelect,
    DynamicField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getCampaign(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Campaign",
    });

    onMounted(() => {
      fetchModel();
    });

    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getFormValues(model.value));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getFormValues(model.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
      updateGamesField();
      updateGamelistsField();
      updateReviewsField();
    });

    const gamesField = ref<FieldUnion>(
      universalFieldUtils.createFormField(GAMES_FIELD_REPEATER_CONFIG, null)
    );

    const gamelistsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(GAMELISTS_FIELD_REPEATER_CONFIG, null)
    );

    const reviewsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(REVIEWS_FIELD_REPEATER_CONFIG, null)
    );

    function updateGamesField() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.games
        ? model.value?.games.map((value) => {
            return [
              {
                name: "id",
                value,
              },
            ];
          })
        : [];

      gamesField.value = universalFieldUtils.createFormField(
        GAMES_FIELD_REPEATER_CONFIG,
        incomingFieldList
      ) as RepeaterField;
    }

    function updateGamelistsField() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.gamelists
        ? model.value?.gamelists.map((value) => {
            return [
              {
                name: "id",
                value,
              },
            ];
          })
        : [];

      gamelistsField.value = universalFieldUtils.createFormField(
        GAMELISTS_FIELD_REPEATER_CONFIG,
        incomingFieldList
      ) as RepeaterField;
    }

    function updateReviewsField() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.reviews
        ? model.value?.reviews.map((value) => {
            return [
              {
                name: "id",
                value,
              },
            ];
          })
        : [];

      reviewsField.value = universalFieldUtils.createFormField(
        REVIEWS_FIELD_REPEATER_CONFIG,
        incomingFieldList
      ) as RepeaterField;
    }

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(
          values.value,
          gamesField.value as RepeaterField,
          gamelistsField.value as RepeaterField,
          reviewsField.value as RepeaterField
        );

      const requestPromise = isCreation.value
        ? createCampaign(createOrUpdatePayload)
        : updateCampaign(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getCampaignsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getCampaignsListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Campaigns",
            body: isCreation.value
              ? "Campaign successfully created"
              : "Campaign successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Campaigns",
            body: isCreation.value
              ? "Error creation campaign"
              : "Error updating campaign",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(() => isModelLoading.value);

    return {
      isLoading,
      values,
      errors,
      model,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getCampaignsListUrl(),

      reviewsField,
      gamesField,
      gamelistsField,
    };
  },
  computed: {
    console: () => console,
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldOptionsSwitcherTrueFalse = _resolveComponent("FormFieldOptionsSwitcherTrueFalse")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Update Review",
    "is-content-loading": _ctx.isLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": false,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (_ctx.model)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_FieldValue, {
              label: "Date / Time",
              value: _ctx.model.datetime
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "User",
              value: `ID ${_ctx.model.user?.id} - ${_ctx.model.user?.name}`
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Game",
              value: `ID ${_ctx.model.game?.id} - ${_ctx.model.game?.name}`
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Rating",
              value: String(_ctx.model.rating)
            }, null, 8, ["value"]),
            _createVNode(_component_FormFieldOptionsSwitcherTrueFalse, {
              value: _ctx.values.displayWebHome,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.displayWebHome) = $event)),
              name: "displayWebHome",
              error: _ctx.errors.displayWebHome,
              label: "Display Web Home"
            }, null, 8, ["value", "error"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-content-loading"]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GamesListViewPlatform = _resolveComponent("GamesListViewPlatform")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "column-defs": _ctx.columnDefs,
    "row-data": _ctx.games,
    "use-search": false
  }, {
    "cell(android)": _withCtx(({ row }) => [
      (row.android)
        ? (_openBlock(), _createBlock(_component_GamesListViewPlatform, {
            key: 0,
            platform: row.android
          }, null, 8, ["platform"]))
        : _createCommentVNode("", true)
    ]),
    "cell(ios)": _withCtx(({ row }) => [
      (row.ios)
        ? (_openBlock(), _createBlock(_component_GamesListViewPlatform, {
            key: 0,
            platform: row.ios
          }, null, 8, ["platform"]))
        : _createCommentVNode("", true)
    ]),
    "cell(priority)": _withCtx(({ row }) => [
      _createVNode(_component_FormField, {
        "no-error-padding": true,
        style: { textAlign: 'center' },
        name: `developerPriority[${row.id}]`,
        type: "number",
        value: row.developerPriority,
        onChange: (value) => _ctx.onChange(row.id, value)
      }, null, 8, ["name", "value", "onChange"])
    ]),
    _: 1
  }, 8, ["column-defs", "row-data"]))
}

import { defineComponent } from "vue";

import { DataTable } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getDeletedPlaylists } from "@/modules/playlists/services";
import { getPlaylistsViewUrl } from "@/modules/playlists";
import TableCellUser from "@/components/TableCellUser.vue";

import { PlaylistDeletedInterface } from "../typings";

import { COLUMN_DEFS } from "./PlaylistDeletedListView.helpers";

export default defineComponent({
  name: "PlaylistsDeletedListView",
  components: {
    Page,
    DataTable,
    TableCellUser,
  },
  setup() {
    const {
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<PlaylistDeletedInterface>({
      fetchEntityList: (params) =>
        getDeletedPlaylists({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Playlists Deleted List",
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getPlaylistsViewUrl,
    };
  },
});

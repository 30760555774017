
import { defineComponent, PropType } from "vue";

type Props = {
  user: {
    id: number;
    username: string;
  };
};

export default defineComponent({
  name: "CategoriesFormView",
  props: {
    user: {
      type: Object as PropType<Props["user"]>,
    },
  },
});

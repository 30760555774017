import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getPlaylistsListUrl() {
  return ROUTE_PATHS.PLAYLISTS_LIST;
}

export function getPlaylistsCreateUrl() {
  return ROUTE_PATHS.PLAYLISTS_CREATE;
}

export function getPlaylistsViewUrl(id: number) {
  return compile(ROUTE_PATHS.PLAYLISTS_FORM)({ id: id });
}

import { ColumnDefinition, OptionType } from "@tager/admin-ui";

import { PlaylistInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<PlaylistInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "50px",
    style: {
      textAlign: "center",
    },
    headStyle: {
      textAlign: "center",
    },
    type: "link",
    options: {
      shouldOpenNewTab: false,
      disableCopyButton: true,
    },
    format: ({ row, column }) => {
      return {
        url: "/playlists/" + row.id,
        text: String(row.id),
      };
    },
  },
  {
    id: 2,
    name: "Android Image",
    field: "androidImage",
    width: "160px",
    type: "image",
  },
  {
    id: 2,
    name: "iOS Image",
    field: "iosImage",
    width: "160px",
    type: "image",
  },
  {
    id: 7,
    name: "Stats",
    field: "stats",
    type: "key-value",
    format: ({ row }) => {
      return row.stats
        ? [
            { key: "Games", value: String(row.stats.games) },
            { key: "Likes", value: String(row.stats.likes) },
            { key: "Views", value: String(row.stats.views) },
            { key: "Downloads", value: String(row.stats.downloads) },
          ]
        : [];
    },
    options: {
      view: "table",
    },
    width: "200px",
  },
  {
    id: 2,
    name: "Featured",
    field: "featured",
    width: "220px",
    style: {
      textAlign: "center",
    },
    headStyle: {
      textAlign: "center",
    },
  },
  {
    id: 3,
    name: "Weekly",
    field: "weekly",
    width: "80px",
    type: "boolean",
    view: "TICK",
    style: {
      textAlign: "center",
    },
    headStyle: {
      textAlign: "center",
    },
  },
  {
    id: 3,
    name: "Name",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: null,
      },
      websiteLink: row.webAvailable
        ? {
            text: row.webUrl,
            url: row.webUrl,
          }
        : null,
    }),
    options: {
      displayFullWebsiteLink: true,
    },
  },
  {
    id: 4,
    name: "User",
    field: "user",
  },
  {
    id: 2,
    name: "Updated",
    field: "updatedAt",
    type: "datetime",
    width: "165px",
  },
  {
    id: 6,
    name: "",
    field: "actions",
    headStyle: { width: "40px", textAlign: "center", whiteSpace: "nowrap" },
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "priority",
    label: "Featured Position",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "games",
    label: "Games",
  },
  {
    value: "likes",
    label: "Likes",
  },
  {
    value: "views",
    label: "Views",
  },
  {
    value: "downloads",
    label: "Downloads",
  },
];

import { ColumnDefinition } from "@tager/admin-ui";

import { getUsersListUrl } from "@/utils/paths";

import { CampaignInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<CampaignInterface>> = [
  {
    id: 1,
    name: "Name",
    type: "string",
    field: "name",
  },
  {
    id: 2,
    name: "Public URL",
    type: "link",
    field: "publicUrl",
  },
  {
    id: 2,
    name: "Download URL",
    type: "link",
    field: "downloadUrl",
  },
  {
    id: 3,
    name: "Active",
    type: "boolean",
    field: "active",
    width: "120px",
  },
  {
    id: 4,
    name: "Developer",
    field: "developer",
    width: "200px",
    format: ({ row }) => row.developer?.name || "-",
  },
  {
    id: 5,
    name: "",
    type: "relations",
    field: "relations",
    format: ({ row }) => [
      {
        label: "Users",
        quantity: row.usersCount,
        to: {
          path: getUsersListUrl(),
          query: { "filter[campaign]": row.id },
        },
      },
    ],
  },
  {
    id: 5,
    name: "",
    field: "actions",
    width: "80px",
  },
];

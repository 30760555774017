import { ColumnDefinition, OptionType } from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import { GameInterface } from "@/modules/games/typings";
import { getGamesUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<GameInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px" },
    headStyle: { width: "50px" },
  },
  {
    id: 2,
    name: "Name",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getGamesUpdateUrl(row.id),
      },
      websiteLink: {
        text: row.webUrl,
        url: row.webUrl,
      },
      paramList: [
        { name: "IGDB Rating", value: String(row.igdbRating || "-") },
        {
          name: "Display Onboarding",
          value: row.displayOnOnboarding ? "Yes" : "No",
        },
        { name: "Skipped", value: String(row.stats.skipped || 0) },
        { name: "Wanted", value: String(row.stats.wanted || 0) },
        { name: "Played", value: String(row.stats.played || 0) },
        { name: "Downloaded", value: String(row.stats.downloaded || 0) },
        { name: "Reviews", value: String(row.stats.reviews || 0) },
      ].filter(isNotNullish),
    }),
    options: {
      displayFullWebsiteLink: true,
    },
  },
  {
    id: 3,
    name: "Android",
    field: "android",
    headStyle: { width: "200px", textAlign: "center" },
  },
  {
    id: 4,
    name: "iOS",
    field: "ios",
    headStyle: { width: "200px", textAlign: "center" },
  },
  {
    id: 5,
    name: "Taxonomies",
    field: "taxonomies",
    type: "key-value",
    headStyle: { width: "300px" },
    format: ({ row }) => {
      return [
        row.categories.length
          ? { key: "Genres", value: row.categories.join(", ") }
          : null,
        row.tags.length
          ? { key: "Categories", value: row.tags.join(", ") }
          : null,
      ].filter(isNotNullish);
    },
  },
  {
    id: 6,
    name: "",
    field: "actions",
    width: "105px",
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "created_at_desc",
    label: "Created - Newest to oldest",
  },
  {
    value: "updated_at_desc",
    label: "Updated - Newest to oldest",
  },
  {
    value: "wanted_desc",
    label: "Wanted",
  },
  {
    value: "played_desc",
    label: "Played",
  },
  {
    value: "reviews_desc",
    label: "Reviews",
  },
  {
    value: "rating_desc",
    label: "Rating - Highest to lowest",
  },
  {
    value: "first_release_asc",
    label: "First Release - Oldest to newest",
  },
  {
    value: "first_release_desc",
    label: "First Release - Newest to oldest",
  },
];

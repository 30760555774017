import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getCategoriesListUrl() {
  return ROUTE_PATHS.CATEGORIES_LIST;
}

export function getCategoriesCreateUrl() {
  return compile(ROUTE_PATHS.CATEGORIES_FORM)({ id: "create" });
}

export function getCategoriesUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.CATEGORIES_FORM)({ id: id });
}

export function getTagsListUrl() {
  return ROUTE_PATHS.TAGS_LIST;
}

export function getTagsCreateUrl() {
  return compile(ROUTE_PATHS.TAGS_FORM)({ id: "create" });
}

export function getTagsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.TAGS_FORM)({ id: id });
}

export function getGamesListUrl() {
  return ROUTE_PATHS.GAMES_LIST;
}

export function getGamesCreateUrl() {
  return compile(ROUTE_PATHS.GAMES_CREATE)();
}

export function getGamesMergeUrl() {
  return compile(ROUTE_PATHS.GAMES_MERGE)();
}

export function getGamesUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.GAMES_FORM)({ id: id });
}

export function getPollsListUrl() {
  return ROUTE_PATHS.POLLS_LIST;
}

export function getPollsCreateUrl() {
  return compile(ROUTE_PATHS.POLLS_CREATE)();
}

export function getPollsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.POLLS_FORM)({ id: id });
}

export function getUsersListUrl() {
  return ROUTE_PATHS.USERS_LIST;
}

export function getUsersUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.USERS_FORM)({ id: id });
}

export function getCallToActionsListUrl() {
  return ROUTE_PATHS.CTAS_LIST;
}

export function getCallToActionsCreateUrl() {
  return compile(ROUTE_PATHS.CTAS_FORM)({ id: "create" });
}

export function getCallToActionsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.CTAS_FORM)({ id: id });
}

export function getUsersSendPushUrl() {
  return ROUTE_PATHS.USERS_PUSH;
}

export function getUsersStatsUrl() {
  return ROUTE_PATHS.USERS_STATS;
}

export function getDevelopersListUrl() {
  return ROUTE_PATHS.GAMES_LIST;
}

export function getDevelopersCreateUrl() {
  return compile(ROUTE_PATHS.DEVELOPERS_CREATE)();
}

export function getDevelopersMergeUrl() {
  return compile(ROUTE_PATHS.DEVELOPERS_MERGE)();
}

export function getDevelopersUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.DEVELOPERS_FORM)({ id: id });
}

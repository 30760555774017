import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Followings" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, null, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }))
}

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { Page } from "@tager/admin-layout";
import {
  OptionType,
  useDataTable,
  FormFieldAjaxSelect,
  FormFooter,
} from "@tager/admin-ui";
import { Nullable, useToast } from "@tager/admin-services";

import { DeveloperInterface } from "@/modules/developers/typings";
import { getDevelopersList } from "@/modules/developers";
import { mergeDevelopers } from "@/modules/developers/services";
import { getDevelopersListUrl, getDevelopersUpdateUrl } from "@/utils/paths";

type FormValues = {
  mergeFrom: Nullable<OptionType<number>>;
  mergeTo: Nullable<OptionType<number>>;
};
export default defineComponent({
  name: "DevelopersMergeView",
  components: {
    Page,
    FormFieldAjaxSelect,
    FormFooter,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const values = ref<FormValues>({
      mergeFrom: null,
      mergeTo: null,
    });

    const isSubmitting = ref<boolean>(false);

    const developerToDisabled = computed<boolean>(() => {
      return !values.value.mergeFrom?.value;
    });

    watch(
      () => values.value.mergeFrom,
      () => {
        if (
          values.value.mergeFrom &&
          values.value.mergeTo &&
          values.value.mergeFrom.value === values.value.mergeTo.value
        ) {
          values.value.mergeTo = null;
        }
      }
    );

    const submitButtonEnabled = computed<boolean>(
      () => !!values.value.mergeTo?.value && !!values.value.mergeFrom?.value
    );

    const submitForm = async () => {
      isSubmitting.value = true;

      try {
        if (values.value.mergeFrom && values.value.mergeTo) {
          await mergeDevelopers(
            values.value.mergeFrom.value,
            values.value.mergeTo.value
          );
          toast.show({
            variant: "success",
            title: "Developers",
            body: "Merge success",
          });
          await router.push(getDevelopersUpdateUrl(values.value.mergeTo.value));
        }
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Developers",
          body: "Error merge developers",
        });
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      backUrl: getDevelopersListUrl(),
      values,
      developerToDisabled,
      submitButtonEnabled,
      isSubmitting,
      submitForm,
    };
  },
});

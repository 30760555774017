import { OptionType } from "@tager/admin-ui";
import { Nullable } from "@tager/admin-services";

import { UserUpdatePayload } from "@/modules/users/services";

import { UserFullInterface } from "../typings";

export type FormValues = {
  priority: number;
  emailAuthCode: Nullable<string>;
  emailAuthSendDisabled: boolean;
  categories: Array<OptionType<number>>;
  tags: Array<OptionType<number>>;
  featureFlags: Array<OptionType<string>>;
  campaign: OptionType<number | null>;
};

export function getUserFormValues(
  model: UserFullInterface,
  categoryList: OptionType<number>[],
  tagList: OptionType<number>[],
  featureFlags: OptionType[],
  campaigns: OptionType<number | null>[]
): FormValues {
  const foundCategories = categoryList.filter((item) => {
    return model.categories.map((item) => item.id).includes(item.value);
  });

  const foundTags = tagList.filter((item) => {
    return model.tags.map((item) => item.id).includes(item.value);
  });

  const foundFeatureFlags = model
    ? featureFlags.filter((item) => {
        return model.featureFlags.includes(item.value);
      })
    : [];

  return {
    priority: model ? model.priority : 0,
    emailAuthSendDisabled: model ? model.emailAuthDisableSend : false,
    emailAuthCode: model ? model.emailAuthCode : null,
    categories: foundCategories,
    tags: foundTags,
    featureFlags: foundFeatureFlags,
    campaign:
      campaigns.find((item) => item.value === model?.campaign?.id) ??
      campaigns[0],
  };
}

export function convertFormValuesToCreationOrUpdatePayload(
  values: FormValues
): UserUpdatePayload {
  return {
    priority: values.priority,
    emailAuthCode: values.emailAuthCode,
    emailAuthDisableSend: values.emailAuthSendDisabled,
    categories: values.categories.map((item) => item.value),
    tags: values.tags.map((item) => item.value),
    featureFlags: values.featureFlags.map((item) => item.value),
    campaign: values.campaign?.value,
  };
}

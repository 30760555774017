
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  AdvancedSearch,
  BaseButton,
  DataTable,
  DeleteIcon,
  EditIcon,
  FilterTagType,
  FormField,
  FormFieldMultiSelect,
  FormFieldSelect,
  getFilterParamAsString,
  getFilterParamAsStringArray,
  getFilterParams,
  OptionType,
  useDataTable,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  isNotNullish,
  useResource,
  useResourceDelete,
} from "@tager/admin-services";

import { getGamesCreateUrl, getGamesUpdateUrl } from "@/utils/paths";
import { deleteGame, getGames } from "@/modules/games/services";
import { GameInterface } from "@/modules/games/typings";
import { getCategoriesList } from "@/modules/categories/services";
import { getTagsList } from "@/modules/tags/services";

import { COLUMN_DEFS, SORT_OPTIONS } from "./GamesListView.helpers";
import GamesListViewPlatform from "./components/GamesListViewPlatform.vue";

export default defineComponent({
  name: "GamesListView",
  components: {
    GamesListViewPlatform,
    FormFieldSelect,
    FormField,
    EditIcon,
    BaseButton,
    FormFieldMultiSelect,
    AdvancedSearch,
    Page,
    DataTable,
    DeleteIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      fetchEntityList: fetchEntityList,
      isLoading: isGamesLoading,
      rowData: gamesData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<GameInterface>({
      fetchEntityList: (params) =>
        getGames({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Games List",
      defaultSort: SORT_OPTIONS[0].value,
    });

    const [
      fetchCategoryList,
      { data: categoryList, loading: isCategoryListLoading },
    ] = useResource({
      fetchResource: () => getCategoriesList(),
      initialValue: [],
      resourceName: "Categories list",
    });

    const [fetchTagList, { data: tagList, loading: isTagListLoading }] =
      useResource({
        fetchResource: () => getTagsList(),
        initialValue: [],
        resourceName: "Tag list",
      });

    const categoryOptionList = computed(() =>
      categoryList.value.map<OptionType>((category) => ({
        value: String(category.id),
        label: category.name,
      }))
    );

    const tagOptionList = computed(() =>
      tagList.value.map<OptionType>((tag) => ({
        value: String(tag.id),
        label: tag.name,
      }))
    );

    const initialCategoryFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "category");
      return categoryOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const categoryFilter = ref<Array<OptionType>>(initialCategoryFilter.value);
    watch(initialCategoryFilter, () => {
      categoryFilter.value = initialCategoryFilter.value;
    });

    const initialTagFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "tag");
      return tagOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const tagFilter = ref<Array<OptionType>>(initialTagFilter.value);
    watch(initialTagFilter, () => {
      tagFilter.value = initialTagFilter.value;
    });

    const storesFilterOptions: Array<OptionType> = [
      { label: "All", value: "" },
      { label: "With Stores", value: "with_stores" },
      { label: "With Android", value: "android" },
      { label: "With iOS", value: "ios" },
      { label: "Without Stores", value: "no_stores" },
    ];

    const initialStoresFilter = computed<string>(() => {
      return getFilterParamAsString(route.query, "stores") || "";
    });
    watch(initialStoresFilter, () => {
      storesFilter.value =
        storesFilterOptions.find(
          (item) => item.value === initialStoresFilter.value
        ) || null;
    });
    const storesFilter = ref<OptionType | null>();

    const discoveryFilterOptions: Array<OptionType> = [
      { label: "All", value: "" },
      { label: "Hidden", value: "hidden" },
      { label: "Available", value: "available" },
    ];

    const initialDiscoveryFilter = computed<string>(() => {
      return getFilterParamAsString(route.query, "discovery") || "";
    });
    watch(initialDiscoveryFilter, () => {
      discoveryFilter.value =
        discoveryFilterOptions.find(
          (item) => item.value === initialDiscoveryFilter.value
        ) || null;
    });
    const discoveryFilter = ref<OptionType | null>();

    const initialDeveloperFilter = computed<string>(() => {
      return getFilterParamAsString(route.query, "developer") || "";
    });
    watch(initialDeveloperFilter, () => {
      developerFilter.value = initialDeveloperFilter.value;
    });
    const developerFilter = ref<string>(initialDeveloperFilter.value);

    const filterParams = computed(() => {
      return getFilterParams({
        category: categoryFilter.value.map((category) => category.value),
        tag: tagFilter.value.map((tag) => tag.value),
        stores: storesFilter.value ? storesFilter.value.value : "",
        discovery: discoveryFilter.value ? discoveryFilter.value.value : "",
        developer: developerFilter.value ? developerFilter.value : "",
      });
    });

    watch(filterParams, () => {
      if (isTagListLoading.value || isCategoryListLoading.value) return;

      const newQuery = {
        ...pick(route.query, ["query", "pageNumber", "sort"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchEntityList();
      }
    });

    onMounted(() => {
      fetchEntityList();
      fetchCategoryList();
      fetchTagList();
    });

    const isDataLoading = computed<boolean>(
      () =>
        isGamesLoading.value ||
        isCategoryListLoading.value ||
        isTagListLoading.value
    );

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "category") {
        categoryFilter.value = categoryFilter.value.filter(
          (category) => category.value !== event.value
        );
      }
      if (event.name === "tag") {
        tagFilter.value = tagFilter.value.filter(
          (tag) => tag.value !== event.value
        );
      }

      if (event.name === "stores") {
        storesFilter.value =
          storesFilterOptions.find((item) => item.value === event.value) ||
          storesFilterOptions[0];
      }

      if (event.name === "stores") {
        discoveryFilter.value =
          discoveryFilterOptions.find((item) => item.value === event.value) ||
          discoveryFilterOptions[0];
      }

      if (event.name === "developer") {
        developerFilter.value = "";
      }
    }

    const tags = computed<Array<FilterTagType>>(() =>
      [
        ...categoryFilter.value.map((category) => ({
          value: category.value,
          label: category.label,
          name: "category",
          title: "Category",
        })),
        ...tagFilter.value.map((tag) => ({
          value: tag.value,
          label: tag.label,
          name: "tag",
          title: "Tag",
        })),
        developerFilter.value
          ? {
              value: String(developerFilter.value),
              label: String(developerFilter.value),
              name: "developer",
              title: "Developer",
            }
          : null,

        storesFilter.value && storesFilter.value.value
          ? {
              value: String(storesFilter.value.value),
              label: storesFilter.value.label,
              name: "stores",
              title: "Stores",
            }
          : null,

        discoveryFilter.value && discoveryFilter.value.value
          ? {
              value: String(discoveryFilter.value.value),
              label: discoveryFilter.value.label,
              name: "discovery",
              title: "Discovery",
            }
          : null,
      ].filter(isNotNullish)
    );

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteGame,
      resourceName: "Game",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      sortOptions: SORT_OPTIONS,
      sortValue: sort,
      rowData: gamesData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      getGamesUpdateUrl,
      getGamesCreateUrl,

      categoryOptionList,
      tagOptionList,
      tags,
      handleTagRemove,

      handleResourceDelete,

      categoryFilter,
      tagFilter,
      storesFilterOptions,
      storesFilter,
      discoveryFilterOptions,
      discoveryFilter,
      developerFilter,

      isBusy,
    };
  },
});


import { defineComponent, PropType } from "vue";

import { FieldValue } from "@tager/admin-ui";

import { UserFullInterface } from "@/modules/users/typings";

export default defineComponent({
  name: "UsersViewStats",
  components: {
    FieldValue,
  },
  props: {
    model: {
      type: Object as PropType<UserFullInterface>,
    },
  },
  setup() {
    return {};
  },
});

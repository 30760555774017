
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useResourceMove,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  type TabType,
  FieldValue,
  useDataTable,
  FormField,
  FormFieldCheckbox,
  FormFooter,
  TagerFormSubmitEvent,
  FormFieldUrlAliasInput,
  FormFieldOptionsSwitcherTrueFalse,
} from "@tager/admin-ui";
import {
  FieldUnion,
  universalFieldUtils,
  DynamicField,
  RepeaterIncomingValue,
  RepeaterField,
} from "@tager/admin-dynamic-field";

import { getPlaylistsListUrl } from "@/modules/playlists";
import TableCellUser from "@/components/TableCellUser.vue";
import { PlaylistGameInterface } from "@/modules/playlists/typings";
import {
  fieldWebFaqConfig,
  fieldWebTextBlocksConfig,
} from "@/modules/games/GamesUpdateView/GamesUpdateView.helpers";

import {
  getPlaylist,
  getPlaylistGames,
  movePlaylistGame,
  updatePlaylist,
} from "../services";

import {
  convertPageFormValuesToCreationOrUpdatePayload,
  FormValues,
  getCategoryFormValues,
  playlistGamesDynamicFieldConfig,
} from "./PlaylistUpdateView.helpers";

export default defineComponent({
  name: "PlaylistUpdateView",
  components: {
    FormFieldUrlAliasInput,
    Page,
    FieldValue,
    TableCellUser,
    FormFooter,
    DynamicField,

    FormField,
    FormFieldCheckbox,
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const router = useRouter();

    const id = computed<number>(() => +(route.params.id as string));

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => getPlaylist(id.value),
      initialValue: null,
      resourceName: "Playlist",
    });

    const {
      fetchEntityList: fetchGames,
      isLoading: isGamesLoading,
      rowData: games,
    } = useDataTable<PlaylistGameInterface>({
      fetchEntityList: () => getPlaylistGames(id.value),
      initialValue: [],
      resourceName: "Playlist Games",
    });

    onMounted(() => {
      fetchModel();
      fetchGames();
    });
    watch(id, () => {
      if (id.value) {
        fetchModel();
        fetchGames();
      }
    });

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isGamesLoading.value
    );

    const tabs: Array<TabType> = [
      {
        id: "general",
        label: "General",
      },
      {
        id: "display",
        label: "Display",
      },
      {
        id: "web",
        label: "Web",
      },
      {
        id: "games",
        label: "Games",
      },
      {
        id: "stats",
        label: "Stats",
      },
    ];

    const selectedTabId = ref<string>(tabs[0].id);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getCategoryFormValues(model.value));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getCategoryFormValues(model.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
      updateWebFaq();
      updateWebTextBlocks();
    });

    const webFaq = ref<FieldUnion>(
      universalFieldUtils.createFormField(fieldWebFaqConfig, null)
    );

    const webTextBlocks = ref<FieldUnion>(
      universalFieldUtils.createFormField(fieldWebTextBlocksConfig, null)
    );

    function updateWebFaq() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.webFaq
        ? model.value?.webFaq?.map((item) => {
            return [
              {
                name: "question",
                value: item.question,
              },
              {
                name: "answer",
                value: item.answer,
              },
            ];
          })
        : [];

      if (model.value) {
        webFaq.value = universalFieldUtils.createFormField(
          fieldWebFaqConfig,
          incomingFieldList
        );
      }
    }

    function updateWebTextBlocks() {
      const incomingFieldList: RepeaterIncomingValue = model.value
        ?.webTextBlocks
        ? model.value?.webTextBlocks?.map((item) => {
            return [
              {
                name: "title",
                value: item.title,
              },
              {
                name: "body",
                value: item.body,
              },
            ];
          })
        : [];

      if (model.value) {
        webTextBlocks.value = universalFieldUtils.createFormField(
          fieldWebTextBlocksConfig,
          incomingFieldList
        );
      }
    }

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(
          values.value,
          gamesField.value as RepeaterField,
          webFaq.value as RepeaterField,
          webTextBlocks.value as RepeaterField
        );

      updatePlaylist(id.value || "", createOrUpdatePayload)
        .then(() => {
          errors.value = {};

          if (event.type === "save_exit") {
            navigateBack(router, getPlaylistsListUrl());
          }

          toast.show({
            variant: "success",
            title: "Playlists",
            body: "Playlist successfully updated",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Playlists",
            body: "Playlist error updated",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    let websiteOrigin: string =
      process.env.VUE_APP_WEBSITE_URL || window.location.origin;
    if (websiteOrigin.substr(-1) === "/") {
      websiteOrigin = websiteOrigin.substr(0, websiteOrigin.length - 1);
    }

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: (entityId, direction) =>
        movePlaylistGame(id.value, entityId, direction),
      resourceName: "Move Game",
      onSuccess: fetchGames,
    });

    function isBusy(id: number): boolean {
      return isMoving(id) || isLoading.value;
    }

    const gamesField = ref<FieldUnion>(
      universalFieldUtils.createFormField(playlistGamesDynamicFieldConfig, null)
    );

    watch(model, () => {
      const incomingOnboardingGamesFieldList: RepeaterIncomingValue =
        model.value?.games?.map((item) => {
          return [
            {
              name: "game",
              value: {
                value: item.id,
                label: `ID ${item.id} - ${item.name}`,
              },
            },
          ];
        }) || [];

      gamesField.value = universalFieldUtils.createFormField(
        playlistGamesDynamicFieldConfig,
        incomingOnboardingGamesFieldList
      );
    });

    return {
      isLoading,
      model,
      games,
      tabs,
      values,
      errors,
      selectedTabId,
      backButtonUrl: getPlaylistsListUrl(),

      submitForm,
      isSubmitting,
      websiteOrigin,

      isMoving,
      handleResourceMove,
      isBusy,
      gamesField,

      webFaq,
      webTextBlocks,
    };
  },
});

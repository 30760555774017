
import { defineComponent } from "vue";

export default defineComponent({
  name: "GamesListViewPlatform",
  props: {
    platform: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
});

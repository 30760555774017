import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1150dde4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-3" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvancedSearchDateFilter = _resolveComponent("AdvancedSearchDateFilter")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!

  return (_openBlock(), _createBlock(_component_AdvancedSearch, {
    tags: _ctx.tags,
    "onClick:tag": _ctx.tagRemovalHandler
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AdvancedSearchDateFilter, {
        filter: _ctx.dateFilter,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFilter) = $event))
      }, null, 8, ["filter"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormField, {
          value: _ctx.userFilter,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userFilter) = $event)),
          label: "User"
        }, null, 8, ["value"]),
        _createVNode(_component_FormField, {
          value: _ctx.gameFilter,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.gameFilter) = $event)),
          label: "Game"
        }, null, 8, ["value"]),
        _createVNode(_component_FormField, {
          value: _ctx.ratingFilter,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ratingFilter) = $event)),
          type: "number",
          label: "Rating"
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.withBody,
          "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.withBody) = $event)),
          name: "withBody",
          label: "With Body"
        }, null, 8, ["checked"]),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.displayHome,
          "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.displayHome) = $event)),
          name: "displayHome",
          label: "Display Home"
        }, null, 8, ["checked"])
      ])
    ]),
    _: 1
  }, 8, ["tags", "onClick:tag"]))
}
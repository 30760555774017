import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fdcb9e5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  key: 0,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Spinner, {
              size: 40,
              "stroke-width": 3
            })
          ]))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
    ])
  ]))
}
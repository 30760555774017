
import { defineComponent, onMounted } from "vue";

import { ColumnDefinition, DataTable, useDataTable } from "@tager/admin-ui";

import { getUserLauncherHistory } from "@/modules/users/services";
import {
  UserLauncherHistoryInterface,
  UserLauncherInterface,
} from "@/modules/users/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserLauncherInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: {
      width: "40px",
      textAlign: "center",
    },
    headStyle: {
      width: "40px",
      textAlign: "center",
    },
  },
  {
    id: 2,
    name: "Game",
    type: "link",
    field: "game",
    options: {
      shouldOpenNewTab: true,
      disableCopyButton: true,
    },
    format: ({ row }) => {
      return row.game
        ? {
            url: "/games/" + row.game.id,
            text: String(row.game.id) + " - " + row.game.name,
          }
        : null;
    },
  },
  {
    id: 3,
    name: "Start At",
    field: "startAt",
    width: "188px",
    type: "datetime",
    options: {
      displaySeconds: true,
    },
  },
  {
    id: 3,
    name: "Finish At",
    field: "finishAt",
    width: "188px",
    type: "datetime",
    options: {
      displaySeconds: true,
    },
  },
];

export default defineComponent({
  name: "UsersViewLauncherHistoryList",
  components: {
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserLauncherHistoryInterface>({
      fetchEntityList: () => getUserLauncherHistory(props.id),
      initialValue: [],
      resourceName: "Launcher History List",
    });

    onMounted(() => {
      fetchData();
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isGameListLoading,
      errorMessage: errorMessage,
    };
  },
});

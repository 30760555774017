import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldOptionsSwitcherTrueFalse = _resolveComponent("FormFieldOptionsSwitcherTrueFalse")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldAjaxSelect = _resolveComponent("FormFieldAjaxSelect")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.isCreation ? 'Create campaign' : 'Update campaign',
    "is-content-loading": _ctx.isLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormFieldOptionsSwitcherTrueFalse, {
          value: _ctx.values.active,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.active) = $event)),
          name: "active",
          error: _ctx.errors.active,
          label: "Active"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Name"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.downloadUrl,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.downloadUrl) = $event)),
          name: "downloadUrl",
          error: _ctx.errors.downloadUrl,
          label: "Download URL"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldAjaxSelect, {
          value: _ctx.values.developer,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.developer) = $event)),
          name: "developer",
          error: _ctx.errors.developer,
          label: "Featured Developer",
          "value-field": "value",
          "label-field": "label",
          "request-url": "/admin/developers/ajax-search"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_DynamicField, { field: _ctx.gamesField }, null, 8, ["field"]),
        _createVNode(_component_DynamicField, { field: _ctx.gamelistsField }, null, 8, ["field"]),
        _createVNode(_component_DynamicField, { field: _ctx.reviewsField }, null, 8, ["field"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}
import { createId, FileType, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { getMonthOptionValue } from "@/utils/common";

import { GameFullInterface, GameUpdatePayload } from "../typings";

export enum IconSource {
  Store = "store",
  File = "file",
}

export const iconSourceOptions: Array<OptionType<IconSource>> = [
  { label: "App Store / Google Play", value: IconSource.Store },
  { label: "File", value: IconSource.File },
];

export type FormValues = {
  name: string;
  displayOnOnboarding: boolean;
  categories: Array<OptionType<number>>;
  tags: Array<OptionType<number>>;
  similarGamesPlaylist: Nullable<OptionType<number>>;

  webUrlAlias: string;
  webHidden: boolean;

  webOverview: string;
  webOverviewFull: string;
  webPreviewTitle: string;
  webPreviewText: string;

  androidDiscoveryVisible: boolean;
  androidStoreAvailable: boolean;
  androidAppTweakSyncEnabled: boolean;
  androidAppTweakSyncDescriptionEnabled: boolean;
  androidAppTweakSyncReleaseDateEnabled: boolean;
  androidAppTweakSyncPaidEnabled: boolean;

  androidApplicationId: string;
  androidStoreUrl: string;
  androidStoreRating: number | null;

  androidIsPrerelease: boolean;

  androidFirstReleaseDateKnown: boolean;
  androidFirstReleaseDate: Nullable<string>;
  androidFirstReleaseMonth: Nullable<OptionType<number>>;
  androidFirstReleaseYear: Nullable<number>;

  androidPaid: boolean;
  androidDeveloper: OptionType<number> | null;
  androidSize: number | null;
  androidLastVersion: string | null;
  androidLastReleaseDate: string | null;
  androidShortDescription: string;
  androidFullDescription: string;

  androidIconSource: OptionType<IconSource>;
  androidIconUrl: Nullable<string>;
  androidIconFile: Nullable<SingleFileInputValueType>;

  androidScreenshotsSource: OptionType<IconSource>;
  androidScreenshotsFiles: Array<SingleFileInputValueType | null>;
  androidScreenshotsUrls: string;

  androidVideos: string;

  iosDiscoveryVisible: boolean;
  iosStoreAvailable: boolean;
  iosAppTweakSyncEnabled: boolean;
  iosAppTweakSyncReleaseDateEnabled: boolean;
  iosAppTweakSyncDescriptionEnabled: boolean;
  iosAppTweakSyncPaidEnabled: boolean;

  iosApplicationId: string;
  iosStoreUrl: string;
  iosStoreRating: number | null;
  iosIsPrerelease: boolean;

  iosFirstReleaseDateKnown: boolean;
  iosFirstReleaseDate: Nullable<string>;
  iosFirstReleaseMonth: Nullable<OptionType<number>>;
  iosFirstReleaseYear: Nullable<number>;

  iosPaid: boolean;
  iosDeveloper: OptionType<number> | null;
  iosSize: number | null;
  iosLastVersion: string | null;
  iosLastReleaseDate: string | null;
  iosFullDescription: string;

  iosIconSource: OptionType<IconSource>;
  iosIconUrl: Nullable<string>;
  iosIconFile: Nullable<SingleFileInputValueType>;

  iosScreenshotsSource: OptionType<IconSource>;
  iosScreenshotsFiles: Array<SingleFileInputValueType | null>;
  iosScreenshotsUrls: string;

  iosVideos: string;

  restrictSyncVideoStreams: boolean;

  apptweakAndroidJson: string | null;
  apptweakIosJson: string | null;
  appMagicAndroidJson: string | null;
  appMagicFullAndroidJson: string | null;
  appMagicAndroidReleasesJson: string | null;
  appMagicIosJson: string | null;
  appMagicFullIosJson: string | null;
  appMagicIosReleasesJson: string | null;

  matters42AndroidJson: string | null;
};

export function createGalleryFileInputValue(
  fileInfo: Nullable<FileType>
): Nullable<SingleFileInputValueType> {
  return fileInfo
    ? {
        id: createId(),
        file: fileInfo,
      }
    : null;
}

export function getGameFormValues(
  model: GameFullInterface,
  categoryList: OptionType<number>[],
  tagList: OptionType<number>[]
): FormValues {
  const foundCategories = categoryList.filter((item) => {
    return model.categories.map((item) => item.id).includes(item.value);
  });

  const foundTags = tagList.filter((item) => {
    return model.tags.map((item) => item.id).includes(item.value);
  });

  return {
    name: model.name || "",
    displayOnOnboarding: model.displayOnOnboarding,
    categories: foundCategories,
    tags: foundTags,
    similarGamesPlaylist: model.similarGamesPlaylist
      ? {
          value: model.similarGamesPlaylist.id,
          label: model.similarGamesPlaylist.name,
        }
      : null,

    webUrlAlias: model.web?.urlAlias || "",
    webHidden: model.web?.hidden || false,

    webOverview: model.web?.overview || "",
    webOverviewFull: model.web?.overviewFull || "",
    webPreviewTitle: model.web?.previewTitle || "",
    webPreviewText: model.web?.previewText || "",

    androidDiscoveryVisible: !model.android.discoveryHidden,
    androidStoreAvailable: model.android.storeAvailable,
    androidAppTweakSyncEnabled: !model.android.restrictSync,
    androidAppTweakSyncReleaseDateEnabled:
      !model.android.restrictSyncReleaseDate,
    androidAppTweakSyncPaidEnabled: !model.android.restrictSyncPaid,
    androidAppTweakSyncDescriptionEnabled:
      !model.android.restrictSyncDescription,

    androidApplicationId: model.android.applicationId || "",
    androidStoreUrl: model.android.storeUrl || "",
    androidStoreRating: model.android.storeRating || null,

    androidIsPrerelease: model.android.isPrerelease,
    androidFirstReleaseDateKnown: model.android.isPrerelease
      ? !!model.android.releaseDate
      : false,
    androidFirstReleaseDate:
      !model.android.isPrerelease || model.android.releaseDate
        ? model.android.releaseDate
        : null,
    androidFirstReleaseMonth:
      model.android.isPrerelease &&
      !model.android.releaseDate &&
      model.android.releaseDateMonth
        ? getMonthOptionValue(model.android.releaseDateMonth)
        : null,
    androidFirstReleaseYear:
      model.android.isPrerelease && !model.android.releaseDate
        ? model.android.releaseDateYear
        : null,

    androidPaid: model.android.paid || false,
    androidDeveloper: model.android.developer
      ? {
          value: model.android.developer.id,
          label: model.android.developer.name,
        }
      : null,
    androidSize: model.android.size || null,
    androidLastReleaseDate: model.android.lastReleaseDate || "",
    androidLastVersion: model.android.lastVersion || "",
    androidShortDescription: model.android.shortDescription || "",
    androidFullDescription: model.android.fullDescription || "",

    androidIconSource: model.android.iconUrl
      ? iconSourceOptions[0]
      : iconSourceOptions[1],
    androidIconUrl: model.android.iconUrl,
    androidIconFile: model.android.iconFile
      ? { id: createId(), file: model.android.iconFile }
      : null,

    androidScreenshotsSource:
      !model.android.screenshotsFiles ||
      model.android.screenshotsFiles.length === 0
        ? iconSourceOptions[0]
        : iconSourceOptions[1],
    androidScreenshotsFiles: model.android.screenshotsFiles
      ? model.android.screenshotsFiles.map((image) =>
          createGalleryFileInputValue(image)
        )
      : [],
    androidScreenshotsUrls: model.android.screenshotsUrls?.join("\n") || "",

    androidVideos: model.android.videos?.join("\n"),

    iosDiscoveryVisible: !model.ios.discoveryHidden,
    iosStoreAvailable: model.ios.storeAvailable,
    iosAppTweakSyncEnabled: !model.ios.restrictSync,
    iosAppTweakSyncReleaseDateEnabled: !model.ios.restrictSyncReleaseDate,
    iosAppTweakSyncPaidEnabled: !model.ios.restrictSyncPaid,
    iosAppTweakSyncDescriptionEnabled: !model.ios.restrictSyncDescription,

    iosApplicationId: model.ios.applicationId || "",
    iosStoreUrl: model.ios.storeUrl || "",
    iosStoreRating: model.ios.storeRating || null,

    iosIsPrerelease: model.ios.isPrerelease,
    iosFirstReleaseDateKnown: model.ios.isPrerelease
      ? !!model.ios.releaseDate
      : false,
    iosFirstReleaseDate:
      !model.ios.isPrerelease || model.ios.releaseDate
        ? model.ios.releaseDate
        : null,
    iosFirstReleaseMonth:
      model.ios.isPrerelease &&
      !model.ios.releaseDate &&
      model.ios.releaseDateMonth
        ? getMonthOptionValue(model.ios.releaseDateMonth)
        : null,
    iosFirstReleaseYear:
      model.ios.isPrerelease && !model.ios.releaseDate
        ? model.ios.releaseDateYear
        : null,

    iosPaid: model.ios.paid || false,
    iosDeveloper: model.ios.developer
      ? { value: model.ios.developer.id, label: model.ios.developer.name }
      : null,
    iosSize: model.ios.size || null,
    iosLastReleaseDate: model.ios.lastReleaseDate || "",
    iosLastVersion: model.ios.lastVersion || "",
    iosFullDescription: model.ios.fullDescription || "",

    iosIconSource: model.ios.iconUrl
      ? iconSourceOptions[0]
      : iconSourceOptions[1],
    iosIconUrl: model.ios.iconUrl,
    iosIconFile: model.ios.iconFile
      ? { id: createId(), file: model.ios.iconFile }
      : null,

    iosScreenshotsSource:
      !model.ios.screenshotsFiles || model.ios.screenshotsFiles.length === 0
        ? iconSourceOptions[0]
        : iconSourceOptions[1],

    iosScreenshotsFiles: model.ios.screenshotsFiles
      ? model.ios.screenshotsFiles.map((image) =>
          createGalleryFileInputValue(image)
        )
      : [],
    iosScreenshotsUrls: model.ios.screenshotsUrls?.join("\n") || "",

    iosVideos: model.ios.videos?.join("\n"),

    /* igdbJson:
          model.dataSources.find((item) => item.source === "IGDB")?.data || null,*/

    restrictSyncVideoStreams: model.restrictSyncVideoStreams,

    apptweakIosJson:
      model.dataSources.find((item) => item.source === "APPTWEAK_IOS")?.data ||
      null,

    apptweakAndroidJson:
      model.dataSources.find((item) => item.source === "APPTWEAK_ANDROID")
        ?.data || null,

    appMagicIosJson:
      model.dataSources.find((item) => item.source === "APPMAGIC_IOS")?.data ||
      null,

    appMagicFullIosJson:
      model.dataSources.find((item) => item.source === "APPMAGIC_FULL_IOS")
        ?.data || null,

    appMagicIosReleasesJson:
      model.dataSources.find((item) => item.source === "APPMAGIC_IOS_RELEASES")
        ?.data || null,

    appMagicAndroidJson:
      model.dataSources.find((item) => item.source === "APPMAGIC_ANDROID")
        ?.data || null,

    appMagicFullAndroidJson:
      model.dataSources.find((item) => item.source === "APPMAGIC_FULL_ANDROID")
        ?.data || null,

    appMagicAndroidReleasesJson:
      model.dataSources.find(
        (item) => item.source === "APPMAGIC_ANDROID_RELEASES"
      )?.data || null,

    matters42AndroidJson:
      model.dataSources.find((item) => item.source === "42_MATTERS_ANDROID")
        ?.data || null,
  };
}

export function convertFormValuesToUpdatePayload(
  values: FormValues,
  videoStreams: RepeaterField,
  androidAltStoresDeleted: string[],
  iosAltStoresDeleted: string[],
  webFaq: RepeaterField,
  webTextBlocks: RepeaterField
): GameUpdatePayload {
  const videoStreamsValue = videoStreams.value
    ? videoStreams.value.map((item) => {
        return {
          name: String(item.value[0].value),
          url: String(item.value[1].value),
          author: String(item.value[2].value),
          datetime: String(item.value[3].value),
        };
      })
    : [];

  const webFaqValue = webFaq.value
    ? webFaq.value.map((item) => {
        return {
          question: String(item.value[0].value),
          answer: String(item.value[1].value),
        };
      })
    : [];

  const webTextBlocksValue = webTextBlocks.value
    ? webTextBlocks.value.map((item) => {
        return {
          title: String(item.value[0].value),
          body: String(item.value[1].value),
        };
      })
    : [];

  return {
    name: values.name,
    displayOnOnboarding: Boolean(values.displayOnOnboarding),
    categories: values.categories.map((item) => item.value),
    tags: values.tags.map((item) => item.value),

    similarGamesPlaylist: values.similarGamesPlaylist?.value || null,
    webUrlAlias: values.webUrlAlias,
    webHidden: values.webHidden,

    webOverview: values.webOverview,
    webOverviewFull: values.webOverviewFull,
    webPreviewTitle: values.webPreviewTitle,
    webPreviewText: values.webPreviewText,
    webFaq: webFaqValue,
    webTextBlocks: webTextBlocksValue,

    iosDiscoveryHidden: !values.iosDiscoveryVisible,
    iosStoreAvailable: values.iosStoreAvailable,
    iosRestrictSync: !values.iosAppTweakSyncEnabled,
    iosRestrictSyncReleaseDate: !values.iosAppTweakSyncReleaseDateEnabled,
    iosRestrictSyncPaid: !values.iosAppTweakSyncPaidEnabled,
    iosRestrictSyncDescription: !values.iosAppTweakSyncDescriptionEnabled,

    iosApplicationId: values.iosApplicationId,
    iosStoreUrl: values.iosStoreUrl,
    iosStoreRating: values.iosStoreRating,

    iosIsPrerelease: values.iosIsPrerelease,
    iosFirstReleaseDate: values.iosIsPrerelease
      ? values.iosFirstReleaseDateKnown
        ? values.iosFirstReleaseDate
        : null
      : values.iosFirstReleaseDate,
    iosFirstReleaseDateMonth:
      values.iosIsPrerelease && !values.iosFirstReleaseDateKnown
        ? values.iosFirstReleaseMonth?.value || null
        : null,
    iosFirstReleaseDateYear:
      values.iosIsPrerelease && !values.iosFirstReleaseDateKnown
        ? values.iosFirstReleaseYear
        : null,

    iosPaid: values.iosPaid,
    iosSize: values.iosSize,
    iosDeveloper: values.iosDeveloper?.value || null,
    iosLastVersion: values.iosLastVersion || "",
    iosLastReleaseDate: values.iosLastReleaseDate || "",
    iosFullDescription: values.iosFullDescription || "",

    iosIconFile:
      values.iosIconSource.value === IconSource.File && values.iosIconFile
        ? values.iosIconFile.file.id
        : null,
    iosIconUrl:
      values.iosIconSource.value === IconSource.Store
        ? values.iosIconUrl
        : null,

    iosScreenshotsFiles:
      values.iosScreenshotsSource.value === IconSource.File &&
      values.iosScreenshotsFiles
        ? values.iosScreenshotsFiles.map((item) => item?.file.id as string)
        : [],
    iosScreenshotsUrls:
      values.iosScreenshotsSource.value === IconSource.Store &&
      values.iosScreenshotsUrls
        ? values.iosScreenshotsUrls.split("\n")
        : [],

    iosVideos:
      values.iosVideos.trim().length > 0 ? values.iosVideos.split("\n") : [],
    iosAlternativeStoresDeleted: iosAltStoresDeleted,

    androidDiscoveryHidden: !values.androidDiscoveryVisible,
    androidStoreAvailable: values.androidStoreAvailable,
    androidRestrictSync: !values.androidAppTweakSyncEnabled,
    androidRestrictSyncDescription:
      !values.androidAppTweakSyncDescriptionEnabled,
    androidRestrictSyncPaid: !values.androidAppTweakSyncPaidEnabled,
    androidRestrictSyncReleaseDate:
      !values.androidAppTweakSyncReleaseDateEnabled,

    androidApplicationId: values.androidApplicationId,
    androidStoreUrl: values.androidStoreUrl,
    androidStoreRating: values.androidStoreRating,

    androidIsPrerelease: values.androidIsPrerelease,
    androidFirstReleaseDate: values.androidIsPrerelease
      ? values.androidFirstReleaseDateKnown
        ? values.androidFirstReleaseDate
        : null
      : values.androidFirstReleaseDate,
    androidFirstReleaseDateMonth:
      values.androidIsPrerelease && !values.androidFirstReleaseDateKnown
        ? values.androidFirstReleaseMonth?.value || null
        : null,
    androidFirstReleaseDateYear:
      values.androidIsPrerelease && !values.androidFirstReleaseDateKnown
        ? values.androidFirstReleaseYear
        : null,

    androidPaid: values.androidPaid,
    androidSize: values.androidSize,
    androidDeveloper: values.androidDeveloper?.value || null,
    androidLastVersion: values.androidLastVersion || "",
    androidLastReleaseDate: values.androidLastReleaseDate || "",
    androidShortDescription: values.androidShortDescription || "",
    androidFullDescription: values.androidFullDescription || "",

    androidIconFile:
      values.androidIconSource.value === IconSource.File &&
      values.androidIconFile
        ? values.androidIconFile.file.id
        : null,
    androidIconUrl:
      values.androidIconSource.value === IconSource.Store
        ? values.androidIconUrl
        : null,

    androidScreenshotsFiles:
      values.androidScreenshotsSource.value === IconSource.File &&
      values.androidScreenshotsFiles
        ? values.androidScreenshotsFiles.map((item) => item?.file.id as string)
        : [],
    androidScreenshotsUrls:
      values.androidScreenshotsSource.value === IconSource.Store &&
      values.androidScreenshotsUrls
        ? values.androidScreenshotsUrls.split("\n")
        : [],

    androidVideos:
      values.androidVideos.trim().length > 0
        ? values.androidVideos.split("\n")
        : [],
    androidAlternativeStoresDeleted: androidAltStoresDeleted,

    restrictSyncVideoStreams: values.restrictSyncVideoStreams,
    videoStreams: videoStreamsValue,
  };
}

export const fieldVideoStreamsDataConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Video Streams",
  meta: {
    view: "TABLE",
  },
  fields: [
    {
      name: "name",
      type: "STRING",
      label: "Name",
    },
    {
      name: "youtubeUrl",
      type: "URL",
      label: "Youtube URL",
    },
    {
      name: "author",
      type: "STRING",
      label: "Author",
    },
    {
      name: "datetime",
      type: "STRING",
      label: "Date / Time",
    },
  ],
};

export const fieldWebFaqConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "FAQ",
  meta: {
    view: "BLOCK",
    defaultIsOpen: true,
    noToggle: true,
    titleFormatter: (values) => {
      return String(values[0].value) || "Question";
    },
  },
  fields: [
    {
      name: "question",
      type: "STRING",
      label: "Question",
    },
    {
      name: "answer",
      type: "HTML",
      label: "Answer",
    },
  ],
};
export const fieldWebTextBlocksConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Text Blocks",
  meta: {
    view: "BLOCK",
    defaultIsOpen: true,
    noToggle: true,
    titleFormatter: (values) => {
      return String(values[0].value) || "Title";
    },
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Title",
    },
    {
      name: "body",
      type: "HTML",
      label: "Body",
    },
  ],
};

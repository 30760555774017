
import { defineComponent } from "vue";

import {
  BaseButton,
  CountButton,
  DataTable,
  DeleteIcon,
  EditIcon,
  NorthIcon,
  SouthIcon,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import {
  getTagsCreateUrl,
  getTagsUpdateUrl,
  getGamesListUrl,
  getUsersListUrl,
} from "@/utils/paths";

import { deleteTag, getTagsList, moveTag } from "../services";
import { TagInterface } from "../typings";

import { COLUMN_DEFS } from "./TagsListView.helpers";

export default defineComponent({
  name: "TagsListView",
  components: {
    CountButton,
    DeleteIcon,
    EditIcon,
    NorthIcon,
    SouthIcon,
    BaseButton,
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchData,
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<TagInterface>({
      fetchEntityList: (params) =>
        getTagsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Tags List",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveTag,
      resourceName: "Tag",
      onSuccess: fetchData,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteTag,
      resourceName: "Tag",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      handleResourceDelete,
      handleResourceMove,
      getTagsCreateUrl,
      getTagsUpdateUrl,
      getGamesListUrl,
      getUsersListUrl,
    };
  },
});

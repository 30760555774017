
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { isEqual, pick } from "lodash";
import { useRoute, useRouter } from "vue-router";

import {
  AdvancedSearch,
  CountButton,
  DataTable,
  FilterTagType,
  FormFieldMultiSelect,
  getFilterParamAsStringArray,
  getFilterParams,
  OptionType,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { isNotNullish, useResource } from "@tager/admin-services";

import { getCategoriesList } from "@/modules/categories/services";
import { getTagsList } from "@/modules/tags/services";
import { getUsersUpdateUrl } from "@/utils/paths";
import { getCampaigns } from "@/modules/campaigns";
import { getPlaylistsListUrl } from "@/modules/playlists";

import { getUsers } from "../services";
import { UserInterface } from "../typings";

import { COLUMN_DEFS, SORT_OPTIONS } from "./UsersListView.helpers";

export default defineComponent({
  name: "UsersListView",
  components: {
    CountButton,
    FormFieldMultiSelect,
    AdvancedSearch,
    Page,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const baseRoutePath = route.path;

    const {
      fetchEntityList: fetchData,
      isLoading: isGameListLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<UserInterface>({
      fetchEntityList: (params) =>
        getUsers({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Users List",
      defaultSort: SORT_OPTIONS[0].value,
    });

    const [
      fetchCampaignList,
      { data: campaignList, loading: isCampaignListLoading },
    ] = useResource({
      fetchResource: () => getCampaigns(),
      initialValue: [],
      resourceName: "Campaign list",
    });

    const [
      fetchCategoryList,
      { data: categoryList, loading: isCategoryListLoading },
    ] = useResource({
      fetchResource: () => getCategoriesList(),
      initialValue: [],
      resourceName: "Categories list",
    });

    const [fetchTagList, { data: tagList, loading: isTagListLoading }] =
      useResource({
        fetchResource: () => getTagsList(),
        initialValue: [],
        resourceName: "Tag list",
      });

    const categoryOptionList = computed(() =>
      categoryList.value.map<OptionType>((category) => ({
        value: String(category.id),
        label: category.name,
      }))
    );

    const tagOptionList = computed(() =>
      tagList.value.map<OptionType>((tag) => ({
        value: String(tag.id),
        label: tag.name,
      }))
    );

    const campaignOptionList = computed(() =>
      campaignList.value.map<OptionType>((item) => ({
        value: String(item.id),
        label: item.name,
      }))
    );

    const initialCampaignFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "campaign");
      return campaignOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const campaignFilter = ref<Array<OptionType>>(initialCampaignFilter.value);
    watch(initialCampaignFilter, () => {
      campaignFilter.value = initialCampaignFilter.value;
    });

    const initialCategoryFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "category");
      return categoryOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const categoryFilter = ref<Array<OptionType>>(initialCategoryFilter.value);
    watch(initialCategoryFilter, () => {
      categoryFilter.value = initialCategoryFilter.value;
    });

    const initialTagFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "tag");
      return tagOptionList.value.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const tagFilter = ref<Array<OptionType>>(initialTagFilter.value);
    watch(initialTagFilter, () => {
      tagFilter.value = initialTagFilter.value;
    });

    const filterParams = computed(() => {
      return getFilterParams({
        category: categoryFilter.value.map((item) => item.value),
        tag: tagFilter.value.map((item) => item.value),
        campaign: campaignFilter.value.map((item) => item.value),
      });
    });

    watch(filterParams, () => {
      if (
        isTagListLoading.value ||
        isCategoryListLoading.value ||
        isCampaignListLoading.value ||
        route.path !== baseRoutePath
      )
        return;

      const newQuery = {
        ...pick(route.query, ["query", "pageNumber", "sort"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchData();
      }
    });

    onMounted(() => {
      fetchData();
      fetchCategoryList();
      fetchTagList();
      fetchCampaignList();
    });

    const isDataLoading = computed<boolean>(
      () =>
        isCampaignListLoading.value ||
        isGameListLoading.value ||
        isCategoryListLoading.value ||
        isTagListLoading.value
    );

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "category") {
        categoryFilter.value = categoryFilter.value.filter(
          (category) => category.value !== event.value
        );
      }
      if (event.name === "tag") {
        tagFilter.value = tagFilter.value.filter(
          (tag) => tag.value !== event.value
        );
      }
      if (event.name === "campaign") {
        campaignFilter.value = campaignFilter.value.filter(
          (tag) => tag.value !== event.value
        );
      }
    }

    const tags = computed<Array<FilterTagType>>(() =>
      [
        ...categoryFilter.value.map((category) => ({
          value: category.value,
          label: category.label,
          name: "category",
          title: "Category",
        })),
        ...tagFilter.value.map((tag) => ({
          value: tag.value,
          label: tag.label,
          name: "tag",
          title: "Tag",
        })),
        ...campaignFilter.value.map((item) => ({
          value: item.value,
          label: item.label,
          name: "campaign",
          title: "Campaign",
        })),
      ].filter(isNotNullish)
    );

    return {
      columnDefs: COLUMN_DEFS,
      sortOptions: SORT_OPTIONS,
      sortValue: sort,

      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      categoryOptionList,
      campaignOptionList,
      tagOptionList,
      tags,
      handleTagRemove,
      categoryFilter,
      campaignFilter,
      tagFilter,

      getUsersUpdateUrl,
      getPlaylistsListUrl,
    };
  },
});

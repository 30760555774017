import { ColumnDefinition } from "@tager/admin-ui";

import { GamesLauncherCustomInterface } from "../typings";

export const COLUMN_DEFS: Array<
  ColumnDefinition<GamesLauncherCustomInterface>
> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    headStyle: { width: "40px" },
  },
  {
    id: 5,
    name: "Created At",
    field: "createdAt",
    headStyle: { width: "168px" },
    type: "datetime",
  },
  {
    id: 3,
    name: "User",
    type: "link",
    field: "user",
    options: {
      shouldOpenNewTab: true,
      disableCopyButton: true,
    },
    format: ({ row, column }) =>
      row.user
        ? {
            url: "/users/" + row.user.id,
            text: String(row.user.id) + " - " + row.user.name,
          }
        : null,
  },
  {
    id: 4,
    name: "Package Name",
    field: "packageName",
  },
  {
    id: 5,
    name: "Name",
    field: "name",
  },
];


import { defineComponent } from "vue";

import { Spinner } from "@tager/admin-ui";

export default defineComponent({
  name: "StatsWidget",
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});


import { defineComponent, computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  FormField,
  TagerFormSubmitEvent,
  FormFieldMultiSelect,
  OptionType,
} from "@tager/admin-ui";

import { getGamesListUrl, getGamesUpdateUrl } from "@/utils/paths";
import { getCategoriesList } from "@/modules/categories/services";
import { getTagsList } from "@/modules/tags/services";

import { createGame } from "../services";

import {
  FormValues,
  defaultFormValues,
  convertFormValuesToCreationPayload,
} from "./GamesCreateView.helpers";

export default defineComponent({
  name: "GamesCreateView",
  components: {
    FormFieldMultiSelect,
    FormField,
    Page,
    FormFooter,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const [
      fetchCategoryList,
      { data: categoryList, loading: isCategoryListLoading },
    ] = useResource({
      fetchResource: () => getCategoriesList(),
      initialValue: [],
      resourceName: "Categories list",
    });

    const [fetchTagList, { data: tagList, loading: isTagListLoading }] =
      useResource({
        fetchResource: () => getTagsList(),
        initialValue: [],
        resourceName: "Tag list",
      });

    onMounted(() => {
      fetchCategoryList();
      fetchTagList();

      values.value = {
        name: "",
        categories: [],
        tags: [],
      };
    });

    const tagOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...tagList.value.map((tag) => ({
          value: tag.id,
          label: tag.name,
        })),
      ];
    });

    const categoryOptions = computed<Array<OptionType<number>>>(() => {
      return categoryList.value.map((category) => ({
        value: category.id,
        label: category.name,
      }));
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(defaultFormValues);

    const isSubmitting = ref<boolean>(false);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createPayload = convertFormValuesToCreationPayload(
        values.value as FormValues
      );

      createGame(createPayload)
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getGamesUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getGamesListUrl());
          }
          toast.show({
            variant: "success",
            title: "Games",
            body: "Game successfully created",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Games",
            body: "Error create game",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isTagListLoading.value || isCategoryListLoading.value
    );

    return {
      isLoading,
      values,
      errors,
      isSubmitting,
      submitForm,
      backButtonUrl: getGamesListUrl(),

      tagOptions,
      categoryOptions,
    };
  },
  computed: {
    console: () => console,
  },
});

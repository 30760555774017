
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

import { Page } from "@tager/admin-layout";
import { useResource } from "@tager/admin-services";
import { FieldValue } from "@tager/admin-ui";

import { getUserPushNotificationDetailed } from "@/modules/users/services";

export default defineComponent({
  name: "UsersPushNotificationsFormView",
  components: {
    FieldValue,
    Page,
  },
  setup() {
    const route = useRoute();

    const id = computed(() => route.params.id as string);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value) {
          return getUserPushNotificationDetailed(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "PushNotification",
    });

    onMounted(() => {
      fetchModel();
    });

    watch(id, fetchModel);

    const isLoading = computed<boolean>(() => isModelLoading.value);

    return {
      id,
      isLoading,
      model,
    };
  },
});


import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormField,
  FormFieldAjaxSelect,
  FormFooter,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";

import {
  getPlaylistsListUrl,
  createPlaylist,
  getPlaylistsViewUrl,
} from "@/modules/playlists";

import {
  convertPageFormValuesToCreationPayload,
  DEFAULT_FORM_VALUES,
  FormValues,
} from "./PlaylistCreateView.helpers";

export default defineComponent({
  name: "PollsFormView",
  components: {
    FormFieldAjaxSelect,
    Page,
    FormFooter,
    FormField,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(DEFAULT_FORM_VALUES);

    const isSubmitting = ref<boolean>(false);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      createPlaylist(convertPageFormValuesToCreationPayload(values.value))
        .then((response) => {
          errors.value = {};

          if (event.type === "save_exit") {
            navigateBack(router, getPlaylistsListUrl());
          } else {
            router.push(getPlaylistsViewUrl(response.data.id));
          }

          toast.show({
            variant: "success",
            title: "Playlists",
            body: "Playlist successfully created",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Playlists",
            body: "Playlist error created",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    return {
      values,
      errors,
      backButtonUrl: getPlaylistsListUrl(),

      submitForm,
      isSubmitting,
    };
  },
});

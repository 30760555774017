
import { defineComponent } from "vue";

import {
  FormFieldFileInput,
  FormField,
  FieldValue,
  FormFieldSelect,
} from "@tager/admin-ui";
import { DynamicField } from "@tager/admin-dynamic-field";
import {
  IconSource,
  iconSourceOptions,
} from "@/modules/games/GamesUpdateView/GamesUpdateView.helpers";

export default defineComponent({
  name: "GamesUpdateViewPlatformMedia",
  computed: {
    IconSource() {
      return IconSource;
    },
  },
  components: {
    FormField,
    FormFieldFileInput,
    FieldValue,
    DynamicField,
    FormFieldSelect,
  },
  props: {
    platform: {
      type: String,
    },
    model: {
      type: Array,
    },
    values: {
      type: Array,
    },
    errors: {
      type: Array,
    },
    screenshotsOrientation: {
      type: String,
    },
  },
  setup() {
    return {
      iconSourceOptions,
    };
  },
});

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "column-defs": _ctx.columnDefs,
    "row-data": _ctx.rowData,
    loading: _ctx.isRowDataLoading,
    "error-message": _ctx.errorMessage,
    "use-search": false
  }, {
    "cell(payload)": _withCtx(({ row }) => [
      (row.type === 'FOLLOWED')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (row.followingUser)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _ctx.getUsersUpdateUrl(row.followingUser.id)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(row.followingUser.id + " - " + row.followingUser.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
          ], 64))
        : _createCommentVNode("", true),
      (row.type === 'RATED')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (row.review)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(row.review.id + " - " + row.review.rating + " - " + row.review.body), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("-")
                ], 64))
          ], 64))
        : (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.games, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id
              }, [
                _createVNode(_component_router_link, {
                  to: _ctx.getGamesUpdateUrl(item.id)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.id) + " - " + _toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ]))
    ]),
    _: 1
  }, 8, ["column-defs", "row-data", "loading", "error-message"]))
}
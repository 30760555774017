import { ColumnDefinition } from "@tager/admin-ui";

import { TagInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<TagInterface>> = [
  {
    id: 1,
    name: "Name (English)",
    field: "name",
  },
  {
    id: 3,
    name: "Section",
    field: "section",
  },
  {
    id: 4,
    name: "Icon",
    field: "icon",
  },
  {
    id: 5,
    name: "",
    field: "relations",
    style: { width: "370px" },
    headStyle: { width: "370px" },
  },
  {
    id: 6,
    name: "",
    field: "actions",
    headStyle: { width: "200px", textAlign: "center", whiteSpace: "nowrap" },
  },
];


import { defineComponent, PropType, ref } from "vue";
import { useRouter } from "vue-router";

import { BaseButton } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { getCategoriesUpdateUrl } from "@/utils/paths";
import { TagFullInterface } from "@/modules/tags/typings";
import { setTagAsCategory } from "@/modules/tags/services";

export default defineComponent({
  name: "TagsFormViewTopRight",
  components: { BaseButton },
  props: {
    tag: {
      type: Object as PropType<TagFullInterface>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const router = useRouter();

    const loading = ref<boolean>(false);

    const onClick = async () => {
      if (!confirm("Are you sure?")) return;

      try {
        loading.value = true;
        const response = await setTagAsCategory(props.tag.id);

        toast.show({
          variant: "success",
          title: "Success",
          body: "Tag is successfully converted to category",
        });

        router.push(getCategoriesUpdateUrl(response.data.id));
      } catch (e) {
        loading.value = false;

        toast.show({
          variant: "danger",
          title: "Error",
          body: "Error converting",
        });
      }
    };

    return {
      onClick,
      loading,
    };
  },
});

import {ColumnDefinition} from "@tager/admin-ui";

import {CategoryInterface} from "../typings";
import {getCategoriesUpdateUrl} from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<CategoryInterface>> = [
    {
        id: 2,
        name: "Name",
        field: "name",
        type: "name",
        format: ({row}) => ({
            adminLink: {
                text: row.name,
                url: getCategoriesUpdateUrl(row.id),
            },
            websiteLink: {
                text: row.webUrl,
                url: row.webUrl,
            },
        }),
        options: {
            displayFullWebsiteLink: true,
        },
    },
    {
        id: 3,
        name: "Icon",
        field: "icon",
    },
    {
        id: 4,
        name: "",
        field: "relations",
        style: {width: "370px"},
        headStyle: {width: "370px"},
    },
    {
        id: 5,
        name: "",
        field: "actions",
        headStyle: {width: "200px", textAlign: "center", whiteSpace: "nowrap"},
    },
];

import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getCampaignsListUrl() {
  return ROUTE_PATHS.CAMPAIGNS_LIST;
}
export function getCampaignsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.CAMPAIGNS_FORM)({ id: id });
}
export function getCampaignsCreateUrl() {
  return compile(ROUTE_PATHS.CAMPAIGNS_CREATE)();
}
